import { addDays } from "@utils/helpers";

export const terms = [
    {
        title: "Keeping your data safe",
        body: "We take security seriously. We use strong measures to protect your bank info. We will never ask you to share your bank login details."
    },
    {
        title: "How long your consent lasts",
        body: `Your consent stays in effect for ${addDays(180)} (180 days) or until you tell us to stop, either through your bank or our apps.`
    },
    {
        title: "Your right to say no",
        body: "You can change your mind and withdraw your consent anytime. This will not affect any assessments we have done before you withdrew consent."
    },
    {
        title: "How we use your info ",
        body: "We will only use your bank data for what we have told you and to provide our services. "
    },
    {
        title: "Your privacy",
        body: "Your personal info will be handled according to the law. You can ask to see, correct, or delete your data if needed."
    }
]