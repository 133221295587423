import React, { useState } from "react";

// Helpers
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import RadioButton from "@components/RadioButton";

// Assets
import { ReactComponent as LdapIcon } from "@icons/database.svg";
import { ReactComponent as DirectoryIcon } from "@icons/directory.svg";
import { ReactComponent as MySqlIcon } from "@icons/sql.svg";
import { ReactComponent as CsvIcon } from "@icons/csv.svg";

const options = [
  {
    id: 1,
    icon: <LdapIcon />,
    title: "LDAP",
  },
  {
    id: 2,
    icon: <DirectoryIcon />,
    title: "Active Directory",
  },
  {
    id: 3,
    icon: <MySqlIcon />,
    title: "MySQL",
  },
  {
    id: 4,
    icon: <CsvIcon />,
    title: "CSV",
  },
];

const CreateBulkUserForm = ({ bulkOption, setBulkOption, closeModal }) => {
  const [selected, setSelected] = useState(1);
  const onContinue = () => {
    setBulkOption(selected);
    closeModal(false);
  };

  return (
    <div className="px-6 pb-6">
      <span className="text-desc-grey">Select method of importation</span>
      {options.map((option) => (
        <div
          key={option.id}
          onClick={() => setSelected(option.id)}
          className={getClassNames("cursor-pointer rounded border border-stroke bg-white4 px-4 py-3 flex items-center justify-between mt-2")}
        >
          <div className="grid grid-cols-[40px,1fr]">
            {option.icon}
            <span className="text-lg text-body-black">{option.title}</span>
          </div>
          <RadioButton active={selected === option.id} />
        </div>
      ))}
      <Button
        title="Continue"
        color="dark"
        category="1"
        className="mt-8"
        onClick={() => onContinue()}
      />
    </div>
  );
};

export default CreateBulkUserForm;
