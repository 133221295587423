import React from "react";

// Utils
import { getClassNames } from "@utils/helpers";

// Assets
import { ReactComponent as TickIcon } from "@icons/circle-tick-lg.svg";

interface ButtonProps {
  active: boolean;
  onClick?: () => void;
}

export const CheckButton: React.FC<ButtonProps> = ({ active, onClick = () => { } }) => {
  return active ? (
    <TickIcon className="" />
  ) : (
    <div
      className={getClassNames(
        "rounded-full w-[18px] h-[18px] flex items-center justify-center border border-grey-content-tertiary",
        "transition-all duration-500 bg-white",
      )}
      onClick={onClick}
    />
  );
};

const RadioButton: React.FC<ButtonProps> = ({ active, onClick = () => { } }) => {
  return (
    <div
      className={getClassNames(
        "rounded-full w-6 h-6 flex items-center justify-center border border-grey-content-tertiary",
        "transition-all duration-500",
        active ? "bg-black" : "bg-white4"
      )}
      onClick={onClick}
    >
      <div
        className={getClassNames(
          "rounded-full w-[0.375rem] h-[0.375rem] bg-white4"
        )}
      />
    </div>
  );
};

export default RadioButton;
