import React from "react";

// Components
import { Button } from "@components/button";

const ImportSummary = ({ onContinue }) => {
  const attributes = [
    {
      id: 1,
      name: "Lerato Matsio",
      email: "lerato@trudenty.com",
      phoneNumber: "+447 801 123 4567",
      group: "Administrators",
    },
    {
      id: 2,
      name: "Marko Kome",
      email: "marco@trudenty.com",
      phoneNumber: "+447 801 123 4567",
      group: "Engineering",
    },
    {
      id: 3,
      name: "Just Iyin",
      email: "iyin@trudenty.com",
      phoneNumber: "+447 801 123 4567",
      group: "Design and Engineering",
    },
    {
      id: 4,
      name: "Sam Rudy",
      email: "sam@trudenty.com",
      phoneNumber: "+447 801 123 4567",
      group: "Engineering",
    },
    {
      id: 5,
      name: "Success McFellow",
      email: "success@trudenty.com",
      phoneNumber: "+447 801 123 4567",
      group: "Accounting",
    },
    
  ];
  return (
    <div className="mt-6">
      <div className="border border-stroke bg-white rounded-md">
        <div className="grid grid-cols-[47px,3fr,4fr,3fr,4fr] bg-grey-600 py-2 leading-base1 text-sm text-grey-content-secondary">
          <span className="self-center mx-auto">#</span>
          <div className="pl-4">Name</div>
          <div className="pl-4">Email</div>
          <div className="pl-4">Phone number</div>
          <div className="pl-4">Group</div>
        </div>
        {attributes?.map(({ id, name, email, phoneNumber, group }, i) => (
          <div
            className="grid grid-cols-[47px,3fr,4fr,3fr,4fr] hover:bg-white2 items-center text-sm1 text-desc-grey"
            key={id}
          >
            <div className="counter self-center w-full mx-auto flex items-center justify-center border-b border-grey-10 h-full">
              {i + 1}
            </div>
            <div className="pl-4 flex items-center h-full border-b border-l py-6 border-grey-10">
              <span className="header">{name}</span>
            </div>
            <div className="h-full border-b border-l py-6 border-grey-10 pl-4">
              <p className="h-full flex items-center">
                {email}
              </p>
            </div>
            <div className="h-full border-b border-l py-6 border-grey-10 flex justify-center items-center px-4">
              <p className="">{phoneNumber}</p>
            </div>
            <div className="h-full border-b border-l py-6 border-grey-10 flex items-center px-4">
              <p className="">{group}</p>
            </div>
            <div className="h-full flex items-center justify-center"></div>
          </div>
        ))}
      </div>
      <Button
        title="Import all"
        color="dark"
        category="1"
        className="w-fit mt-6"
        onClick={() => onContinue()}
      />
    </div>
  );
};

export default ImportSummary;
