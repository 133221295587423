import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Utils
import { getClassNames } from "@utils/helpers";

// Components
import CreateGroupModal from "./components/createGroupModal";
import CustomPopover from "@components/customPopover";
import EditGroupModal from "./components/editGroupModal";
import GroupInitial from "./components/groupInitial";
import SearchInput from "@components/searchInput";
import Topbar from "@components/topbar";
import UserInitial from "@components/UserInitial";

// Assets
import EllipsisIcon from "@icons/Ellipsis.js";

const Groups = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
    const { state } = location;
    if (state?.openInvite) {
      setOpenCreateModal(true);
    }
  }, [location]);
  const handleSearch = (query) => {
    console.log(query);
  };

  const groups = [{ name: "Internal Team" }];

  return (
    <div className="w-full h-full bg-white3">
      <Topbar
        title="Groups"
        buttonConfig={{ color: "dark", title: "Create" }}
        buttonAction={() => setOpenCreateModal(true)}
      >
        <div className="ml-auto mr-4">
          <SearchInput handleChange={handleSearch} height="h-full" />
        </div>
      </Topbar>
      <CreateGroupModal
        isOpen={openCreateModal}
        closeModal={setOpenCreateModal}
      />
      <EditGroupModal
        isOpen={openEditModal}
        closeModal={setOpenEditModal}
      />

      <div className="px-8 py-6">
        <div className="border border-stroke bg-white rounded-md">
          <div className="grid grid-cols-[64px,2fr,3fr,1fr] bg-grey-600 py-2 leading-base1 text-sm text-grey-content-secondary">
            <span className="self-center mx-auto">#</span>
            <div className="pl-6">Name</div>
            <div className="pl-6">Members</div>
          </div>
          {groups?.map(({ name }, i) => (
            <div
              className="draggable-item group grid grid-cols-[64px,2fr,3fr,1fr] hover:bg-white2 items-center"
              key={getClassNames}
            >
              <div className="counter text-grey-content-secondary text-sm self-center w-full mx-auto flex items-center justify-center border-b border-grey-10 h-full">
                {i + 1}
              </div>
              <div
                className={getClassNames(
                  "title text-body-black text-base pl-6 flex items-center gap-x-3 self-center",
                  "h-full border-b border-l py-6 border-grey-10 cursor-pointer"
                )}
                onClick={() => navigate("id")}
              >
                <GroupInitial />
                <span className="header">{name}</span>
              </div>
              <div className="h-full border-b border-l py-6 border-grey-10 pl-6">
                <p className="text-desc-grey text-sm leading-base1 max-w-[380px] h-full flex items-center ">
                  <UserInitial name="L" />
                </p>
              </div>

              <div className="h-full flex items-center justify-center">
                <CustomPopover
                  className="right-0 top-2"
                  placeholder={<EllipsisIcon color="#747678" />}
                  options={[
                    {
                      className: "text-base",
                      name: "Edit Group",
                      id: 1,
                      value: true,
                      onClick: () => setOpenEditModal(true),
                    },
                    {
                      className: "text-base",
                      name: "Roles and Permissions",
                      id: 1,
                      value: true,
                      onClick: () => navigate('id/roles'),
                    },
                    {
                      name: "Delete Group",
                      className:
                        "text-secondary-red hover:text-secondary-red text-base",
                      id: 3,
                      value: true,
                      onClick: () => {},
                    },
                  ]}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Groups;
