import CaretSm from '@/assets/icons/CaretSm';
import { Button } from './button';
import MerchantSummaryHeader from './MerchantSummaryHeader';
import { useEffect, useMemo, useState } from 'react';
import RenderChart from './renderchart';
import { IRootState } from '@/redux/store';
import { useSelector } from 'react-redux';
interface NetworkRiskDistributionProps {
  highRiskCounsumerCount: number;
  mediumRiskCounsumerCount: number;
  lowRiskCounsumerCount: number;
  highRiskConsumerPercentage: number;
  mediumRiskConsumerPercentage: number;
  lowRiskConsumerPercentage: number;
  highRiskConsumerSeries: { label: string; value: number }[];
  mediumRiskConsumerSeries: { label: string; value: number }[];
  lowRiskConsumerSeries: { label: string; value: number }[];
}
const MerchantNetwork = () => {
  const [networkRiskDistributionData, setNetworkRiskDistributionData] =
    useState<NetworkRiskDistributionProps>({
      highRiskCounsumerCount: 0,
      mediumRiskCounsumerCount: 0,
      lowRiskCounsumerCount: 0,
      highRiskConsumerPercentage: 0,
      mediumRiskConsumerPercentage: 0,
      lowRiskConsumerPercentage: 0,
      highRiskConsumerSeries: [],
      mediumRiskConsumerSeries: [],
      lowRiskConsumerSeries: [],
    });
  const { shoppers } = useSelector((state: IRootState) => state.projectSlice);
  const data = useMemo(
    () => [
      {
        risk: 'trusted',
        risk_level: { label: 'Trusted Consumers', value: 'LOW_RISK_SHOPPER' },
        score: 80,
        title: 'Trusted Consumers',
        value: 0,
        date: "Sep '23",
        info: '35% of 43050 Consumers',
        chatColor: '#0ccfa0',
      },
      {
        risk: 'medium',
        risk_level: {
          label: 'Medium Risk Consumers',
          value: 'MEDIUM_RISK_SHOPPER',
        },
        score: 79,
        title: 'Medium Risk Consumers',
        value: 0,
        date: "Sep '23",
        info: '36% of 43050 consumers',
        chatColor: '#efb604',
      },
      {
        risk: 'high',
        risk_level: { label: 'High Risk Consumers', value: 'HIGH_RISK_SHOPPER' },
        score: 69,
        title: 'High Risk Consumers',
        value: 0,
        date: "Sep '23",
        info: '29% of 43050 consumers',
        chatColor: '#e32d2d',
      },
    ],
    []
  );
  useEffect(() => {
    if (shoppers?.users?.length) {
      data?.map((result: any) => {
        result.value = shoppers?.users?.filter(
          (users) =>
            (result.risk === 'trusted' && users.risk_level === 'LOW_RISK_SHOPPER') ||
            (result.risk === 'medium' && users.risk_level === 'MEDIUM_RISK_SHOPPER') ||
            (result.risk === 'high' && users.risk_level === 'HIGH_RISK_SHOPPER')
        ).length;
        result.info = `${Math.round(
          (result.value / shoppers?.users?.length) * 100
        )}%  of ${shoppers?.users?.length} consumers`;
        return undefined;
      });
      setNetworkRiskDistributionData({
        ...networkRiskDistributionData,
        highRiskCounsumerCount: data.find((item) => item.risk === 'high')?.value ?? 0,
        mediumRiskCounsumerCount: data.find((item) => item.risk === 'medium')?.value ?? 0,
        lowRiskCounsumerCount: data.find((item) => item.risk === 'trusted')?.value ?? 0,
      });
    }
  }, [shoppers, data]);

  return (
    <div className="relative bg-white">
      <MerchantSummaryHeader
        heading="Network overview"
        summaryData={{
          merchantCount: 0,
          projectCount: 3,
          totalConsumerCount: 7,
          totalActiveConsumerCount: 7,
          merchantSeries: [],
          customerSeries: [],
          activeConsumerSeries: [],
          projectSeries: [],
          merchantPercentage: 0,
          projectPercentage: 0,
          consumerPercentage: -100,
          activeConsumerPercentage: -100,
        }}
        summaryFilter={30}
      />
      <div className="mb-8">
        <div className="flex items-center justify-between mb-2">
          <p className="text-grey-content-secondary text-base leading-5 font-medium">
            Risk distribution
          </p>
          <Button
            title="Last 30 Days"
            className="pt-[2px] -mt-1 pb-[2px] px-[6px] text-sm1 text-grey-content-tertiary"
            suffixIcon={<CaretSm color="#747678" className="ml-1 rotate-180" />}
          />
        </div>
        <div className="border border-solid border-grey-stroke-primary rounded-lg px-4">
          <div className="grid grid-cols-3 gap-5">
            <div className="relative py-4 border-r border-solid border-grey-stroke-primary">
              <div className="relative">
                <div className="flex items-center gap-1 mb-1">
                  <div className="h-3 w-3 bg-risk-green rounded-sm"></div>
                  <p className="text-base font-medium leading-5 text-grey-content-secondary">
                    Trusted Consumers
                  </p>
                </div>
                <h3 className="text-xl2 font-medium leading-2xl2 text-black mb-1">
                  {networkRiskDistributionData.lowRiskCounsumerCount}
                </h3>
                <span className="font-medium text-sm leading-sm text-score-green">
                  0%
                </span>
              </div>
            </div>
            <div className="relative py-4 border-r border-solid border-grey-stroke-primary">
              <div className="relative">
                <div className="flex items-center gap-1 mb-1">
                  <div className="h-3 w-3 bg-trud-orange rounded-sm"></div>
                  <p className="text-base font-medium leading-5 text-grey-content-secondary">
                    Medium Risk Consumers
                  </p>
                </div>
                <h3 className="text-xl2 font-medium leading-2xl2 text-black mb-1">
                  {networkRiskDistributionData.mediumRiskCounsumerCount}
                </h3>
                <span className="font-medium text-sm leading-sm text-score-green">
                  0%
                </span>
              </div>
            </div>
            <div className="relative py-4 rounded-lg">
              <div className="relative">
                <div className="flex items-center gap-1 mb-1">
                  <div className="h-3 w-3 bg-risk-red rounded-sm"></div>
                  <p className="text-base font-medium leading-5 text-grey-content-secondary">
                    High Risk Consumers
                  </p>
                </div>
                <h3 className="text-xl2 font-medium leading-2xl2 text-black mb-1">
                  {networkRiskDistributionData.highRiskCounsumerCount}
                </h3>
                <span className="font-medium text-sm leading-sm text-score-green">
                  0%
                </span>
              </div>
            </div>
          </div>
          <div className="relative w-full text-center my-5">
            <RenderChart
              series={[
                {
                  name: 'Trusted Consumers',
                  data: [
                    networkRiskDistributionData.lowRiskCounsumerCount,
                    ...Array(29)
                      .fill('-')
                      .map((item) => 0),
                  ],
                },
                {
                  name: 'Medium Risk Consumers',
                  data: [
                    networkRiskDistributionData.mediumRiskCounsumerCount,
                    ...Array(29)
                      .fill('-')
                      .map((item) => 0),
                  ],
                },
                {
                  name: 'High Risk Consumers',
                  data: [
                    networkRiskDistributionData.highRiskCounsumerCount,
                    ...Array(29)
                      .fill('-')
                      .map((item) => 0),
                  ],
                },
              ]}
              height={500}
              chartOptions={{
                colors: ['#0CCFA0', '#EFB604', '#E32D2D'],
                xaxis: {
                  type: 'category',
                  categories: [
                    'Aug 13',
                    'Aug 14',
                    'Aug 15',
                    'Aug 16',
                    'Aug 17',
                    'Aug 18',
                    'Aug 19',
                    'Aug 20',
                    'Aug 21',
                    'Aug 22',
                    'Aug 23',
                    'Aug 24',
                    'Aug 25',
                    'Aug 26',
                    'Aug 27',
                    'Aug 28',
                    'Aug 29',
                    'Aug 30',
                    'Aug 31',
                    'Sep 1',
                    'Sep 2',
                    'Sep 3',
                    'Sep 4',
                    'Sep 5',
                    'Sep 6',
                    'Sep 7',
                    'Sep 8',
                    'Sep 9',
                    'Sep 10',
                    'Sep 11',
                  ],
                },
                tooltip: {
                  y: {
                    formatter: function (value: any) {
                      return parseInt(value).toString();
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantNetwork;
