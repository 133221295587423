import React from "react";
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import MandatoryDropDown from "./MandatoryDropDown";

const AttributeMappingForm = ({ onContinue }) => {
  const attributes = [
    {
      userAttribute: "First name",
      ldapAttribute: "user-attribute-idap-first-name",
    },
    {
      userAttribute: "Last name",
      ldapAttribute: "user-attribute-idap-last-name",
    },
    {
      userAttribute: "Email",
      ldapAttribute: "user-attribute-idap-email",
    },
    {
      userAttribute: "Phone number",
      ldapAttribute: "user-attribute-idap-phone-number",
    },
    {
      userAttribute: "Group",
      ldapAttribute: "user-attribute-idap-group",
    },
    {
      userAttribute: "Last modified",
      ldapAttribute: "user-attribute-idap-last-modified",
    },
  ];
  return (
    <div className="mt-6">
      <div className="border border-stroke bg-white rounded-md">
        <div className="grid grid-cols-[47px,3fr,5fr,2fr,3fr] bg-grey-600 py-2 leading-base1 text-sm text-grey-content-secondary">
          <span className="self-center mx-auto">#</span>
          <div className="pl-4">User Attribute</div>
          <div className="pl-4">LDAP Attribute</div>
          <div className="pl-4">Mandatory</div>
          <div className="pl-4">Default value</div>
        </div>
        {attributes?.map(({ userAttribute, ldapAttribute }, i) => (
          <div
            className="grid grid-cols-[47px,3fr,5fr,2fr,3fr] hover:bg-white2 items-center text-sm1 text-desc-grey"
            key={ldapAttribute}
          >
            <div className="counter self-center w-full mx-auto flex items-center justify-center border-b border-grey-10 h-full">
              {i + 1}
            </div>
            <div className="pl-4 flex items-center gap-x-3 self-center h-full border-b border-l py-4 border-grey-10">
              <span className="header">{userAttribute}</span>
            </div>
            <div className="h-full border-b border-l py-4 border-grey-10 pl-4">
              <p className="leading-base1 max-w-[380px] h-full flex items-center">
                {ldapAttribute}
              </p>
            </div>
            <div className="h-full border-b border-l py-4 border-grey-10 flex justify-center items-center px-4">
              <MandatoryDropDown value="Mandatory" />
            </div>
            <div className="h-full flex items-center border-b border-l py-4 border-grey-10 pl-4">
              <p
                className={getClassNames(
                  "leading-base1 flex items-center capitalize",
                )}
              >
                -
              </p>
            </div>
            <div className="h-full flex items-center justify-center"></div>
          </div>
        ))}
      </div>
      <Button
        title="Continue"
        color="dark"
        category="1"
        className="w-fit mt-6"
        onClick={() => onContinue()}
      />
    </div>
  );
};

export default AttributeMappingForm;
