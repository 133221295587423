import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// Components
import BackArrow from "@components/BackArrow";
import { Button } from "@components/button";
import Input from "@components/input";
import ThemeSelect from "./components/ThemeSelect";

const Organization = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async (data) => {};
  const [theme, setTheme] = useState({
    theme: "Default",
    primary: "#424446",
    secondary: "#1E1F23",
  });
  return (
    <div className="relative flex-1 py-20 px-11 overflow-scroll">
      <BackArrow onClick={() => navigate(-1)} className="absolute" />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-[576px] mx-auto bg-white/[.76] border border-stroke rounded-lg"
      >
        <div className="flex items-center justify-center pt-8 pb-4 border-b border-grey">
          <span className="text-[1.75rem] leading-[2.125rem]">
            Create an Organization
          </span>
        </div>
        <div className="px-12 pt-6 pb-10 flex flex-col gap-y-4">
          <div className="w-full flex flex-col items-center justify-center gap-y-2 mb-2">
            <div className="rounded-full w-14 h-14 bg-grey-stroke-primary flex items-center justify-center text-4xl">
              O
            </div>
            <Button
              title="Upload image"
              color="light"
              category="2"
              onClick={() => {}}
            />
          </div>
          <Input
            name="name"
            label="Name"
            {...{ register, errors }}
            rules={{
              required: true,
              minLength: 2,
            }}
            labelStyle="text-desc-grey"
            placeholder={"Company Name"}
          />
          <Input
            name="domain"
            label="Company Email Domain"
            {...{ register, errors }}
            rules={{
              required: true,
            }}
            labelStyle="text-desc-grey"
            placeholder={"Company Name"}
          />
          <ThemeSelect {...{ theme, setTheme }} />

          <div className="flex items-center gap-x-3 py-4">
            <Button
              title="Create"
              color="dark"
              disabled={!isValid}
              onClick={() => {}}
            />
            <Button title="Cancel" color="light" onClick={() => {}} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Organization;
