import { getRandomInt } from '@/utils/helpers';

export type ITableProps = {
  className?: string;
  bodyData?: any[];
  bodyClass?: string;
  headerData?: { label: string; name: string }[];
  headerClass?: string;
  totalPage: number;
  dataCount?: number;
  pagination?: boolean;
  noDataHeader?: string;
  noDataContent?: string;
};

const Table = ({
  bodyData = [],
  headerData = [],
  headerClass,
  bodyClass,
  className,
  noDataHeader,
  noDataContent,
}: ITableProps) => {
  const renderColumnCell = (row: { [key: string]: string }, columnCell: any) => {
    if (
      !columnCell.subString &&
      typeof columnCell.cell(row) === 'string' &&
      columnCell.cell(row).length > 25
    ) {
      return `${columnCell.cell(row).substring(0, 25)}...`;
    }
    return columnCell.cell(row);
  };

  const renderRowCell = (
    row: { [key: string]: string },
    columnCell: any,
    index: number
  ) => {
    if (columnCell?.option && columnCell?.option?.isIndex) {
      return index;
    }
    if (columnCell) {
      if (row[columnCell.name]) {
        if (row[columnCell.name].length > 100) {
          return `${row[columnCell.name].substring(0, 25)}...`;
        }
        return row[columnCell.name];
      }
      if (columnCell.name.toString().includes('.')) {
        const allKeys = columnCell.name.split('.');
        let newData: any = null;
        allKeys.forEach((element: string) => {
          newData = !newData ? row[element] : newData[element];
        });
        if (newData) {
          return newData;
        }
      }
    }
    return '-';
  };

  const renderInnerHtml = (
    columnCell: any,
    row: { [key: string]: string },
    index: number
  ) => {
    return `<div className="display: flex; align-items: center; gap: 0.5rem;">
        ${renderRowCell(row, columnCell, index)}
      </div>`;
  };
  return (
    <>
      {bodyData.length > 0 ? (
        <div
          className={`mx-8 mb-6 table-wrapper overflow-y-auto h-[calc(100vh-140px)] relative  bg-white rounded-md rounded-t-none container-fluid border border-stroke ${className ?? ''}`}
        >
          <table
            className={`datatable-main w-full  text-sm text-left ${bodyData.length == 0 ? ' h-full' : ''}`}
          >
            <thead
              className={`leading-base1 text-sm text-grey-content-secondary sticky top-0 bg-white ${
                headerClass ?? ''
              }`}
            >
              <tr>
                {headerData.map((val) => (
                  <th
                    key={val.name}
                    scope="col"
                    className={`pl-6 py-2 leading-base1 text-base font-medium text-grey-content-tertiary`}
                  >
                    {val.label}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className={bodyClass ?? 'h-full overflow-auto'}>
              {bodyData.length > 0 && (
                <>
                  {bodyData.map((row: { [key: string]: string }, currentIndex) => {
                    return (
                      <tr
                        className={'hover:bg-white2 group'}
                        key={`tr_${getRandomInt()}`}
                      >
                        {headerData.map(
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          (columnCell: any) => {
                            if (Object.keys(columnCell).length) {
                              return (
                                <td
                                  className={`text-base px-6 border-b border-l first:border-l-0 py-5 border-grey-10 leading-base1 text-grey-content-secondary group-hover:text-blacked font-medium ${columnCell.bodyClass ?? ''}`}
                                  key={`td_${
                                    columnCell?.header ??
                                    Math.floor(Math.random() * 100) + 1
                                  }`}
                                  onClick={() => {
                                    columnCell?.onCellClick?.(row);
                                  }}
                                >
                                  {' '}
                                  {columnCell.cell ? (
                                    renderColumnCell(row, columnCell)
                                  ) : (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: renderInnerHtml(
                                          columnCell,
                                          row,
                                          currentIndex + 1
                                        ),
                                      }}
                                    />
                                  )}
                                </td>
                              );
                            }
                            return <td />;
                          }
                        )}
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          className={` mx-8 mb-6 table-wrapper overflow-y-auto h-[calc(100vh-140px)] relative border border-stroke bg-white rounded-md rounded-t-none container-fluid ${className ?? ''}`}
        >
          <div className="flex items-center justify-center h-full w-full">
            <div className="text-center w-96">
              <h2 className="text-2xl pb-4 text-body-black">
                {noDataHeader ? noDataHeader : 'No results found'}
              </h2>
              <p className="text-grey-content-tertiary text-xl">
                {noDataContent
                  ? noDataContent
                  : 'Please try a different filter or keyword.'}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
