import Switch from '@/components/switch';

const ChangePermission = ({
  title,
  hasPermission,
  description,
}: {
  title: string;
  hasPermission: boolean;
  description: string;
}) => {
  return (
    <div className="flex items-center justify-between mb-6">
      <div className="relative">
        <h6 className="text-lg leading-5 -tracking-[0.2px] font-medium text-body-black mb-2">
          {title}
        </h6>
        <p className="text-md leading-base1 -tracking-[0.2px] font-medium text-grey-content-tertiary">
          {description}
        </p>
      </div>
      <div className="relative">
        <Switch checked={hasPermission} toggleCheck={() => {}} size="md" />
      </div>
    </div>
  );
};

export default ChangePermission;
