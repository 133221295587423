import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const RenderChart = ({
  series,
  height,
  chartOptions,
}: {
  series: {
    name: string;
    data: any[];
  }[];
  height: number;
  chartOptions: any;
}) => {
  const getOptions = () => ({
    ...(chartOptions ?? {}),
    tooltip: {
      theme: 'dark',
      x: {
        show: false,
      },
      style: {
        fontSize: '14px',
      },
      fillSeriesColor: false,
      ...(chartOptions?.tooltip ?? {}),
    },
    chart: {
      background: '#FBFBFB',
      toolbar: {
        show: false,
      },
      fontFamily: 'tt-commons',
      ...(chartOptions?.chart ?? {}),
    },
    grid: {
      strokeDashArray: 2,
    },
    yaxis: {
      opposite: true,
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 600,
          colors: '#A7A8AC',
        },
      },
      tickAmount: 2,
      ...(chartOptions?.yaxis ?? {}),
    },
    legend: {
      show: false,
      ...(chartOptions?.legend ?? {}),
    },
    xaxis: {
      tickPlacement: 'between',
      labels: {
        showDuplicates: true,
        style: {
          fontSize: '14px',
          fontWeight: 600,
          colors: '#A7A8AC',
          ...(chartOptions?.xaxis?.labels?.style ?? {}),
        },
        ...(chartOptions?.xaxis?.labels ?? {}),
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      ...(chartOptions?.xaxis ?? {}),
    },
    stroke: {
      show: true,
      width: 2.5,
      ...(chartOptions?.stroke ?? {}),
    },
  });

  const [options, setOptions] = useState(getOptions());
  useEffect(() => {
    setOptions(getOptions());
  }, [chartOptions]);

  return <Chart height={height} options={options} series={series} type="line" />;
};

export default RenderChart;
