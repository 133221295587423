import React from "react";
import QRCode from "react-qr-code";

type QRCodeContainerProps = {  
  qrcodeLink: string,
};

const QRCodeContainer: React.FC<QRCodeContainerProps> = ({ qrcodeLink }) => {
  return (
    <div className="w-full max-w-[378px] max-h-[379px] rounded-2xl shadow-2xl p-10 bg-white4 ml-auto border border-stroke">
      <QRCode
        fgColor="#24272A"
        style={{
          height: "auto",
          maxWidth: "100%",
          width: "100%",
        }}
        value={qrcodeLink}
      />
    </div>
  );
};

export default QRCodeContainer;
