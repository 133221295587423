// Assets
import React from 'react';
import CaretIcon from '@icons/CaretSm';

type ShopperType = {
  title: string;
  key: string;
  description: string;
  value: number;
  classNm: string;
  icon: React.ReactComponentElement<any, any>;
};

export const shopperTypeMap: Record<string, ShopperType> = {
  LOW_RISK: {
    title: 'Trusted Consumer',
    key: 'low_risk_shopper_options',
    description: `Trusted Consumers have a high assurance on their identity and a low
    ratio of returns and refunds across the network.`,
    value: 80,
    classNm: 'text-risk-green text-lg',
    icon: <CaretIcon color="#02BD8E" width={5} height={4} className="" />,
  },
  MEDIUM_RISK: {
    title: 'Medium Risk Consumer',
    key: 'medium_risk_shopper_options',
    description: `Medium Risk Consumer have a high assurance on their identity and a
                considerable ratio of returns and refunds, but no detected fraud.`,
    value: 79,
    classNm: 'text-trud-orange text-lg text-center min-w-[20px]',
    icon: <CaretIcon color="#EFB604" width={5} height={4} className="rotate-180" />,
  },
  HIGH_RISK: {
    title: 'High Risk Consumer',
    key: 'high_risk_shopper_options',
    description: `High Risk Consumers have medium to low levels on identity assurance,
                  high ratio of returns and refunds and have return and refund issues including fraud.`,
    value: 69,
    classNm: 'text-red-alert text-lg',
    icon: <CaretIcon color="#E5374C" className="rotate-180" />,
  },
};

export const triggerOptions = [
  {
    label: 'Return received by logistics partner',
    value: 'Received by logistics',
  },
  {
    label: 'Return received in warehouse',
    value: 'Received in warehouse',
  },
  {
    label: 'Return approved for refund',
    value: 'Approved for refund',
  },
];

export const executeOptions = [
  { label: 'Immediately', value: 'Immediately' },
  { label: 'After', value: 'After' },
];

export type ShopperOptions = {
  [key in SHOPPER_OPTION]: boolean;
};

export enum SHOPPER_OPTION {
  'Pay by installments' = 'Pay by installments',
  'Pay after delivery' = 'Pay after delivery',
  'Debit/Credit card' = 'Debit/Credit card',
  'Pay by Bank' = 'Pay by Bank',
  'Apple Pay' = 'Apple Pay',
  'Google Pay' = 'Google Pay',
}
