import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { getClassNames } from '@utils/helpers';
import {
  publishSuccessModalHandler,
  publishModalHandler,
  publishProject,
} from '@redux/features/projects';
import { IRootState } from '@/redux/store';
import { Button } from '@components/button';
import { InputContainer } from '@components/input';
import { ReactComponent as CloseIcon } from '@icons/close-lg.svg';
import { ReactComponent as TickSuccessIcon } from '@icons/ticksuccess.svg';
import CopyToClipboard from 'react-copy-to-clipboard';

const PublishModal = () => {
  const { showPublishModal, projectTitle, activeProject } = useSelector(
    (state: IRootState) => state.projectSlice
  );
  const [showShopperOptions, setShowShopperOptions] = useState(false);
  const [showed, setShowed] = useState(false);
  const [copied, setCopied] = useState(false);
  const dispatch = useDispatch();

  const onPublish = async () => {
    if (!activeProject) return;
    await dispatch(publishProject(activeProject?.project_id) as any)
      .unwrap()
      .then(() => {
        dispatch(publishModalHandler(false));
        dispatch(publishSuccessModalHandler(true));
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (copied)
      setTimeout(() => {
        setCopied(false);
      }, 1000);
  }, [copied]);

  return (
    <Transition appear show={showPublishModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full min-w-[1080px] max-w-[1080px] transform rounded-2xl bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all p-10`
                )}
              >
                <div className="flex items-center justify-between">
                  <div className="text-grey-content-secondary rounded-3xl bg-grey-stroke-primary px-2 py-0.5">
                    Step 2
                  </div>
                  <CloseIcon
                    onClick={() => dispatch(publishModalHandler(false))}
                    className="cursor-pointer"
                  />
                </div>
                <h3 className="header3 mt-4 mb-2">Publish</h3>
                <p className="text-grey-content-tertiary paragraph1">
                  Test your project configurations in a sandbox environment before
                  deploying it to production.
                </p>

                <div className="mt-10 border border-grey-stroke-primary rounded h-">
                  <div className="border-b border-grey-stroke-primary ">
                    <div className="flex justify-between px-6 py-4">
                      <Tab.Group>
                        <Tab.List>
                          <Tab>
                            {({ selected }) => (
                              <div
                                className={getClassNames(
                                  'px-4 py-2 text-base border border-grey-stroke-primary rounded font-medium',
                                  selected
                                    ? 'text-trud-black bg-grey-stroke-primary'
                                    : 'text-grey-content-secondary'
                                )}
                              >
                                Sandbox API key
                              </div>
                            )}
                          </Tab>
                        </Tab.List>
                      </Tab.Group>
                    </div>
                  </div>
                  <div className="border-b border-grey-stroke-primary px-6 pt-6 pb-8">
                    <div className="">
                      <h4 className="header4 mt-4 mb-2">Test with sandbox API key</h4>
                      <p className="text-grey-content-tertiary paragraph1">
                        Copy the current API key to securely to authenticate requests and
                        access external services . Periodically generate a new API key to
                        maintain the security of your projects and data.
                      </p>
                    </div>
                    <div className="mt-10 mb-4">
                      <p className="text-desc-grey paragraph1 mb-2">Test key</p>
                      <InputContainer className="flex items-center justify-between text-grey-content-secondary  bg-stroke-primary">
                        <span className="">
                          {showed
                            ? activeProject?.api_key
                            : '●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●'}{' '}
                        </span>
                        <div className="flex gap-2">
                          <Button
                            title={showed ? 'Hide' : 'Show'}
                            onClick={() => setShowed(!showed)}
                            className="!py-1 border-0"
                          />
                          {copied ? (
                            <Button
                              title="Copied"
                              className="!py-1 border-0"
                              prefixIcon={<TickSuccessIcon />}
                            />
                          ) : (
                            <CopyToClipboard
                              text={`${activeProject?.api_key}`}
                              onCopy={() => setCopied(true)}
                            >
                              <Button title="Copy" className="!py-1 border-0" />
                            </CopyToClipboard>
                          )}
                        </div>
                      </InputContainer>
                    </div>
                    <div className="flex justify-center mb-10 mt-6">
                      <Button
                        title="Generate new key"
                        category="2"
                        color="light"
                        className={getClassNames('w-[250px] max-w-[300px] rounded-full')}
                        onClick={() => {}}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between mt-8">
                  <div className="flex gap-x-3 items-center">
                    <Button
                      title="Publish"
                      category="1"
                      color="dark"
                      className={getClassNames('')}
                      onClick={onPublish}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PublishModal;
