import React, { Fragment } from 'react';
import { Transition, Listbox } from '@headlessui/react';
import { createPortal } from 'react-dom';

// Helpers
import { getClassNames } from '@utils/helpers';

// Assets
import { ReactComponent as CaretIcon } from '@icons/arrow_down.svg';

const ListBox = ({ value, onChange }) => {
  const roles = [
    {
      role: 'viewer',
      description: 'Cannot edit or configure',
    },
    {
      role: 'editor',
      description: 'Can edit but cannot perform admin tasks',
    },
    {
      role: 'admin',
      description: 'Can fully configure and edit application',
    },
  ];

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => {
        return (
          <>
            <label
              htmlFor="role"
              className={getClassNames('text-desc-grey text-base leading-5 font-medium')}
            >
              Role
            </label>
            <div className="w-full items-center border border-stroke rounded">
              <Listbox.Button
                className={getClassNames(
                  'flex items-center gap-x-2 justify-between w-full h-full',
                  'bg-white4 border-0 px-2  p-3 md:p-4  text-left cursor-pointer',
                  'focus:outline-none focus:ring-0 focus:border-0 sm:text-sm'
                )}
              >
                <span className="text-body-black text-base leading-5 font-medium capitalize">
                  {value?.role}
                </span>

                <span className="pointer-events-none mt-1">
                  <CaretIcon />
                </span>
              </Listbox.Button>
              <div className="relative" id="positionEl" />

              {open &&
                createPortal(
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      className={getClassNames(
                        'absolute w-max bg-white z-[100] -mt-3 px-1',
                        'shadow-[0px_6px_10px_2px_rgba(218,218,219,0.5)] rounded-[5px] py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'
                      )}
                    >
                      {roles?.map((option) => (
                        <Listbox.Option
                          key={option.role}
                          className={({ active }) =>
                            getClassNames(
                              value.role === option.role
                                ? 'text-white bg-grey'
                                : 'text-gray-900',
                              'cursor-pointer select-none relative py-1 px-1'
                            )
                          }
                          value={option}
                        >
                          <div
                            className={getClassNames(
                              value.role === option.role && 'bg-grey',
                              'flex flex-col items-center w-full py-2 px-2 hover:bg-grey rounded'
                            )}
                          >
                            <span
                              className={getClassNames(
                                'block truncate w-full text-base leading-5 font-medium text-body-black capitalize'
                              )}
                            >
                              {option.role}
                            </span>
                            <span className="w-full font-medium text-sm leading-base1 text-desc-grey">
                              {option.description}
                            </span>
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>,
                  document.getElementById('positionEl')
                )}
            </div>
          </>
        );
      }}
    </Listbox>
  );
};

export default ListBox;
