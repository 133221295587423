import { Fragment, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
// import { toast } from "react-toastify";

// Helpers
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import CreateUserForm from "./createUserForm";
import CreateBulkUserForm from "./createBulkUserForm";

// Assets
import { ReactComponent as CloseIcon } from "@icons/cancel.svg";

const CreateUsersModal = ({ isOpen, closeModal, isBulk, setIsBulk, bulkOption, setBulkOption }) => {

  const [role, setRole] = useState({
    role: "viewer",
    description: "Cannot edit or configure",
  });

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full max-w-[530px] transform rounded-md bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all`
                )}
              >
                <Dialog.Title
                  as="h3"
                  className="flex justify-between items-center text-base1 leading-xl1 text-body-black bg-grey-50 py-[0.625rem] px-[1.625rem] rounded-t-md"
                >
                  <span>Add User</span>

                  <CloseIcon
                    onClick={() => {
                      closeModal(false)
                      setIsBulk(false)
                      setBulkOption(0)
                    }}
                    className="cursor-pointer"
                  />
                </Dialog.Title>

                <div className="flex items-center gap-x-3 p-6 pb-4">
                  <Button
                    title="Single User"
                    color={isBulk ? "light" : "grey"}
                    category="2"
                    className={
                      isBulk
                        ? "border-grey-stroke-primary text-grey-content-secondary"
                        : ""
                    }
                    onClick={() => setIsBulk(false)}
                  />
                  <Button
                    title="Bulk Import"
                    color={!isBulk ? "light" : "grey"}
                    category="2"
                    className={
                      !isBulk
                        ? "border-grey-stroke-primary text-grey-content-secondary"
                        : ""
                    }
                    onClick={() => {
                      setBulkOption(0)
                      setIsBulk(true)
                    }}
                  />
                </div>
                {!isBulk && <CreateUserForm {...{role, setRole, closeModal}} />}
                {isBulk && <CreateBulkUserForm {...{role, setRole, closeModal, bulkOption, setBulkOption}} />}
               
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateUsersModal;
