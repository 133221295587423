import React from 'react'

// Utils
import { getClassNames } from '@utils/helpers';

// Components
import { InputContainer } from "@components/input";
import ToolTip from "@components/ToolTip";
import UserInitial from "@components/UserInitial";

// Assets
import PlusIcon from "@icons/plus";

type CollaboratorsProps = {
  users: string[];
  setUsers: (users: string[]) => void;
  setShowInviteModal: (show: boolean) => void;
}

const Collaborators: React.FC<CollaboratorsProps> = ({ users, setUsers, setShowInviteModal }) => {
  return (
    <div>
    <InputContainer
      label="Collaborators"
      labelStyle="text-desc-grey"
      className={getClassNames(
        "group relative flex justify-between items-center bg-white4",
        users.length ? "md:py-1" : "py-4"
      )}
    >
      {users.length ? (
        <div className="flex gap-x-2">
          {users.map((user, i) => (
            <div
              key={user}
              className={getClassNames(
                "flex gap-x-2 items-center rounded-full bg-stroke-primary p-2",
                i > 0 ? "opacity-50" : ""
              )}
            >
              <UserInitial name={user} size="sm" color="tertiary" />
              <p className="text-body-black">
                {user}
                {i === 0 && (
                  <span className="text-grey-content-secondary ml-0.5">
                    (you)
                  </span>
                )}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <span>Just you</span>
      )}
      <PlusIcon
        onClick={() => {
          setUsers(["Samantha Jonhs", "Hugo Lee"]);
          setShowInviteModal(true);
        }}
        className="cursor-pointer"
      />
      <ToolTip
        info="Invite Collaborators"
        className="right-2 top-10 max-w-[136px]"
      />
    </InputContainer>
    <p className="paragraph1 mt-2">
      Invite team members from your organization to join you in
      setting up and managing this project.
    </p>
  </div>
  )
}

export default Collaborators