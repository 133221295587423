import Dots from '@/assets/icons/Dots';
import Table from '@/components/Table/Table';
import { getRoleColor, organizationUsers, renderNameIcon } from '@/utils/helpers';
import { useEffect, useState } from 'react';

const actionRender = (props: Record<string, any>) => {
  return (
    <div className="flex justify-between">
      <div className="inline-flex items-center gap-1 border border-solid border-stroke-primary px-2 py-1 rounded-full w-auto">
        <div
          className={`w-2 h-2 rounded-sm ${getRoleColor(props?.role?.name ?? '')}`}
        ></div>
        <span className="text-base leading-base1 capitalize text-body-black">
          {props?.role?.name ?? ''}
        </span>
      </div>
      <Dots height={20} width={20} className="" color="#424446" />
    </div>
  );
};

const AllUser = ({ currentRole, search }: { currentRole?: string; search?: string }) => {
  const [userList, setUserList] = useState<any[]>([]);

  useEffect(() => {
    if (search !== '') {
      setUserList(
        organizationUsers.filter((user) => {
          if (
            search &&
            user.name.toLocaleLowerCase().includes(search?.toLocaleLowerCase() ?? '')
          ) {
            return {
              ...user,
              name: user?.name,
              team: {
                name: 'General',
              },
            };
          }
          return false;
        })
      );
    } else {
      setUserList(
        organizationUsers.map((user) => {
          return {
            ...user,
            name: user?.name,
            team: {
              name: 'General',
            },
          };
        })
      );
    }
  }, [search]);

  useEffect(() => {
    if (currentRole === 'all') {
      setUserList(
        organizationUsers.map((user) => {
          return {
            ...user,
            name: user?.name,
            team: {
              name: 'General',
            },
          };
        })
      );
    } else {
      setUserList(
        organizationUsers.filter((user) => {
          if (user.role.name === currentRole) {
            return {
              ...user,
              name: user?.name,
              team: {
                name: 'General',
              },
            };
          }
          return false;
        })
      );
    }
  }, [currentRole]);

  const columnData: any[] = [
    {
      label: '#',
      className: 'background-white w-[44px]',
      option: {
        sort: false,
        hasFilter: false,
        isIndex: true,
      },
    },
    {
      cell: (props: { name: string; image?: string }) => {
        return renderNameIcon({
          name: props?.name,
          imageUrl: props?.image,
          isCircle: true,
          className: 'bg-grey-content-quaternary text-white font-medium',
          textClassName:
            'flex items-center ml-2 text-body-black font-medium group-hover:text-blacked text-base',
        });
      },
      label: 'Name',
    },
    {
      name: 'team.name',
      label: 'Team',
      option: {
        sort: false,
        hasFilter: false,
      },
    },
    {
      label: 'Role',
      cell: (props: any) => actionRender(props),
    },
  ];

  return (
    <>
      <Table
        headerData={columnData}
        bodyData={userList}
        totalPage={1}
        noDataHeader={
          !search && currentRole === 'all' && userList?.length === 0
            ? 'Add Users to this Organization'
            : ''
        }
        noDataContent={
          !search && currentRole === 'all' && userList?.length === 0
            ? 'Invite users to become part of this organisation through email or share an invite link.'
            : ''
        }
      />
    </>
  );
};

export default AllUser;
