import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Context
import { authContext } from '@context/auth';

// Helpers
// import { axiosInstance } from "@utils/baseAxios";
import { getClassNames } from '@utils/helpers';

// Components
import { Button } from '@components/button';
import BackArrow from './BackArrow';

// Assets
// import { ReactComponent as NotificationIcon } from '@icons/notification.svg';
import GroupInitial from '@pages/groups/components/groupInitial';
import ReactDropDown from './ReactDropdown';
import NameBadge from './Badge/NameBadge';

interface TopbarProps {
  title: string;
  buttonAction?: () => void;
  buttonConfig?: {
    title: string;
    color?: 'light' | 'dark' | 'dark2' | 'grey' | 'dark3';
  } | null;
  showBackButton?: boolean;
  showExtras?: boolean;
  showTitleTag?: boolean;
  options?: React.ReactNode;
  backButtonAction?: () => void;
  color?: string;
  className?: string;
  children?: React.ReactNode;
  titleClassName?: string;
  secondButtonConfig?: { color: string; title: string };
  secondButtonAction?: () => void;
}

const Topbar: React.FC<TopbarProps> = ({
  title,
  buttonAction,
  buttonConfig = null,
  showBackButton = false,
  showExtras = true,
  showTitleTag,
  options,
  backButtonAction,
  color = '',
  className = '',
  children,
  titleClassName,
  secondButtonAction,
  secondButtonConfig,
}) => {
  const navigate = useNavigate();
  const colors: Record<string, string> = {
    '': 'bg-white2',
    white: 'bg-white',
  };

  return (
    <div
      className={getClassNames(
        'w-full py-[0.62rem] px-8 flex border-b-grey ',
        colors[color],
        className
      )}
    >
      <div className="flex items-center gap-x-2">
        {showBackButton ? (
          <BackArrow onClick={backButtonAction ? backButtonAction : () => navigate(-1)} />
        ) : null}
        {showTitleTag ? <GroupInitial /> : null}
        <h4 className={`header4 whitespace-nowrap ${titleClassName}`}>{title}</h4>
        {options}
      </div>
      <div className="flex items-center justify-between w-full">{children}</div>
      <div
        className={getClassNames('flex items-center gap-x-4', !children ? 'ml-auto' : '')}
      >
        {buttonConfig && (
          <Button
            title={buttonConfig.title}
            category="1"
            color={buttonConfig.color || 'light'}
            onClick={buttonAction}
          />
        )}
        {secondButtonConfig && (
          <Button
            title={secondButtonConfig?.title}
            category="1"
            color={secondButtonConfig ? (secondButtonConfig.color as 'dark') : 'light'}
            onClick={secondButtonAction}
          />
        )}
        {/* <div
          className="theme-switch rounded-full w-10 h-10 bg-primary cursor-pointer border border-stroke"
          onClick={() => switchTheme()}
        ></div> */}
        {showExtras && (
          <>
            {/* <NotificationIcon className="cursor-pointe" /> */}
            <ReactDropDown
              Options={[
                {
                  label: 'Logout',
                  handleChange: () => {
                    window.location.href =
                      'https://experiencemastercard.trudenty.com/select-demo';
                  },
                },
              ]}
              menuHeading={
                <NameBadge
                  FirstName={localStorage.getItem('email') || 'Z'}
                  parentClass="bg-grey-content-tertiary text-white p-2 "
                />
              }
              btnClass="rounded-full overflow-hidden"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Topbar;
