import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

// Utils
import { getClassNames } from "@utils/helpers";

// Assets
import { Button } from "@components/button";
import { ReactComponent as GearIcon } from "@icons/gear2.svg";
import PlusIcon from "@icons/plus";
import { ReactComponent as ScoreIcon } from "@icons/score.svg";

type FocusedStateProps = {
  showFocusedStated: boolean;
  setShowFocusedStated: (value: boolean) => void;
};  

const FocusedState: React.FC<FocusedStateProps> = ({
  showFocusedStated,
  setShowFocusedStated,
}) => {
  return (
    <Transition appear show={showFocusedStated} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="relative flex min-h-full">
            <Transition.Child
              as={"div"}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `min-w-[479px] max-w-[479px] min-h-[336px] ml-[300px] mt-[190px] transform rounded-lg bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all`
                )}
              >
                <div className="bg-white/[0.91] flex-1 rounded-lg">
                  <Link to="/projects/new">
                    <div className="flex gap-x-3 items-center pt-7 pl-7 pr-9 pb-6 border-b border-b-grey">
                      <button
                        type="button"
                        className="rounded bg-grey-70 w-10 h-10 flex items-center justify-center  focus:outline-none focus-visible:ring-0"
                      >
                        <PlusIcon size="12" />
                      </button>
                      <h3 className="text-xl mr-1">Setup a project</h3>
                    </div>
                  </Link>
                  <div className="pt-8 pl-7 flex flex-col gap-y-11">
                    <div className="flex gap-x-4 items-center mt-3">
                      <ScoreIcon className="w-10 h-10" />
                      <span className="text-grey-content-secondary text-base1 leading-5 max-w-[280px]">
                        Get started with knowing your consumers by creating a
                        project.
                      </span>
                    </div>
                    <div className="flex gap-x-4 items-center">
                      <GearIcon className="w-10 h-10" />

                      <span className="text-grey-content-secondary text-base1 leading-5 max-w-[280px]">
                        Publish a project, integrate and improve your business’
                        workflows.
                      </span>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <Dialog.Panel
              className={getClassNames(
                `absolute bottom-24 left-5 w-full max-w-[203px] transform rounded-lg bg-body-black 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all p-5`
              )}
            >
              <h6 className="text-white">Welcome to Trudenty!</h6>
              <p className="text-sm1 text-grey-content-tertiary mt-3">
                Let’s get you started by setting up a project. <br /> <br /> Projects can be
                set up for Sandbox or Production environment, including any of
                our products.
              </p>
              <div className="flex mt-10">
                <Button title="Got it" color="light" category="2" className="text-sm1" onClick={() => setShowFocusedStated(false) } />
                <Button title="Hide" category="2" className="bg-body-black text-white text-sm1" onClick={() => setShowFocusedStated(false) } />
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default FocusedState;
