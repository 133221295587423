import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import { GroupTag } from "@components/GroupSelect";
import SearchInput from "@components/searchInput";
import Topbar from "@components/topbar";
import UserActivities from "./components/user/userActivities";
import UserCredentials from "./components/user/userCredentials";
import UserDetails from "./components/user/userDetails";
import UserInitial from "@components/UserInitial";
import PageNav from "./components/user/pageNav";

const User = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("Details");
  const navs = [
    {
      title: "Details",
      to: "details",
    },
    {
      title: "Credentials",
      to: "credentials",
    },
    {
      title: "Activities",
      to: "activities",
    },
  ];
  return (
    <div className="flex-1 flex flex-col">
      <Topbar
        title="User"
        showBackButton
        backButtonAction={() => navigate("/users")}
      >
        <div className="ml-auto mr-4">
          <SearchInput handleChange={() => {}} height="h-full" />
        </div>
      </Topbar>
      <div className="h-full grid grid-cols-[236px,1fr] flex-1">
        <PageNav {...{ selected, setSelected, navs }} />
        <div className="bg-white3 flex-1 flex flex-col py-8 px-20 h-full">
          <div className="flex items-center justify-between w-full">
            <div className="mb-6 flex items-center gap-x-2">
              <UserInitial name="Marco Kome" />
              <h4 className="header4">Marco Kome</h4>
            </div>
            <GroupTag title="Internal Team" />
          </div>

          {selected === "Details" && <UserDetails />}
          {selected === "Credentials" && <UserCredentials />}
          {selected === "Activities" && <UserActivities />}
        </div>
      </div>
    </div>
  );
};

export default User;
