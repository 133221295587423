import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import {
  getClassNames,
  getRiskColor,
  getRiskTitle,
  getRiskLevelColor,
} from '@utils/helpers';

// Redux
import { IRootState } from '@redux/store';
import { setRiskLevelFilter } from '@redux/features/projects';

// Components
import { Button } from '@/components/button';
import ToolTip from '@components/ToolTip';

// Assets
import { ReactComponent as Chatline1 } from '@images/chatlines1.svg';
import { ReactComponent as Chatline2 } from '@images/chatlines2.svg';
import { ReactComponent as Chatline3 } from '@images/chatlines3.svg';
import SparklineChart from '@/components/charts';

type ShoppersChartsProps = {
  iref: React.RefObject<HTMLDivElement>;
};
const ShoppersCharts: React.FC<ShoppersChartsProps> = ({ iref }) => {
  const dispatch = useDispatch();
  const { shoppers, riskLevelFilter } = useSelector(
    (state: IRootState) => state.projectSlice
  );
  const data = useMemo(
    () => [
      {
        risk: 'trusted',
        risk_level: { label: 'Trusted Consumers', value: 'LOW_RISK_SHOPPER' },
        score: 80,
        title: 'Trusted Consumers',
        value: 0,
        date: "Sep '23",
        info: '35% of 43050 Consumers',
        chatColor: '#0ccfa0',
      },
      {
        risk: 'medium',
        risk_level: {
          label: 'Medium Risk Consumers',
          value: 'MEDIUM_RISK_SHOPPER',
        },
        score: 79,
        title: 'Medium Risk Consumers',
        value: 0,
        date: "Sep '23",
        info: '36% of 43050 consumers',
        chatColor: '#efb604',
      },
      {
        risk: 'high',
        risk_level: { label: 'High Risk Consumers', value: 'HIGH_RISK_SHOPPER' },
        score: 69,
        title: 'High Risk Consumers',
        value: 0,
        date: "Sep '23",
        info: '29% of 43050 consumers',
        chatColor: '#e32d2d',
      },
    ],
    []
  );
  const [scoreList, setScoreList] = useState(data);

  useEffect(() => {
    if (shoppers?.users?.length) {
      data?.map((result) => {
        result.value = shoppers?.users?.filter(
          (users) =>
            (result.risk === 'trusted' && users.risk_level === 'LOW_RISK_SHOPPER') ||
            (result.risk === 'medium' && users.risk_level === 'MEDIUM_RISK_SHOPPER') ||
            (result.risk === 'high' && users.risk_level === 'HIGH_RISK_SHOPPER')
        ).length;
        result.info = `${Math.round(
          (result.value / shoppers?.users?.length) * 100
        )}%  of ${shoppers?.users?.length} consumers`;
        return undefined;
      });
      setScoreList(data);
    }
  }, [shoppers, data]);

  const handleRiskLevelFilter = (risk_level: { label: string; value: string }) => {
    if (risk_level.value === riskLevelFilter.value)
      dispatch(setRiskLevelFilter({ label: 'Recent', value: '' }));
    else dispatch(setRiskLevelFilter(risk_level));
  };
  return (
    <div className="grid grid-cols-3 gap-x-2 mt-2" ref={iref}>
      {scoreList.map((item) => (
        <div
          className={getClassNames(
            'group relative p-4 bg-white hover:bg-grey-stroke-tertiary border border-grey-stroke-primary',
            'flex justify-between items-end rounded-lg'
          )}
          key={item.risk}
        >
          <div className="flex flex-col group relative">
            <RiskTag risk={item.risk} title={item.title} />
            <span className="header4">{item.value}</span>
            <span className="font-medium text-sm leading-sm uppercase text-score-green">
              0%
            </span>
            <ToolTip
              info={item.info}
              className="top-14 min-w-[132px] whitespace-nowrap text-sm"
            />
          </div>
          {/* {item.chat} */}
          <SparklineChart color={item.chatColor} series={[0, 0]} />
          <Button
            title="View"
            color="light"
            onClick={() => handleRiskLevelFilter(item.risk_level)}
            className={getClassNames(
              'hidden group-hover:block absolute z-50 top-2 right-2',
              'py-1 md:py-1 lg:py-1 px-2 md:px-2 lg:px-2 text-sm1'
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default ShoppersCharts;

type ScoreBubbleProps = {
  score: number;
  inactive?: boolean;
  risk_level: string;
};

export const ScoreBubble: React.FC<ScoreBubbleProps> = ({
  score,
  inactive,
  risk_level,
}) => (
  <div
    className={getClassNames(
      'py-4 px-6 bg-grey text-[3.5rem] flex items-center justify-center w-[110px] h-[110px] rounded-full',
      getRiskLevelColor(risk_level, inactive)
    )}
  >
    {score}
  </div>
);

type RiskTagProps = {
  title?: string;
  risk: string;
  className?: string;
  inactive?: boolean;
};

export const RiskTag: React.FC<RiskTagProps> = ({
  title,
  risk,
  className = '',
  inactive = false,
}) => (
  <div className={getClassNames('flex items-center gap-x-1', className)}>
    <div className={getClassNames('w-3 h-3 rounded-sm', getRiskColor(risk, inactive))} />
    <span className="text-grey-content-secondary">{title ?? getRiskTitle(risk)}</span>
  </div>
);
