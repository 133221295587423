import React from "react";
import { getClassNames } from "@utils/helpers";

const Progress = ({ step, setStep, options }) => {

  return (
    <div
      className={getClassNames(
        "relative w-full min-h-[52px] grid h-full text-black border border-stroke rounded translate-x-0",
      )}
      style={{
        gridTemplateColumns: `repeat(${options.length}, minmax(0, 1fr))`
      }}
    >
      <div
        className={getClassNames(
          "absolute bg-stroke4 transition-transform duration-300 h-full border border-stroke",
          step === 0 && "rounded-s", step === options.length - 1 && "rounded-r"
        )}
        style={{
          width: `${100/options.length}%`,
          transform: `translate(${100 * step}%)`
        }}
      />
      {options.map((option, i) => (
        <div
          key={option}
          onClick={() => setStep(i)}
          className={getClassNames(
            "h-full flex items-center gap-x-2 px-4 py-3 z-10 border-x border-stroke"
          )}
        >
          <div
            className={getClassNames(
              "rounded-full min-w-[28px] min-h-[28px] h-7 w-7 flex items-center justify-center text-white",
              step === i ? "bg-primary" : "bg-trud-black"
            )}
          >
            {i + 1}
          </div>
          <span className={getClassNames("cursor-pointer", step === i ? "text-black" : "text-grey-content-secondary")}>
            {option}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Progress;
