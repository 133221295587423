import { getClassNames } from '@utils/helpers';

interface TagProps {
  title: string;
}

export const GroupTag: React.FC<TagProps> = ({ title }) => (
  <span
    className={getClassNames(
      'text-white text-sm leading-5 font-medium capitalize',
      'bg-grey-content-tertiary rounded px-2 py-1'
    )}
  >
    {title}
  </span>
);

interface RoleTagProps {
  role: 'Admin' | 'Viewer' | 'Editor';
}

export const RoleTag: React.FC<RoleTagProps> = ({ role }) => (
  <span
    className={getClassNames(
      'text-grey-content-secondary text-sm leading-5 font-medium capitalize',
      'bg-grey-800 rounded px-2 py-1'
    )}
  >
    {role}
  </span>
);
