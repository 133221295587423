// Assets
import { ReactComponent as ComingSoonIcon } from '@icons/coming-soon.svg';
const ComingSoon = ({
  title,
  content,
  className = '',
}: {
  title: string;
  content: string;
  className?: string;
}) => {
  return (
    <div
      className={`absolute inset-0 bg-[#F6F7F7] flex items-center justify-center z-10 bg-opacity-95 ${className}`}
    >
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-[22px] font-medium text-body-black">{title}</h2>
        <p className="text-grey-content-secondary text-base text-center mt-2 w-[450px]">
          {content}
        </p>
        <div className="flex gap-2 mt-4 border border-grey-content-quaternary bg-grey-stroke-primary text-body-black rounded-full px-4 py-2">
          <ComingSoonIcon /> <span className="font-medium">Coming soon</span>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
