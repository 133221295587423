import { getClassNames, getRandomInt } from '@/utils/helpers';
import { Menu } from '@headlessui/react';
import { ReactNode } from 'react';
interface ReactDropdownProps {
  Options: { label: string; handleChange: () => void; className?: string }[];
  btnClass?: string;
  optionsClass?: string;
  menuHeading?: ReactNode;
}

const ReactDropDown = ({
  Options,
  btnClass,
  optionsClass,
  menuHeading,
}: ReactDropdownProps) => {
  return (
    <Menu>
      <div className="relative">
        <Menu.Button className={`${btnClass ?? ''} `}>{menuHeading}</Menu.Button>
        <Menu.Items
          className={` min-w-32 z-20 bg-white rounded-lg shadow-1 px-1 py-2 border border-solid border-stroke-secondary mt-2 absolute top-full right-2 ${optionsClass ?? ''}`}
        >
          {Options.map((option) => {
            return (
              <Menu.Item key={`option.label_${getRandomInt()}`}>
                {({ active }) => (
                  <div className={`${active ? 'bg-stroke-primary' : ''}`}>
                    <button
                      onClick={() => {
                        option.handleChange();
                      }}
                      className={getClassNames(
                        'paragraph1 flex py-2 pl-2 w-full text-body-black',
                        option.className ?? ''
                      )}
                    >
                      {option.label}
                    </button>
                  </div>
                )}
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </div>
    </Menu>
  );
};

export default ReactDropDown;
