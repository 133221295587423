import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

// Redux
import {
  editTrustedShoppersConfigInBatch,
  saveModalHandler,
  getTrustedShopperConfig,
  publishModalHandler,
  Project,
  FraudRiskTypeProps,
} from '@redux/features/projects';
import { IRootState } from '@/redux/store';

// Utils
import { SHOPPER_TRUST_INDEX } from '@utils/constants';
import { getClassNames, getLocalStorageData, setLocalStorageData } from '@utils/helpers';

// Components
import ActionButtons from './components/actionButtons';
import ConfigurationNav from './components/configurationNav';
import ProjectTopBar from './components/projectTopBar';
import ShopperTrustConfigSetup from './shopper-trust-index/components/shopperTrustConfigSetup';
import TrustedShopperConfigSetup from './shopper-trust-index/components/trustedShopperConfigSetup';
import ShopperExperience from './checkout-and-refund/shopperExperience';
import StepsIndicator from './components/stepsIndicator';
import { useNavigate } from 'react-router-dom';
import { authContext } from '@/context/auth';

const ShopperConfiguration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [stage, setStage] = useState(0);
  const [fraudRiskValue, setFraudRiskValue] = useState<FraudRiskTypeProps>();
  const { updatedShopperTrustedScore } = useContext(authContext);
  const {
    activeProject,
    projectType,
    shopperTrustTotal,
    editedTrustedShoppersScoreForSubmission,
    showPublishModal,
    showSaveModal,
    showPublishSuccessModal,
    showSaveSuccessModal,
  } = useSelector((state: IRootState) => state.projectSlice);

  const { register } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const stepsData = [
    [
      {
        title: 'Consumer Trust Index',
        description: `A granular fraud risk assessment to identify and differentiate first-party fraudsters and trusted consumers. The assessment is based on a 360 understanding of the consumer’s actions across the commerce and payments ecosystem.`,
        info: 'Customise the index score weightings for your company to sum up to 100',
      },
      {
        title: 'Consumer Trust Index',
        description: `A granular fraud risk assessment to identify and differentiate first-party fraudsters and trusted consumers. The assessment is based on a 360 understanding of the consumer’s actions across the commerce and payments ecosystem.`,
        info: 'Customise the index score weightings for your company to sum up to 100',
      },
      {
        title: 'Trusted Consumer Score',
        stepTitle: 'Consumer Trust Index',
        description: `Segment consumers based on their fraud risk scores into Trusted, Medium Risk and High Risk consumers within your network.`,
        info: 'Segment consumers into ranges to define Trusted, Medium, and High-Risk groups.',
      },
    ],
    [
      {
        title: 'Define Consumer Experience',
        stepTitle: 'Payment Personalization',
        description: `Define the payments and refund experience for consumers in your network, based on their fraud risk scores.`,
        info: '',
      },
    ],
    [
      {
        title: 'Payment Personalization',
        description: `Define the payments and refund experience for Consumers in your network,
        based on their unique Consumer Trust Index.`,
        info: '',
      },
    ],
    [{}],
    [{}],
  ];

  const scoreObjectToArray2 = (obj: object) => {
    if (!obj) return [];
    return Object.keys(editedTrustedShoppersScoreForSubmission).map((key) => ({
      id: key,
      score: editedTrustedShoppersScoreForSubmission[key].score,
    }));
  };
  const onContinue = async () => {
    if (activeProject && activeProject.products === SHOPPER_TRUST_INDEX) {
      if (step === 0 && stage === 0) {
        setStage(1);
      } else if (step === 0 && stage === 1) {
        if (shopperTrustTotal === 100) {
          if (updatedShopperTrustedScore.length) {
            const localData = getLocalStorageData(`project_${activeProject?.id}`);
            if (
              localData &&
              localData?.config?.length &&
              updatedShopperTrustedScore.length
            ) {
              updatedShopperTrustedScore.forEach((obj2: any) => {
                const obj1ToUpdate = localData?.config.find(
                  (obj1: any) => obj1.id === obj2.id
                );
                if (obj1ToUpdate) {
                  Object.assign(obj1ToUpdate, obj2);
                }
              });
              setLocalStorageData(
                `project_${activeProject?.id}`,
                JSON.stringify(localData)
              );

              const activeData =
                getLocalStorageData(`project${activeProject?.id}`) || null;
              if (
                activeData &&
                activeData?.data.length &&
                fraudRiskValue &&
                activeData.data[0].fraud_risk_type_id !== fraudRiskValue.id
              ) {
                activeData.data[0].fraud_risk_type = fraudRiskValue;
                activeData.data[0].fraud_risk_type_id = fraudRiskValue.id;
                setLocalStorageData(
                  `project${activeProject?.id}`,
                  JSON.stringify(activeData)
                );
              }
              setStage(2);
            }
          }
        } else {
          toast.error('Weightings can only add up to a maximum of 100');
        }
      } else {
        if (editedTrustedShoppersScoreForSubmission) {
          const t = await scoreObjectToArray2(editedTrustedShoppersScoreForSubmission);
          const localData = getLocalStorageData(`project${activeProject?.id}`);
          const storageData = localData?.data.find(
            (proj: Project) => proj.environment === 'SANDBOX'
          );
          if (storageData?.trusted_shopper_score_configs?.length) {
            storageData?.trusted_shopper_score_configs?.map((data: any) => {
              const findExist = t.find((tdata) => tdata.id === data.id);
              if (findExist) data.score = findExist.score.toFixed(2);
              return data;
            });
          }
          setLocalStorageData(`project${activeProject?.id}`, JSON.stringify(localData));
          await dispatch(editTrustedShoppersConfigInBatch(t) as any)
            .unwrap()
            .then(async () => {
              await dispatch(getTrustedShopperConfig(activeProject?.id) as any);
              dispatch(publishModalHandler(true));
            })
            .catch((err: any) => {
              console.log(err);
            });
        } else {
          dispatch(publishModalHandler(true));
        }
      }
    } else {
      if (!activeProject) {
        return;
      }
      if (step === 0 && stage === 0) {
        setStage(1);
      } else if (step === 0 && stage === 1) {
        if (shopperTrustTotal === 100) {
          if (updatedShopperTrustedScore.length) {
            const localData = getLocalStorageData(`project_${activeProject?.id}`);
            if (
              localData &&
              localData?.config?.length &&
              updatedShopperTrustedScore.length
            ) {
              updatedShopperTrustedScore.forEach((obj2: any) => {
                const obj1ToUpdate = localData?.config.find(
                  (obj1: any) => obj1.id === obj2.id
                );
                if (obj1ToUpdate) {
                  Object.assign(obj1ToUpdate, obj2);
                }
              });
              setLocalStorageData(
                `project_${activeProject?.id}`,
                JSON.stringify(localData)
              );

              const activeData =
                getLocalStorageData(`project${activeProject?.id}`) || null;
              if (
                activeData &&
                activeData?.data.length &&
                fraudRiskValue &&
                activeData.data[0].fraud_risk_type_id !== fraudRiskValue.id
              ) {
                activeData.data[0].fraud_risk_type = fraudRiskValue;
                activeData.data[0].fraud_risk_type_id = fraudRiskValue.id;
                setLocalStorageData(
                  `project${activeProject?.id}`,
                  JSON.stringify(activeData)
                );
              }
              setStage(2);
            }
          }
        } else {
          toast.error('Weightings can only add up to a maximum of 100');
        }
      } else if (step === 0 && stage === 2) {
        if (editedTrustedShoppersScoreForSubmission) {
          const t = await scoreObjectToArray2(editedTrustedShoppersScoreForSubmission);
          const localData = getLocalStorageData(`project${activeProject?.id}`);
          const storageData = localData?.data.find(
            (proj: Project) => proj.environment === 'SANDBOX'
          );
          if (storageData?.trusted_shopper_score_configs?.length) {
            storageData?.trusted_shopper_score_configs?.map((data: any) => {
              const findExist = t.find((tdata) => tdata.id === data.id);
              if (findExist) data.score = findExist.score.toFixed(2);
              return data;
            });
          }
          setLocalStorageData(`project${activeProject?.id}`, JSON.stringify(localData));
          await dispatch(editTrustedShoppersConfigInBatch(t) as any)
            .unwrap()
            .then(async () => {
              await dispatch(getTrustedShopperConfig(activeProject?.id) as any);
              setStage(0);
              setStep(1);
            })
            .catch((err: any) => {
              console.log(err);
            });
        } else {
          setStage(0);
          setStep(1);
        }
      } else {
        dispatch(saveModalHandler(true));
      }
    }
  };

  useEffect(() => {
    if (!activeProject) {
      navigate(-1);
    }
  }, [activeProject, navigate]);

  return (
    <div
      className={getClassNames(
        'w-full h-screen max-h-screen flex-1 flex flex-col bg-white overflow-hidden',
        showPublishModal ||
          showSaveModal ||
          showPublishSuccessModal ||
          showSaveSuccessModal
          ? 'blur-sm'
          : ''
      )}
    >
      <ProjectTopBar {...{ step, stage, setStage, setStep }} />
      <div className="grid grid-cols-[285px,1fr] gap-x-10 h-full flex-1 max-h-full overflow-hidden">
        <ConfigurationNav {...{ step, setStep, stage, setStage }} />
        <div className="max-w-full h-full max-h-full flex flex-col overflow-auto">
          <div className="max-w-full overflow-hidden ml-[6%] mr-[8%] pt-8 flex-1 flex flex-col text-grey-content-tertiary">
            <StepsIndicator {...{ step, stepsData, stage }} />
            <div
              className={getClassNames(
                'h-fit max-h-fit',
                step < 1 ? 'overflow-auto' : 'flex flex-col h-full'
              )}
            >
              {step === 0 && stage <= 1 && (
                <ShopperTrustConfigSetup
                  {...{
                    step,
                    setStep,
                    stepsData,
                    stage,
                    setStage,
                    register,
                    fraudRiskValue,
                    setFraudRiskValue,
                  }}
                />
              )}
              {((step === 0 && stage === 2) ||
                (projectType === 'shopperTrustIndex' && step > 1)) && (
                <TrustedShopperConfigSetup
                  {...{
                    step,
                    setStep,
                    stepsData,
                    stage,
                    setStage,
                    register,
                  }}
                />
              )}
              <ShopperExperience {...{ step, setStep, setStage }} />
            </div>
            {step < 1 && <ActionButtons {...{ stage, setStage, onContinue }} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopperConfiguration;
