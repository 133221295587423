import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '@/redux/store';
import { CHECKOUT_AND_REFUND, SHOPPER_TRUST_INDEX } from '@utils/constants';
import { setProjectType } from '@redux/features/projects';
import { getProjectById } from '@redux/features/projects';
import { getShopperTrustConfig } from '@redux/features/projects';

const EditProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = [CHECKOUT_AND_REFUND, SHOPPER_TRUST_INDEX];
  const { activeProject } = useSelector((state: IRootState) => state.projectSlice);
  const onEdit = async (option: number) => {
    if (activeProject?.environment !== 'SANDBOX') return;
    dispatch(getProjectById(activeProject?.id) as any);
    await dispatch(getShopperTrustConfig(activeProject?.id) as any).then(() => {
      dispatch(setProjectType(options[option]));
      if (activeProject.products === SHOPPER_TRUST_INDEX) {
        dispatch(setProjectType(SHOPPER_TRUST_INDEX));
      } else {
        dispatch(setProjectType(CHECKOUT_AND_REFUND));
      }
      navigate('/projects/configure', { replace: true, state: 'Edit' });
    });
  };

  useEffect(() => {
    activeProject?.products === SHOPPER_TRUST_INDEX ? onEdit(1) : onEdit(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default EditProject;
