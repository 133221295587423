import React from "react";

// CONSTANTS
import {
  CHECKOUT_AND_REFUND,
  SHOPPER_TRUST_INDEX,
  RETURNS_ORCHESTRATOR,
  PAYMENT_PERSONALIZATION
} from "@utils/constants";

// Utils
import { getClassNames, projectTypes } from "@utils/helpers";

// Components
import { Button } from "@components/button";

// Assets
import PlusIcon from "@icons/plus";
import { ReactComponent as TickIcon } from "@icons/circle-tick-lg.svg";

type NewProjectTemplateProps = {
  option?: string;
  setOption: (option: string) => void;
};

const getSelectButtonTitle = (option: string, type: string) => {
  if (
    type === SHOPPER_TRUST_INDEX &&
    (option === PAYMENT_PERSONALIZATION || option === RETURNS_ORCHESTRATOR)
  )
    return "Included";
  else if (option === type) return "Selected";
  else return "Select";
};

const NewProjectTemplate: React.FC<NewProjectTemplateProps> = ({ option = 'SHOPPER_TRUST_INDEX', setOption }) => {
  return (
    <div className="grid grid-cols-2 gap-4 max-w-[850px] mx-auto mb-10">
      {projectTypes?.map(({ name, description, pecks, icon, type }) => (
        <div
          key={type}
          onClick={() => setOption(type)}
          className={getClassNames(
            "flex flex-col hover:bg-white2 min-h-[492px] cursor-pointer rounded-lg",
            "border border-grey-stroke-primary px-8 py-10",
            option === type ? "bg-white2" : "",
            type === SHOPPER_TRUST_INDEX &&
              (option === CHECKOUT_AND_REFUND ||
                option === RETURNS_ORCHESTRATOR) ?
              "opacity-50" : ""
          )}
        >
          <div className="flex flex-col flex-1">
            {icon}
            <h4 className="header5 mt-4 mb-1">{name}</h4>
            <p className="text-grey-content-tertiary text-base1 leading-xl1">
              {description}
            </p>
            <h6 className="text-base text-grey-content-tertiary mt-8">
              Includes
            </h6>
            {pecks?.map((peck) => (
              <div
                key={peck}
                className="flex items-center gap-x-2 px-4 py-3 border border-grey-stroke-primary"
              >
                <div className="w-2 h-2 bg-grey-stroke-primary" />
                <h6 className="text-base text-body-black">{peck}</h6>
              </div>
            ))}
          </div>

          <Button
            title={getSelectButtonTitle(option, type)}
            category="2"
            color="light"
            className="w-fit"
            onClick={() => setOption(type)}
            prefixIcon={
              getSelectButtonTitle(option, type) === "Select" ? (
                <PlusIcon className="mr-0.5" />
              ) : (
                <TickIcon className="w-3 mr-0.5" />
              )
            }
          />
        </div>
      ))}
    </div>
  );
};

export default NewProjectTemplate;
