import { useState } from 'react';

// Assets
import { ReactComponent as SearchIcon } from '@icons/search.svg';

interface SearchInputProps {
  placeholder?: string;
  handleChange: (query: string) => void;
  className?: string;
  value?: string;
  inputClassName?: string;
  setSearchOnClick?: boolean;
}

const SearchInput = ({
  placeholder,
  handleChange,
  className = 'rounded-[20px] h-[37px]',
  value,
  inputClassName = '',
  setSearchOnClick = true,
}: SearchInputProps) => {
  const [startSearch] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const handleQueryChange = (query: string) => {
    if (query === '' && !query) {
      setShowSearchInput(false);
    }
    handleChange(query);
  };

  return (
    <div
      className={`flex items-center  px-4 cursor-pointer ${(showSearchInput || value) && setSearchOnClick ? 'w-36 border border-grey-400' : 'w-full'} gap-x-2 ${className} ${
        startSearch ? 'bg-white' : 'bg-body-variant'
      }`}
    >
      <SearchIcon onClick={() => setShowSearchInput(!showSearchInput)} />
      {(showSearchInput || value || !setSearchOnClick) && (
        <input
          type="search"
          placeholder={placeholder ?? 'Search'}
          autoFocus
          onChange={(e) => handleQueryChange(e.target.value)}
          className={`text-base border-0 outline-none focus:border-0 ring-0 w-full ${inputClassName} ${
            startSearch ? 'bg-white' : 'bg-body-variant'
          }`}
          defaultValue={value ?? ''}
          autoComplete="off"
        />
      )}
    </div>
  );
};

export default SearchInput;
