import { getClassNames, organizationsList, renderNameIcon } from '@/utils/helpers';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as CloseIcon } from '@icons/cancel.svg';
import { Fragment, useEffect, useState } from 'react';
import { ORGANIZATION_TYPES } from '@/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveOrganization,
  setActiveOrganization,
} from '@/redux/features/organization';
import { useNavigate } from 'react-router-dom';

export interface organizationType {
  id: string;
  name: string;
  organization_type: string;
  image: string | null;
}

const SwitchOrganization = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: (val: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeOrganization = useSelector(getActiveOrganization);
  const [finalOrganizationList, setFinalOrganizationList] = useState<organizationType[]>(
    []
  );

  useEffect(() => {
    setFinalOrganizationList(organizationsList);
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        onClose={() => {
          closeModal(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed top-20 left-0 right-0 ">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full max-w-[600px] transform rounded-2xl bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all `
                )}
              >
                <div className="p-[32px]">
                  <Dialog.Title
                    as="div"
                    className={getClassNames(
                      'flex justify-between items-center text-base1 leading-xl',
                      'bg-white rounded-t-2xl mb-4'
                    )}
                  >
                    <h2 className="text-2xl text-body-black font-medium">
                      Switch organisation view
                    </h2>
                    <CloseIcon
                      onClick={() => closeModal(false)}
                      className="cursor-pointer"
                    />
                  </Dialog.Title>
                  <p className="text-base text-grey-content-tertiary font-medium">
                    Manage a different organisation by switching between views.
                  </p>
                </div>
                <div className="h-full max-h-[300px] mx-10 mb-8 border border-collapse border-stroke overflow-auto p-2">
                  {finalOrganizationList?.length > 0 &&
                    finalOrganizationList?.map((organization, index) => (
                      <div
                        key={index}
                        className="flex h-16 items-center hover:bg-grey-stroke-tertiary cursor-pointer"
                        onClick={async () => {
                          if (organization?.id !== activeOrganization?.id) {
                            dispatch(setActiveOrganization(organization));
                            navigate('/');
                            closeModal(false);
                          }
                        }}
                      >
                        <div className="w-11 flex justify-center">{index + 1}</div>
                        <div className="flex justify-between w-full pl-6">
                          <div className="w-full flex justify-between">
                            <div>
                              {renderNameIcon({
                                name: organization?.name,
                                className:
                                  'bg-grey-content-quaternary text-white font-medium',
                                textClassName:
                                  'flex items-center ml-2 text-body-black font-medium text-base',
                                imageUrl: organization?.image ?? '',
                              })}
                            </div>
                            <div
                              className={getClassNames(
                                'flex items-center gap-x-1',
                                'mr-4 rounded-md border border-grey-stroke-primary px-2 py-1 h-[26px]'
                              )}
                            >
                              <div
                                className={getClassNames(
                                  'w-3 h-3 rounded-sm rounded-sm',
                                  `${organization?.organization_type === ORGANIZATION_TYPES.PARTNER ? 'bg-tblue' : 'bg-green-alert'}`
                                )}
                              />
                              <span className="font-medium text-sm text-body-black">
                                {organization?.organization_type ===
                                ORGANIZATION_TYPES.PARTNER
                                  ? 'Partner'
                                  : 'Merchant'}
                              </span>
                            </div>
                          </div>
                          <div>
                            <div
                              className={`h-6 w-6 mr-3 border border-solid rounded-full flex justify-center items-center ${activeOrganization?.id && activeOrganization?.id === organization.id ? 'bg-blacked' : 'border-grey-content-tertiary'}  `}
                            >
                              {activeOrganization?.id &&
                                activeOrganization?.id === organization.id && (
                                  <div className="h-2 w-2 border border-solid rounded-full bg-body" />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SwitchOrganization;
