import React, { useState } from "react";

// Utils
import { members } from "@utils/helpers";

// Components
import CreateUsersModal from "@pages/users/components/createUsersModal";
import CustomPopover from "@components/customPopover";
import SearchInput from "@components/searchInput";
import Topbar from "@components/topbar";
import UsersTable from "@pages/users/components/table";

// Assets
import EllipsisIcon from "@icons/Ellipsis.js";

const Team = () => {
  const [openCreateUsersModal, setOpenCreateUsersModal] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [bulkOption, setBulkOption] = useState(0);

  const handleSearch = (query) => {
    console.log(query);
  };

  return (
    <div className="w-full h-full bg-white3">
      <Topbar
        title="Internal Team"
        showBackButton
        showTitleTag
        options={
          <CustomPopover
            className="-left-6 top-2"
            placeholder={<EllipsisIcon color="#747678" className="ml-4" />}
            options={[
              {
                className: "text-base",
                name: "Edit Group",
                id: 1,
                value: true,
                onClick: () => {},
              },
              {
                className: "text-base",
                name: "Roles and Permissions",
                id: 1,
                value: true,
                onClick: () => {},
              },
              {
                name: "Delete Group",
                className:
                  "text-secondary-red hover:text-secondary-red text-base",
                id: 3,
                value: true,
                onClick: () => {},
              },
            ]}
          />
        }
        buttonConfig={
          members?.length ? { color: "dark", title: "Add Member" } : null
        }
        buttonAction={() => setOpenCreateUsersModal(true)}
      >
        <div className="ml-auto mr-4">
          <SearchInput handleChange={handleSearch} height="h-full" />
        </div>
      </Topbar>
      <CreateUsersModal
        isOpen={openCreateUsersModal}
        closeModal={setOpenCreateUsersModal}
        {...{ isBulk, setIsBulk, bulkOption, setBulkOption }}
      />
      <UsersTable
        {...{
          members: members,
          setOpenCreateUsersModal,
          setIsBulk,
        }}
      />
    </div>
  );
};

export default Team;
