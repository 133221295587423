import React from "react";

type PlusProps = {
  color?: string;
  size?: string;
  width?: string;
  height?: string;
  onClick?: () => void;
  className?: string;
};

const Plus = ({ color, size, width, height, onClick, className, ...props }: PlusProps) => {
  return (
    <svg
      width={width || size || "8"}
      height={height || size || "8"}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 3H5V0.5C5 0.224 4.776 0 4.5 0H3.5C3.224 0 3 0.224 3 0.5V3H0.5C0.224 3 0 3.224 0 3.5V4.5C0 4.776 0.224 5 0.5 5H3V7.5C3 7.776 3.224 8 3.5 8H4.5C4.776 8 5 7.776 5 7.5V5H7.5C7.776 5 8 4.776 8 4.5V3.5C8 3.224 7.776 3 7.5 3Z"
        fill={color || "#333333"}
      />
    </svg>
  );
};

export default Plus;
