import { Fragment } from "react";
import { useForm } from "react-hook-form";
// import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";

// Helpers
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import Input from "@components/input";

// Assets
import { ReactComponent as CloseIcon } from "@icons/cancel.svg";

const CreateRoleModal = ({ isOpen, closeModal }) => {

  const {
    register,
    // getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async () => {
    // const data = getValues();
    closeModal(false)
  };


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => { }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed top-20 left-0 right-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full max-w-[530px] transform overflow-hidden rounded-md bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all`
                )}
              >
                <Dialog.Title
                  as="h3"
                  className="flex justify-between items-center text-base1 leading-xl1 text-body-black bg-grey-50 py-[0.625rem] px-[1.625rem] rounded-t-md"
                >
                  <span>Create Role</span>

                  <CloseIcon
                    onClick={() => closeModal(false)}
                    className="cursor-pointer"
                  />
                </Dialog.Title>

                <form className="p-[1.875rem]">
                  <Input
                    name="name"
                    category="regular"
                    label="Role name"
                    labelStyle="text-body-black"
                    {...{ register, errors }}
                    rules={{ required: true }}
                    placeholder="Give this role a name."
                    className="flex-1"
                  />

                  <Button
                    title="Create"
                    color="dark"
                    category="1"
                    className="mt-4"
                    disabled={!isValid}
                    // loading={loading}
                    onClick={onSubmit}
                  />
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateRoleModal;
