import { getClassNames, getRandomInt } from '@/utils/helpers';
import { Listbox } from '@headlessui/react';
import { ReactComponent as CaretIcon } from '@icons/arrow_down.svg';
import { useEffect, useState } from 'react';

interface Option {
  value: string | number;
  label: string | number;
  description?: string;
}

interface ReactListboxProps {
  selectedOption?: number | string | number[] | string[];
  onChange?: (value: any) => void;
  Options: Option[];
  name?: string;
  btnClass?: string;
  optionsClass?: string;
  noSelectedMessage?: string;
  label?: string;
  disabled?: boolean;
  labelClass?: string;
  isMulti?: boolean;
}

const ReactListbox = ({
  onChange,
  selectedOption,
  Options,
  name,
  btnClass,
  optionsClass,
  label,
  disabled,
  labelClass,
}: ReactListboxProps) => {
  const [currentSelectedValue, setCurrentSelectedValue] = useState<Option | undefined>(
    Options?.find((option) => option.value == selectedOption)
  );

  useEffect(() => {
    if (name) {
      setCurrentSelectedValue(Options?.find((option) => option.value == selectedOption));
    }
  }, []);

  useEffect(() => {
    if (currentSelectedValue?.value && selectedOption !== currentSelectedValue.value) {
      if (onChange) {
        onChange(currentSelectedValue.value);
      }
    }
  }, [currentSelectedValue]);

  useEffect(() => {
    if (selectedOption && selectedOption !== currentSelectedValue?.value) {
      setCurrentSelectedValue(Options?.find((option) => option.value == selectedOption));
    }
  }, [selectedOption]);

  const handleComponentChange = (newStatus: unknown) => {
    setCurrentSelectedValue(newStatus as Option);
  };

  return (
    <Listbox
      value={currentSelectedValue}
      onChange={handleComponentChange}
      disabled={disabled}
    >
      {label ? (
        <label
          className={`text-base font-medium leading-5 -tracking-0.2  mb-3 block justify-between ${labelClass}`}
        >
          {label}
        </label>
      ) : (
        ''
      )}

      <div className="relative">
        <Listbox.Button
          className={`relative flex items-center gap-2 text-body-black font-medium rounded-full text-sm1 leading-lg -tracking-0.2 px-2 py-1 border border-solid justify-between border-grey-stroke-primary  ${btnClass ?? ''} ${disabled ? '!text-grey-content-tertiary' : 'text-grey-content-secondary'}`}
        >
          <span>
            {currentSelectedValue?.label ??
              currentSelectedValue?.label ??
              'Please select a value'}
          </span>
          {!disabled && <CaretIcon />}
        </Listbox.Button>
        <Listbox.Options
          className={` min-w-32 max-h-72 overflow-auto z-20 bg-white rounded-lg shadow-1 px-1 border border-solid border-stroke-secondary mt-2 absolute top-full left-0 ${optionsClass ?? ''}`}
        >
          {Options.map((option) => {
            return (
              <Listbox.Option
                key={`${option.value}-${getRandomInt()}`}
                className={({ active, selected }) => {
                  return `block my-2 px-2 font-medium text-base leading-5 -tracking-0.2 rounded-md cursor-pointer
                  ${active || selected || currentSelectedValue?.value === option.value ? ' bg-stroke-primary' : 'text-grey-content-secondary'}`;
                }}
                value={option}
              >
                <div key={option.value}>
                  <span className={getClassNames('paragraph1 flex py-2 pr-2 w-full')}>
                    {option.label}
                  </span>
                  {option.description && (
                    <p
                      className={getClassNames(
                        'paragraph1 w-full pb-2 pr-2 text-grey-content-tertiary'
                      )}
                    >
                      {option.description}
                    </p>
                  )}
                </div>
              </Listbox.Option>
            );
          })}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default ReactListbox;
