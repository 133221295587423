import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// Utils
import { emailPattern, getClassNames } from "@utils/helpers";

// Context
import { authContext } from "@context/auth.js";

// Components
import { Button } from "@components/button";
import EnrollSuccess from "./enrollSuccess";
import Footer from "./components/Footer";
import Input from "@components/input";
// import OtpHandler from "./otpInput";

// Assets
import { ReactComponent as PlatformLogo } from "@icons/trudenty-logo.svg";
import IncorrectInputError from "./components/IncorrectInputError";
import QRCodeContainer from "./components/QRCodeContainer";
import { axiosInstance } from "@/utils/baseAxios";

type FormData = {
  email: string;
};

const LoginWithTrudenty = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const isEnrollment = pathname === "/enrollment";
  const [step, setStep] = useState(isEnrollment ? 1 : 0);
  const { setEmail, setIsAuthenticated } = useContext(authContext);

  // const [qrcodeLink, setQrcodeLink] = useState("");
  const [qrcodeLink] = useState("");

  const steps = isEnrollment
    ? ["Register on Trudenty"]
    : ["Enter an email to proceed"];

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const login = async (email: string) => {
    axiosInstance
      .post(
        "/create_session",
        {
          email
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (!isEnrollment) {
          setIsAuthenticated(true);
          localStorage.setItem("isAuthenticated", String(true));
          localStorage.setItem("email", email);
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = async ({ email }: { email: string }) => {
    setEmail(email);
    login(email);
    // setStep(1);
  };

  return (
    <div className="flex flex-col h-full bg-white3">
      <div className={getClassNames("bg-[#22222205]")}>
        <div className="max-w-6xl  mx-auto min-h-[52px] flex items-center justify-end relative">
          <PlatformLogo className="absolute left-0" />
          {/* <Button
            title="Sign up"
            onClick={() => navigate("/signup")}
            className="lg:py-1"
          /> */}
        </div>
      </div>
      <div className="h-full overflow-scroll">
        {step < 2 ? (
          <div
            className={getClassNames(
              "h-full w-full py-16 mx-auto container-auth",
              "flex-1 flex flex-col h-full rounded items-center"
            )}
          >
            <div
              className={getClassNames(
                "w-full max-w-[832px] mx-auto flex-1 mb-10",
                isEnrollment ? "grid grid-cols-2" : "flex flex-col items-center"
              )}
            >
              <div className="max-w-[378px]">
                <h3 className="text-body-black text-xl2 leading-[120%]">
                  {steps[step]}
                </h3>
                <p className="mt-2 text-grey-content-secondary">Any email works to initialize the session</p>
                {step === 0 ? (
                  <form onSubmit={handleSubmit(onSubmit)} className="w-[378px] mt-10">
                    <div className="flex flex-col gap-y-4 w-full">
                      <Input
                        name={"email"}
                        type={"email"}
                        label={"Email"}
                        category="auth"
                        {...{ register, errors }}
                        rules={{
                          required: true,
                          minLength: 6,
                          pattern: emailPattern,
                        }}
                        labelStyle="text-desc-grey"
                        placeholder={"name@company.com"}
                      />
                    </div>

                    <IncorrectInputError
                      show={isEnrollment}
                      message="The invitation period for this email address has expired. Please contact your company administrator."
                    />

                    <Button
                      title="Login"
                      color="dark"
                      size="custom"
                      className="mt-6"
                      disabled={!isValid}
                      type="submit"
                    />
                    {/* <p className="mt-8">
                      <span className="text-grey-content-secondary">
                        Not signed up?{" "}
                      </span>{" "}
                      <span
                        onClick={() => navigate("/signup")}
                        className="cursor-pointer text-body-black underline"
                      >
                        Create an account
                      </span>
                    </p> */}
                  </form>
                ) : (
                  <></>
                  //   <OtpHandler
                  //     {...{ isEnrollment, setStep, email, setQrcodeLink }}
                  //   />
                )}
              </div>
              {step > 0 && isEnrollment && (
                <QRCodeContainer {...{ qrcodeLink }} />
              )}
            </div>
            <Footer />
          </div>
        ) : (
          <EnrollSuccess {...{ setStep }} />
        )}
      </div>
    </div>
  );
};

export default LoginWithTrudenty;
