import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
// import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
// import { toast } from "react-toastify";

// Helpers
import { getClassNames, emailPattern } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import Input, { InputContainer } from "@components/input";
import ListBox from "./listBox";

// Assets
import { ReactComponent as CloseIcon } from "@icons/close-lg.svg";
import UserInitial from "@components/UserInitial";
import { RoleTag } from "@components/tags";
// import CopyToClipboard from "react-copy-to-clipboard";

type InviteMemberModalProps = {
  showInviteModal: boolean;
  setShowInviteModal: (showInviteModal: boolean) => void;
};

const InviteMemberModal: React.FC<InviteMemberModalProps> = ({ showInviteModal, setShowInviteModal }) => {
  const {
    register,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [role, setRole] = useState({
    role: "view",
    description: "Cannot edit or configure",
  });
  const [roleByEmail, setRoleByEmail] = useState({
    role: "viewer",
    description: "Cannot edit or configure",
  });

  return (
    <Transition appear show={showInviteModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full max-w-[442px] transform rounded-2xl bg-white2 text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all px-10 py-8`
                )}
              >
                <div
                  className="flex justify-between items-center text-base1 leading-xl1 text-body-black "
                >
                  <span className="text-body-black">Invite</span>

                  <CloseIcon
                    onClick={() => setShowInviteModal(false)}
                    className="cursor-pointer w-4 h-4"
                  />
                </div>
                <p className="text-grey-content-secondary mt-2 max-w-[85%]">
                  Invite team members from your organization to join you in
                  setting up and managing this project.
                </p>
                <div className="flex items-center justify-center gap-x-2 w-full mt-9">
                  <InputContainer
                    label="Share an invite link"
                    labelStyle="text-grey-content-secondary"
                    className="bg-stroke border rounded-lg"
                    style={{ background: "#EFEFEF" }}
                  >
                    https://trudenty.com/a9611f82
                  </InputContainer>
                  {/* <CopyToClipboard
                    text="https://trudenty.com/a9611f82"
                    onCopy={() => toast("Invite link copied")}
                  >
                    <Button title="Copy" color="light" className="mt-4" />
                  </CopyToClipboard> */}
                </div>
                <div className="flex items-center gap-x-2 h-[52px] mt-1">
                  <div className="flex-1 h-full flex items-center px-4  border border-stroke bg-white4 rounded">
                    Anyone with this link can
                  </div>
                  <ListBox value={role} onChange={setRole} />
                </div>
                <hr className="my-8 -mx-10 border-stroke-primary" />
                <form className="">
                  <div className="flex items-end gap-x-2">
                    <Input
                      name="email"
                      category="regular"
                      label="Email invite"
                      {...{ register }}
                      rules={{
                        required: true,
                        pattern: emailPattern,
                      }}
                      placeholder="Email address"
                      className="flex-1 text-lg mt-2"
                    />
                    <ListBox
                      value={roleByEmail}
                      onChange={setRoleByEmail}
                      mode={2}
                    />
                  </div>
                  <div className="grid grid-cols-[1fr,96px] bg-white px-4 items-center mt-2">
                    <div className="flex gap-x-2 items-center py-4">
                      <UserInitial
                        name="Samantha Jonhs"
                        size="sm"
                        color={"tertiary"}
                      />
                      <span className="text-body-black">Samantha Jonhs</span>
                    </div>
                    <div className="flex items-center pl-6 border-l h-full border-l-grey-10">
                      <RoleTag role="Admin" />
                    </div>
                  </div>
                  <Button
                    title="Send"
                    color="dark3"
                    category="1"
                    className="mt-12 py-3 w-full"
                    disabled={!isValid}
                    // loading={loading}
                    onClick={() => {
                      reset();
                      setShowInviteModal(false);
                    }}
                  />
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default InviteMemberModal;
