type Props = {
  color?: string,
  width?: number,
  height?: number,
  className?: string,
};

const Caret = ({ color, width, height, className, ...props }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 11}
    height={height || 8}
    fill="none"
    className={className}
    {...props}
  >
    <path
      fill={color || "#24272A"}
      fillRule="evenodd"
      d="M1.025 0h8.182c.842 0 1.322.961.818 1.636L5.934 7.091a1.023 1.023 0 0 1-1.637 0L.207 1.636A1.023 1.023 0 0 1 1.025 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Caret;
