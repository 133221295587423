import { generateFirstLetter } from '@/utils/helpers';
import './NameBadge.style.css';

type Props = {
  parentClass?: string;
  FirstName?: string;
  LastName?: string;
};

const NameBadge = (props: Props) => {
  const { parentClass, FirstName, LastName } = props;
  return (
    <div className={`name-badge ${parentClass}`}>
      <span>
        {generateFirstLetter(FirstName)}
        {generateFirstLetter(LastName ? LastName?.slice(0) : '')}
      </span>
    </div>
  );
};

export default NameBadge;
