import { useState } from 'react';
import { Popover, Transition } from '@headlessui/react';

import { getClassNames } from '@utils/helpers';

interface CustomPopoverProps {
  placeholder: JSX.Element;
  options?: {
    id?: string;
    name: string;
    icon?: JSX.Element;
    value: any;
    onClick?: (value: any) => void;
    className?: string;
    render?: JSX.Element;
  }[];
  handleChange?: (value: any) => void;
  children?: JSX.Element;
  width?: string;
  active?: any;
  activeMode?: boolean;
  className?: string;
  overlayClassName?: string;
}

const CustomPopover = ({
  placeholder,
  options,
  handleChange,
  children,
  width,
  active,
  activeMode = false,
  className = '',
  overlayClassName,
}: CustomPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (value: any, onClick: ((value: any) => void) | undefined) => {
    if (onClick) {
      onClick(value);
      setIsOpen(false);
    } else {
      if (handleChange) {
        handleChange(value);
      }
      setIsOpen(false);
    }
  };
  return (
    <>
      <div className="" role="button" onClick={() => setIsOpen(!isOpen)}>
        {placeholder}
      </div>
      <Popover className="relative z-50">
        {isOpen && (
          <div
            className={getClassNames('fixed inset-0', overlayClassName)}
            onClick={() => setIsOpen(!isOpen)}
          />
        )}
        {/* <Popover.Button className="border-0 outline-none ring-0 focus:ring-0">{placeholder}</Popover.Button> */}
        <Transition
          show={isOpen}
          enter="transition duration-200 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-100 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel className={`absolute z-[501] ${className}`}>
            <div
              className="z-[501] mt-1 rounded-md border border-stroke shadow-lg p-1 bg-white/[0.97]"
              style={{ width: width ? width : '172px' }}
            >
              {options?.map(({ id, name, icon, value, onClick, className, render }) =>
                render ? (
                  <div
                    key={id || name}
                    role="button"
                    onClick={() => handleClick(value, onClick)}
                    className={getClassNames(
                      'flex items-center gap-x-2 w-full py-2 px-1 hover:bg-grey rounded'
                    )}
                  >
                    {render}
                  </div>
                ) : (
                  <div
                    key={name}
                    role="button"
                    onClick={() => handleClick(value, onClick)}
                    className={getClassNames(
                      'flex items-center gap-x-2 w-full py-2 px-1 hover:bg-grey rounded'
                    )}
                  >
                    <span
                      style={{
                        opacity: activeMode && value !== active ? '0.5' : '1',
                      }}
                    >
                      {icon}
                    </span>
                    <span
                      style={{
                        opacity: activeMode && value !== active ? '0.5' : '1',
                      }}
                      className={getClassNames(
                        'block truncate w-full leading-5 font-medium text-grey-content-tertiary hover:text-body-black',
                        className ? className : 'text-lg'
                      )}
                    >
                      {name}
                    </span>
                  </div>
                )
              )}
              {children}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
};

export default CustomPopover;
