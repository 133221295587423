
// Utils
import { getClassNames } from "@utils/helpers";

// Components

const UserActivities = ({ setStep }) => {
  const credentials = [
    {
      activity: "OTP activated",
      date: "Jul 7, 23 at 23:02",
      success: true,
      id: 1,
    },
    {
      activity: "Authenticated on https://tracksend.co/",
      date: "Jul 7, 23 at 23:02",
      success: true,
      id: 2,
    },
    {
      activity: "Authentication failed on https://business.mypatricia.co/",
      date: "Jul 7, 23 at 23:02",
      success: false,
      id: 3,
    },
  ];
  return credentials ? (
    <div className="">
      <div className="border border-stroke bg-white rounded-md">
        <div className="grid grid-cols-[47px,4fr,2fr] bg-grey-600 py-2 leading-base1 text-sm text-grey-content-secondary">
          <span className="self-center mx-auto">#</span>
          <div className="pl-6">Activity</div>
          <div className="pl-6">Date</div>
        </div>
        {credentials.map(({ id, activity, date, success }, i) => (
          <div
            className="draggable-item group grid grid-cols-[47px,4fr,2fr] hover:bg-white2 items-center"
            key={id}
          >
            <div className="counter text-grey-content-secondary text-sm self-center w-full mx-auto flex items-center justify-center border-b border-grey-10 h-full">
              {i + 1}
            </div>
            <div
              className={getClassNames(
                "title text-body-black text-base pl-6 flex items-center gap-x-2 self-center",
                "h-full border-b border-l py-6 border-grey-10 cursor-pointer"
              )}
            >
              <div className={getClassNames("w-2 h-2 rounded-full", success ? "bg-green-alert" : "bg-red-alert")} />
              <span className="header">{activity}</span>
            </div>
            <div className="h-full border-b border-l py-6 border-grey-10 pl-6">
              <p className="text-desc-grey text-sm leading-base1 max-w-[380px] h-full flex items-center ">
                {date}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default UserActivities;
