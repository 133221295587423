import * as React from "react"
const ThemeSample = ({title, primary, secondary, border, ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={201}
    height={157}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#FEFEFE"
        stroke="#EFEFEF"
        d="M1.5 5A3.5 3.5 0 0 1 5 1.5h191a3.5 3.5 0 0 1 3.5 3.5v101.5H5a3.5 3.5 0 0 1-3.5-3.5V5Z"
      />
      <g filter="url(#b)">
        <mask id="c" fill="#fff">
          <path d="M54 2h141a4 4 0 0 1 4 4v4H54V2Z" />
        </mask>
        <path
          fill="#FDFDFD"
          fillOpacity={0.95}
          d="M54 2h141a4 4 0 0 1 4 4v4H54V2Z"
        />
        <path
          fill="#EDEDED"
          d="M54 2h145H54Zm145 8.5H54v-1h145v1ZM54 10V2v8Zm145-8v8-8Z"
          mask="url(#c)"
        />
      </g>
      <circle cx={192} cy={6} r={3} fill="#F5F5F5" />
      <rect
        width={21}
        height={4}
        x={58}
        y={4}
        fill="#9A9A9A"
        fillOpacity={0.1}
        rx={1}
      />
      <path fill={primary} d="M1 5a4 4 0 0 1 4-4h49v106H5a4 4 0 0 1-4-4V5Z" />
      <path fill={secondary} d="M1 88h53v19H1V88Z" />
      <circle cx={11} cy={98} r={4} fill="#D9D9D9" />
      <rect
        width={21}
        height={4}
        x={18}
        y={96}
        fill="#9A9A9A"
        fillOpacity={0.5}
        rx={2}
      />
      <g fill="#9A9A9A" fillOpacity={0.5}>
        <rect width={38} height={4} x={7} y={17} rx={1} />
        <rect width={30} height={4} x={7} y={25} rx={1} />
        <rect width={38} height={4} x={7} y={33} rx={1} />
        <rect width={30} height={4} x={7} y={41} rx={1} />
      </g>
      <g fill="#9A9A9A" fillOpacity={0.5}>
        <rect width={38} height={4} x={7} y={63} rx={1} />
        <rect width={30} height={4} x={7} y={71} rx={1} />
      </g>
      <rect
        width={88.5}
        height={74.5}
        x={82.25}
        y={16.25}
        fill="url(#d)"
        stroke="#EFEFEF"
        strokeWidth={0.5}
        rx={1.75}
      />
      <g fill="#9A9A9A" fillOpacity={0.05}>
        <rect width={35} height={8} x={90} y={36} rx={1} />
        <rect width={35} height={8} x={127} y={36} rx={1} />
      </g>
      <rect
        width={72}
        height={8}
        x={90}
        y={48}
        fill="#9A9A9A"
        fillOpacity={0.05}
        rx={1}
      />
      <rect
        width={72}
        height={8}
        x={90}
        y={60}
        fill="#9A9A9A"
        fillOpacity={0.05}
        rx={1}
      />
      <rect width={18} height={7} x={90} y={78} fill={primary} rx={3.5} />
      <rect
        width={17.5}
        height={6.5}
        x={111.25}
        y={78.25}
        fill="#fff"
        stroke="#EFEFEF"
        strokeWidth={0.5}
        rx={3.25}
      />
      <circle cx={126} cy={27} r={5} fill="#F5F5F5" />
    </g>
    <mask id="e" fill="#fff">
      <path d="M1 107h199v45a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4v-45Z" />
    </mask>
    <path fill="#FCFCFC" d="M1 107h199v45a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4v-45Z" />
    <path
      fill="#EFEFEF"
      d="M1 107h199H1Zm200 45a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5h2a3 3 0 0 0 3 3h191a3 3 0 0 0 3-3h2ZM5 157a5 5 0 0 1-5-5v-45h2v45a3 3 0 0 0 3 3v2Zm196-50v45a5 5 0 0 1-5 5v-2a3 3 0 0 0 3-3v-45h2Z"
      mask="url(#e)"
    />
  
    <rect width={200} height={156} x={0.5} y={0.5} stroke={border ? "#24272A": ""} rx={4.5} />
    <defs>
      <linearGradient
        id="d"
        x1={126.5}
        x2={126.5}
        y1={16}
        y2={91}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0.76} />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M1 1h199v106H1z" />
      </clipPath>
      <filter
        id="b"
        width={185}
        height={48}
        x={34}
        y={-18}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={10} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_10777_213530"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_10777_213530"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default ThemeSample
