import { useEffect, useState } from "react";
import {Link} from "react-router-dom";

// Components
import GetStarted from "./components/getStarted";
import Topbar from "@components/topbar";

// Assets
import { ReactComponent as HelpIcon } from "@icons/help_icon.svg";
import { ReactComponent as EnvelopIcon } from "@icons/envelop.svg";
import { ReactComponent as RestApiIcon } from "@icons/rest_api_icon.svg";
import FocusedState from "./components/focusedState";

const DashboardNoData = () => {
  // const dispatch = useDispatch();

  // const { shopperTrustConfig, trustedShopperConfig } = useSelector(
  //   (state: IRootState) => state.projectSlice
  // );
    // useEffect(() => {
    //   if (!shopperTrustConfig) dispatch(getShopperTrustConfig() as any);
    //   if (!trustedShopperConfig) dispatch(getTrustedShopperConfig() as any);
    // }, [dispatch, shopperTrustConfig, trustedShopperConfig]);
  const [showFocusedStated, setShowFocusedStated] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowFocusedStated(true);
    }, 5000);
  }, []);

  return (
    <div className="flex w-full flex-col h-full pb-[45px] bg-white">
      <Topbar title="Dashboard" buttonConfig={null} />
      <FocusedState {...{ showFocusedStated, setShowFocusedStated }} />
      <div className="flex-1 overflow-auto pl-8 pr-12 pt-12">
        
        <GetStarted />
        <div className="mt-4 flex-1 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-3 gap-6 lg:max-w-[1088px] ">
          <div className="rounded-lg group bg-igrey-20 hover:bg-white2 flex-1 flex max-w-[544px] min-w-[444px] pt-12 pl-16">
            <div className="bg-white/[0.91] flex-1 rounded-tl-lg pb-8">
              <Link to="/" className="ml-auto">
                <div className="flex gap-x-3 items-center pt-7 pl-7 pr-9 pb-6 border-b border-b-grey">
                  <div
                    className="rounded bg-grey-70 w-10 h-10 flex items-center justify-center
				                       focus:outline-none focus-visible:ring-0 text-xs"
                  >
                    <HelpIcon />
                  </div>
                  <h3 className="text-xl">Help center</h3>
                </div>
              </Link>
              <div className="pt-8 pl-7 flex flex-col gap-y-11">
                <div className="flex gap-x-4 items-center mt-3">
                  <RestApiIcon className="w-10 h-10" />
                  <span className="text-grey-470 text-base1 leading-5 max-w-[280px]">
                    Check out our help guides and API documentation.
                  </span>
                </div>
              </div>
              <div className="pt-8 pl-7 flex flex-col gap-y-11">
                <div className="flex gap-x-4 items-center mt-3">
                  <EnvelopIcon className="w-10 h-10" />
                  <span className="text-grey-470 text-base1 leading-5 max-w-[280px]">
                    Get in touch with questions about us or your account.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardNoData;
