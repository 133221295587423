import ComingSoon from '@/components/ComingSoon/comingSoon';

const MerchantAccuracy = () => {
  return (
    <>
      <ComingSoon
        title="Accuracy Insights"
        content="This dashboard will provide reports on the accuracy of the Consumer Trust Index in the future."
      />
    </>
  );
};

export default MerchantAccuracy;
