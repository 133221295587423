const SHOPPER_TRUST_INDEX = 'SHOPPER_TRUST_INDEX';
const CHECKOUT_AND_REFUND = 'CHECKOUT_AND_REFUND';
const RETURNS_ORCHESTRATOR = 'RETURNS_ORCHESTRATOR';
const IDENTITY_AND_AUTHENTICATION = 'IDENTITY_AND_AUTHENTICATION';
const PAYMENT_PERSONALIZATION = 'PAYMENT_PERSONALIZATION';

export type config_type = 'LOW_RISK' | 'MEDIUM_RISK' | 'HIGH_RISK';

export {
  SHOPPER_TRUST_INDEX,
  PAYMENT_PERSONALIZATION,
  CHECKOUT_AND_REFUND,
  RETURNS_ORCHESTRATOR,
  IDENTITY_AND_AUTHENTICATION,
};

export const ORGANIZATION_TYPES = Object.freeze({
  NETWORK_MASTER: 'NETWORK_MASTER',
  PARTNER: 'PARTNER',
  MERCHANT: 'MERCHANT',
});

export const ROLES = Object.freeze({
  ADMIN: 'Admin',
  EDITOR: 'Editor',
  VIEWER: 'Viewer',
});

export const roleAndPermissionData: any = {
  [ROLES.ADMIN]: {
    Organisation: [
      {
        title: 'Create Partner',
        description: 'Add a new partner organisation.',
        hasPermission: false,
      },
      {
        title: 'View Partner',
        description: 'Browse through a partner organisation.',
        hasPermission: true,
      },
      {
        title: 'Update Partner',
        description: 'Make changes to a partner organisation.',
        hasPermission: true,
      },
      {
        title: 'Delete Partner',
        description: 'Permanently remove a partner organisation.',
        hasPermission: true,
      },
      {
        title: 'Create Merchant',
        description: 'Setup a new merchant organisation.',
        hasPermission: 'true',
      },
      {
        title: 'View Merchant',
        description: 'Browse through a merchant organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Update Merchant',
        description: 'Make changes to a merchant organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Merchant',
        description: 'Permanently remove a merchant organisation.',
        hasPermission: 'true',
      },
    ],
    users: [
      {
        title: 'Create Team',
        description: 'Create a new team.',
        hasPermission: 'true',
      },
      {
        title: 'View Team',
        description: 'Browse through a team.',
        hasPermission: 'true',
      },
      {
        title: 'Update Team',
        description: 'Make changes to a team.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Team',
        description: 'Permanently delete a team.',
        hasPermission: 'true',
      },
      {
        title: 'Create User',
        description: 'Create a new user.',
        hasPermission: 'true',
      },
      {
        title: 'View User',
        description: 'Browse through a user profile.',
        hasPermission: 'true',
      },
      {
        title: 'Update User',
        description: 'Make changes to a user.',
        hasPermission: 'true',
      },
      {
        title: 'Delete User',
        description: 'Permanently remove a user.',
        hasPermission: 'true',
      },
    ],
    projects: [
      {
        title: 'Create Project',
        description: 'Create a new project.',
        hasPermission: 'true',
      },
      {
        title: 'View Project',
        description: 'Browse through a project.',
        hasPermission: 'true',
      },
      {
        title: 'Update Project',
        description: 'Make changes to a project’s configuration.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Project',
        description: 'Permanently delete a project.',
        hasPermission: 'true',
      },
    ],
    dashboard: [
      {
        title: 'View projects',
        description: 'Browse through projects.',
        hasPermission: 'true',
      },
      {
        title: 'View list of consumers',
        description: 'View consumers and their profiles on product tables.',
        hasPermission: 'true',
      },
    ],
    roles: [
      {
        title: 'Create Role',
        description: 'Create a new roles.',
        hasPermission: false,
      },
      {
        title: 'Update Role',
        description: 'Make changes to roles and permissions.',
        hasPermission: false,
      },
      {
        title: 'View Role',
        description: 'Browse through roles and permissions.',
        hasPermission: true,
      },

      {
        title: 'Delete Role',
        description: 'Permanently delete a role.',
        hasPermission: false,
      },
    ],
  },
  [ROLES.EDITOR]: {
    Organisation: [
      {
        title: 'Create Partner',
        description: 'Add a new partner organisation.',
        hasPermission: false,
      },
      {
        title: 'View Partner',
        description: 'Browse through a partner organisation.',
        hasPermission: true,
      },
      {
        title: 'Update Partner',
        description: 'Make changes to a partner organisation.',
        hasPermission: true,
      },
      {
        title: 'Delete Partner',
        description: 'Permanently remove a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Create Merchant',
        description: 'Setup a new merchant organisation.',
        hasPermission: false,
      },
      {
        title: 'View Merchant',
        description: 'Browse through a merchant organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Update Merchant',
        description: 'Make changes to a merchant organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Merchant',
        description: 'Permanently remove a merchant organisation.',
        hasPermission: false,
      },
    ],
    users: [
      {
        title: 'Create Team',
        description: 'Create a new team.',
        hasPermission: 'true',
      },
      {
        title: 'View Team',
        description: 'Browse through a team.',
        hasPermission: 'true',
      },
      {
        title: 'Update Team',
        description: 'Make changes to a team.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Team',
        description: 'Permanently delete a team.',
        hasPermission: 'true',
      },
      {
        title: 'Create User',
        description: 'Create a new user.',
        hasPermission: 'true',
      },
      {
        title: 'View User',
        description: 'Browse through a user profile.',
        hasPermission: 'true',
      },
      {
        title: 'Update User',
        description: 'Make changes to a user.',
        hasPermission: 'true',
      },
      {
        title: 'Delete User',
        description: 'Permanently remove a user.',
        hasPermission: 'true',
      },
    ],
    projects: [
      {
        title: 'Create Project',
        description: 'Create a new project.',
        hasPermission: 'true',
      },
      {
        title: 'View Project',
        description: 'Browse through a project.',
        hasPermission: 'true',
      },
      {
        title: 'Update Project',
        description: 'Make changes to a project’s configuration.',
        hasPermission: 'true',
      },
      {
        title: 'Delete Project',
        description: 'Permanently delete a project.',
        hasPermission: 'true',
      },
    ],
    dashboard: [
      {
        title: 'View projects',
        description: 'Browse through projects.',
        hasPermission: 'true',
      },
      {
        title: 'View list of consumers',
        description: 'View consumers and their profiles on product tables.',
        hasPermission: 'true',
      },
    ],
    roles: [
      {
        title: 'Create Role',
        description: 'Create a new roles.',
        hasPermission: false,
      },
      {
        title: 'Update Role',
        description: 'Make changes to roles and permissions.',
        hasPermission: false,
      },
      {
        title: 'View Role',
        description: 'Browse through roles and permissions.',
        hasPermission: true,
      },
      {
        title: 'Delete Role',
        description: 'Permanently delete a role.',
        hasPermission: false,
      },
    ],
  },
  [ROLES.VIEWER]: {
    Organisation: [
      {
        title: 'Create Partner',
        description: 'Add a new partner organisation.',
        hasPermission: false,
      },
      {
        title: 'View Partner',
        description: 'Browse through a partner organisation.',
        hasPermission: true,
      },
      {
        title: 'Update Partner',
        description: 'Make changes to a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Delete Partner',
        description: 'Permanently remove a partner organisation.',
        hasPermission: false,
      },
      {
        title: 'Create Merchant',
        description: 'Setup a new merchant organisation.',
        hasPermission: false,
      },
      {
        title: 'View Merchant',
        description: 'Browse through a merchant organisation.',
        hasPermission: 'true',
      },
      {
        title: 'Update Merchant',
        description: 'Make changes to a merchant organisation.',
        hasPermission: false,
      },
      {
        title: 'Delete Merchant',
        description: 'Permanently remove a merchant organisation.',
        hasPermission: false,
      },
    ],
    users: [
      {
        title: 'Create Team',
        description: 'Create a new team.',
        hasPermission: false,
      },
      {
        title: 'View Team',
        description: 'Browse through a team.',
        hasPermission: 'true',
      },
      {
        title: 'Update Team',
        description: 'Make changes to a team.',
        hasPermission: false,
      },
      {
        title: 'Delete Team',
        description: 'Permanently delete a team.',
        hasPermission: false,
      },
      {
        title: 'Create User',
        description: 'Create a new user.',
        hasPermission: false,
      },
      {
        title: 'View User',
        description: 'Browse through a user profile.',
        hasPermission: 'true',
      },
      {
        title: 'Update User',
        description: 'Make changes to a user.',
        hasPermission: false,
      },
      {
        title: 'Delete User',
        description: 'Permanently remove a user.',
        hasPermission: false,
      },
    ],
    projects: [
      {
        title: 'Create Project',
        description: 'Create a new project.',
        hasPermission: false,
      },
      {
        title: 'View Project',
        description: 'Browse through a project.',
        hasPermission: 'true',
      },
      {
        title: 'Update Project',
        description: 'Make changes to a project’s configuration.',
        hasPermission: false,
      },
      {
        title: 'Delete Project',
        description: 'Permanently delete a project.',
        hasPermission: false,
      },
    ],
    dashboard: [
      {
        title: 'View projects',
        description: 'Browse through projects.',
        hasPermission: 'true',
      },
      {
        title: 'View list of consumers',
        description: 'View consumers and their profiles on product tables.',
        hasPermission: 'true',
      },
    ],
    roles: [
      {
        title: 'Create Role',
        description: 'Create a new roles.',
        hasPermission: false,
      },
      {
        title: 'View Role',
        description: 'Browse through roles and permissions.',
        hasPermission: true,
      },
      {
        title: 'Update Role',
        description: 'Make changes to roles and permissions.',
        hasPermission: false,
      },
      {
        title: 'Delete Role',
        description: 'Permanently delete a role.',
        hasPermission: false,
      },
    ],
  },
};

export const tabOptions = ['Organisation', 'Users', 'Projects', 'Dashboard', 'Roles'];

export const ShopperConfigSequence = [
  'IDENTITY',
  'MERCHANT_DATA',
  'NETWORK_INTELLIGENCE',
  'BANKING_HISTORY',
  'REAL_WORLD_DATA',
];
