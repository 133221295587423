import CaretSm from '@/assets/icons/CaretSm';
import { Button } from '@/components/button';
import SparklineChart from '@/components/charts';

interface SeriesProps {
  date: string;
  value: number;
}

interface NetworkSummaryProps {
  merchantCount: number;
  projectCount: number;
  totalConsumerCount: number;
  totalActiveConsumerCount: number;
  merchantSeries: SeriesProps[];
  customerSeries: SeriesProps[];
  activeConsumerSeries: SeriesProps[];
  projectSeries: SeriesProps[];
  merchantPercentage: number;
  projectPercentage: number;
  consumerPercentage: number;
  activeConsumerPercentage: number;
}

type SummaryHeaderProps = {
  heading: string;
  summaryData: NetworkSummaryProps;
  summaryFilter: number;
  setSummaryFilter?: (value: number) => void;
};

const PartnerSummaryHeader = ({ heading = '', summaryData }: SummaryHeaderProps) => {
  const chartColor = (score: number) => {
    return score >= 0 ? '#02bd8e' : '#e5374c';
  };
  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-2">
        <p className="text-grey-content-secondary text-base leading-5 font-medium">
          {heading}
        </p>
        <Button
          title="Last 30 Days"
          className="pt-[2px] -mt-1 pb-[2px] px-[6px] text-sm1 text-grey-content-tertiary"
          suffixIcon={<CaretSm color="#e5374c" className="ml-1 rotate-180" />}
        />
      </div>
      <div className={`grid grid-cols-3 gap-2`}>
        <div className="relative flex justify-between items-center p-4 rounded-lg border border-solid border-grey-stroke-primary">
          <div className="relative">
            <p className="text-base font-medium leading-5 text-grey-content-secondary mb-1">
              {heading === 'Network overview' ? 'Merchants' : 'Total consumer profiles'}
            </p>
            <h3 className="text-xl2 font-medium leading-2xl2 text-black mb-1">
              {heading === 'Network overview' ? 1 : 0}
            </h3>
            <span
              className={`font-medium text-sm leading-sm ${heading === 'Network overview' ? 'text-score-green' : 'text-risk-red'} `}
            >
              {heading === 'Network overview' ? '0%' : '100%'}
            </span>
          </div>
          <div className="relative">
            <SparklineChart
              color={heading === 'Network overview' ? '#02bd8e' : '#e5374c'}
              series={[0, 0]}
            />
          </div>
        </div>
        <div className="relative flex justify-between items-center p-4 rounded-lg border border-solid border-grey-stroke-primary">
          <div className="relative">
            <p className="text-base font-medium leading-5 text-grey-content-secondary mb-1">
              Active consumer profiles
            </p>
            <h3 className="text-xl2 font-medium leading-2xl2 text-black mb-1">
              {heading === 'Network overview' ? 7 : 0}
            </h3>
            <span
              className={`font-medium text-sm leading-sm ${heading === 'Network overview' ? 'text-score-green' : 'text-risk-red'}`}
            >
              {' '}
              {heading === 'Network overview' ? '8%' : '100%'}
            </span>
          </div>
          <div className="relative">
            <SparklineChart
              color={heading === 'Network overview' ? '#02bd8e' : '#e5374c'}
              series={[0, 0]}
            />
          </div>
        </div>
        <div className="relative flex justify-between items-center p-4 rounded-lg border border-solid border-grey-stroke-primary">
          <div className="relative">
            <p className="text-base font-medium leading-5 text-grey-content-secondary mb-1">
              Projects
            </p>
            <h3 className="text-xl2 font-medium leading-2xl2 text-black mb-1">
              {heading === 'Network overview' ? 3 : 0}
            </h3>
            <span className={`font-medium text-sm leading-sm text-score-green`}>0%</span>
          </div>
          <div className="relative">
            <SparklineChart
              color={chartColor(summaryData.projectPercentage)}
              series={[0, 0]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerSummaryHeader;
