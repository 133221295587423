import React from "react";

const GroupInitial = () => {
  return (
    <div className="rounded bg-grey-content-tertiary w-7 h-7 flex text-lg items-center text-white2 justify-center">
      I
    </div>
  );
};

export default GroupInitial;
