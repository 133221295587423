import ComingSoon from '@/components/ComingSoon/comingSoon';

const MerchantPerformance = () => {
  return (
    <>
      <ComingSoon
        title="Performance insights"
        content="This dashboard will provide reports on the performance of the Consumer Trust
          Index in the future."
      />
    </>
  );
};

export default MerchantPerformance;
