import React from "react";

const Ellipsis = ({ color, ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="4"
      fill="none"
      viewBox="0 0 15 4"
    >
      <path
        fill={color || "#E9E9E9"}
        d="M1.858 3.765c1.026 0 1.857-.843 1.857-1.883S2.884 0 1.858 0 0 .843 0 1.882c0 1.04.832 1.883 1.858 1.883zM7.43 3.765c1.026 0 1.858-.843 1.858-1.883S8.456 0 7.43 0C6.404 0 5.573.843 5.573 1.882c0 1.04.831 1.883 1.857 1.883zM13.003 3.765c1.026 0 1.858-.843 1.858-1.883S14.029 0 13.003 0c-1.026 0-1.857.843-1.857 1.882 0 1.04.831 1.883 1.857 1.883z"
      ></path>
    </svg>
  );
};

export default Ellipsis;
