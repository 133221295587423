import React, { Fragment } from 'react';
import { Transition, Listbox } from '@headlessui/react';

// Helpers
import { getClassNames } from '@utils/helpers';

// Assets
import { ReactComponent as CaretIcon } from '@icons/arrow_down.svg';

const MandatoryDropDown = ({ value, onChange }) => {
  const options = ['Mandatory', 'Optional'];

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => {
        return (
          <div className="items-center border border-stroke rounded">
            <Listbox.Button
              className={getClassNames(
                'flex items-center gap-x-2 justify-between',
                'bg-white4 border-0 px-2 py-1 text-left cursor-pointer',
                'focus:outline-none focus:ring-0 focus:border-0 sm:text-sm'
              )}
            >
              <span className="text-body-black text-sm1 leading-5 font-medium capitalize">
                {value}
              </span>

              <span className="pointer-events-none">
                <CaretIcon />
              </span>
            </Listbox.Button>
            <div className="relative" id="positionEl" />

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={getClassNames(
                  'absolute w-max bg-white z-[100] -mt-1 px-1',
                  'shadow-[0px_6px_10px_2px_rgba(218,218,219,0.5)] rounded-[5px] py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'
                )}
              >
                {options?.map((option) => (
                  <Listbox.Option
                    key={option}
                    className={({ active }) =>
                      getClassNames(
                        value === option ? 'text-white bg-grey' : 'text-gray-900',
                        'cursor-pointer select-none relative py-0.5'
                      )
                    }
                    value={option}
                  >
                    <div
                      className={getClassNames(
                        'flex flex-col items-center w-full py-0.5 px-2 hover:bg-grey rounded-b'
                      )}
                    >
                      <span
                        className={getClassNames(
                          'block truncate w-full text-sm1 leading-5 font-medium text-body-black capitalize'
                        )}
                      >
                        {option}
                      </span>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        );
      }}
    </Listbox>
  );
};

export default MandatoryDropDown;
