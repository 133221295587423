import React, { useState } from "react";
import { Tab } from "@headlessui/react";

// Utils
import { getClassNames } from "@utils/helpers";
// import { members } from "@utils/helpers";

// Components
import CreateUsersModal from "./components/createUsersModal";
import LDAPModal from "./components/ldap/LDAPModal";
import SearchInput from "@components/searchInput";
import Topbar from "@components/topbar";
import UsersTable from "./components/table";




const Users = () => {
  const [openCreateUsersModal, setOpenCreateUsersModal] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [bulkOption, setBulkOption] = useState(0);

  // members = [];

  const closeModal = () => {
    setOpenCreateUsersModal(false);
    setIsBulk(false);
    setBulkOption(0);
  };

  const tabOptions = ["All", "Internal Team", "Human Resources"];

  return (
    <div className="h-full w-full flex flex-col overflow-hidden">
      <Topbar
        title="Users"
        buttonConfig={
          members.length ? { color: "dark", title: "Add User" } : null
        }
        buttonAction={() => setOpenCreateUsersModal(true)}
      >
        {members.length ? (
          <div className="ml-auto mr-4">
            <SearchInput handleChange={() => {}} height="h-full" />
          </div>
        ) : null}
      </Topbar>
      <div className="flex-1 h-screen w-full bg-white3 overflow-y-scroll">
        <CreateUsersModal
          isOpen={openCreateUsersModal}
          closeModal={setOpenCreateUsersModal}
          {...{ isBulk, setIsBulk, bulkOption, setBulkOption }}
        />
        <LDAPModal isOpen={isBulk && bulkOption === 1} {...{ closeModal }} />
        <Tab.Group>
          <Tab.List className="px-8 pt-6 pb-4">
            <Tab disabled>
                <div
                  className={getClassNames(
                    "px-4 py-2 border-r border-grey-stroke-primary mr-2 text-trud-black",
                  )}
                >
                  Groups
                </div>
            </Tab>
            {tabOptions?.map((tabTitle) => (
              <Tab>
                {({ selected }) => (
                  <div
                    className={getClassNames(
                      "px-4 py-2 border border-grey-stroke-primary rounded mr-2",
                      selected
                        ? "text-trud-black bg-grey-stroke-primary"
                        : "text-grey-content-secondary"
                    )}
                  >
                    {tabTitle}
                  </div>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
            </Tab.Panel>
            <Tab.Panel>
              <UsersTable
                {...{
                  members,
                  setOpenCreateUsersModal,
                  setIsBulk,
                  org: "organization"
                }}
              />
            </Tab.Panel>
            <Tab.Panel>
              <UsersTable
                {...{
                  members: members.slice(2, 4),
                  setOpenCreateUsersModal,
                  setIsBulk,
                }}
              />
            </Tab.Panel>
            <Tab.Panel>
              <UsersTable
                {...{
                  members: members.slice(6),
                  setOpenCreateUsersModal,
                  setIsBulk,
                }}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default Users;

export const members = [
  {
    id: 0,
    firstName: "Lerato",
    lastName: "Matsio",
    email: "lerato@trudenty.com",
    lastSeen: "10 secs ago",
    role: "admin",
    active: false,
  },
  {
    id: 1,
    firstName: "Marco",
    lastName: "Kome",
    email: "marco@trudenty.com",
    lastSeen: "Yesterday at 16:30",
    role: "viewer",
    active: true,
  },
  {
    id: 2,
    firstName: "Tuttuh",
    lastName: "Adegun",
    email: "tuttuh@trudenty.com",
    lastSeen: "10 secs ago",
    role: "admin",
    active: false,
  },
  {
    id: 3,
    firstName: "Ose Yaw",
    lastName: "Ababio",
    email: "ababio@trudenty.com",
    lastSeen: "Yesterday at 16:30",
    role: "viewer",
    active: true,
  },
  {
    id: 4,
    firstName: "Amy",
    lastName: "Fowler",
    email: "amy@trudenty.com",
    lastSeen: "10 secs ago",
    role: "admin",
    active: false,
  },
  {
    id: 5,
    firstName: "Brian",
    lastName: "Goldberg",
    email: "brian@trudenty.com",
    lastSeen: "3 days ago",
    role: "viewer",
    active: true,
  },
];