import React from "react";
import { getClassNames } from "@utils/helpers";

const PageNav = ({ selected, setSelected, navs }) => {

  return (
    <div className="flex flex-col gap-y-2 h-full bg-body-variant2 pt-8 pl-10 pr-6">
      {navs?.map((option) => (
        <div
          key={option.title}
          onClick={() => setSelected(option.title)}
          className={getClassNames("text-grey-content-secondary hover:bg-grey-stroke-primary hover:text-black text-base1 rounded px-4 py-2 cursor-pointer w-full", 
          selected === option.title && "bg-grey-stroke-primary")}
        >
          {option.title}
        </div>
      ))}
    </div>
  );
};

export default PageNav;
