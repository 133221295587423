import React, { useContext } from "react";

// Context
import { authContext } from "@context/auth";
import { getClassNames } from "@utils/helpers";

const colors = ["#A7A8AC", "#5A66DA", "#EFB604", "#424446", "#40A4EC"];

type UserInitialProps = {
  name: string;
  size?: "sm" | "md";
  color?: "tertiary";
};

const UserInitial: React.FC<UserInitialProps> = ({ name, size = "md", color="tertiary" }) => {
  const sizes = {
    sm: "w-7 h-7 text-base1 leading-0",
    md: "w-[38px] h-[38px] text-xl leading-5",
  };
  const theme = {
    tertiary: "bg-grey-content-tertiary",
  };
  const { randNum } = useContext(authContext);
  return (
    <span
      className={getClassNames(
        "rounded-full  flex items-center justify-center text-white2 cursor-pointer capitalize",
        sizes[size],
        theme[color]
      )}
      style={{ backgroundColor: !colors ? `${colors[randNum]}`: '' }}
    >
      {name ? name[0] : ""}
    </span>
  );
};

export default UserInitial;
