import NameBadge from '@/components/Badge/NameBadge';
import { ReactComponent as EmptyStateIcon } from '@icons/empty-users-illustration.svg';
import PartnerSummaryHeader from './partnerSummaryHeader';
import { Button } from '@/components/button';
import CaretSm from '@/assets/icons/CaretSm';
import RenderChart from '@/components/renderchart';
import { organizationsList } from '@/utils/helpers';
import { ORGANIZATION_TYPES } from '@/utils/constants';

const PartnerNetwork = ({ selectedOrganization }: { selectedOrganization: string }) => {
  return (
    <div className="relative bg-white">
      <PartnerSummaryHeader
        heading={
          selectedOrganization === 'Overview' ? 'Network overview' : 'Network Summary'
        }
        summaryData={{
          merchantCount: 0,
          projectCount: 0,
          totalConsumerCount: 0,
          totalActiveConsumerCount: 0,
          merchantSeries: [],
          customerSeries: [],
          activeConsumerSeries: [],
          projectSeries: [],
          merchantPercentage: 0,
          projectPercentage: 0,
          consumerPercentage: -100,
          activeConsumerPercentage: -100,
        }}
        summaryFilter={30}
      />
      <div className="mb-8">
        <div className="flex items-center justify-between mb-2">
          <p className="text-grey-content-secondary text-base leading-5 font-medium">
            Risk distribution across the network
          </p>
          <Button
            title="Last 30 Days"
            className="pt-[2px] -mt-1 pb-[2px] px-[6px] text-sm1 text-grey-content-tertiary"
            suffixIcon={<CaretSm color="#747678" className="ml-1 rotate-180" />}
          />
        </div>
        <div className="border border-solid border-grey-stroke-primary rounded-lg px-4">
          <div className="grid grid-cols-3 gap-5">
            <div className="relative py-4 border-r border-solid border-grey-stroke-primary">
              <div className="relative">
                <div className="flex items-center gap-1 mb-1">
                  <div className="h-3 w-3 bg-risk-green rounded-sm"></div>
                  <p className="text-base font-medium leading-5 text-grey-content-secondary">
                    Trusted Consumers
                  </p>
                </div>
                <h3 className="text-xl2 font-medium leading-2xl text-black mb-1">
                  {selectedOrganization === 'Overview' ? 1 : 0}
                </h3>
                <span
                  className={`font-medium text-sm leading-sm ${selectedOrganization === 'Overview' ? 'text-score-green' : 'text-risk-red'} `}
                >
                  {selectedOrganization === 'Overview' ? '20%' : '100%'}
                </span>
              </div>
            </div>
            <div className="relative py-4 border-r border-solid border-grey-stroke-primary">
              <div className="relative">
                <div className="flex items-center gap-1 mb-1">
                  <div className="h-3 w-3 bg-trud-orange rounded-sm"></div>
                  <p className="text-base font-medium leading-5 text-grey-content-secondary">
                    Medium Risk Consumers
                  </p>
                </div>
                <h3 className="text-xl2 font-medium leading-2xl text-black mb-1">
                  {selectedOrganization === 'Overview' ? 3 : 0}
                </h3>
                <span
                  className={`font-medium text-sm leading-sm ${selectedOrganization === 'Overview' ? 'text-score-green' : 'text-risk-red'}`}
                >
                  {selectedOrganization === 'Overview' ? '10%' : '100%'}
                </span>
              </div>
            </div>
            <div className="relative py-4 rounded-lg">
              <div className="relative">
                <div className="flex items-center gap-1 mb-1">
                  <div className="h-3 w-3 bg-risk-red rounded-sm"></div>
                  <p className="text-base font-medium leading-5 text-grey-content-secondary">
                    High Risk Consumers
                  </p>
                </div>
                <h3 className="text-xl2 font-medium leading-2xl2 text-black mb-1">
                  {' '}
                  {selectedOrganization === 'Overview' ? 3 : 0}
                </h3>
                <span
                  className={`font-medium text-sm leading-sm ${selectedOrganization === 'Overview' ? 'text-risk-red' : 'text-risk-red'}`}
                >
                  {selectedOrganization === 'Overview' ? '5%' : '100%'}
                </span>
              </div>
            </div>
          </div>
          <div className="relative w-full text-center my-5">
            <RenderChart
              series={[
                {
                  name: 'Trusted Consumers',
                  data: Array(30)
                    .fill('-')
                    .map((item) => 0),
                },
                {
                  name: 'Medium Risk Consumers',
                  data: Array(30)
                    .fill('-')
                    .map((item) => 0),
                },
                {
                  name: 'High Risk Consumers',
                  data: Array(30)
                    .fill('-')
                    .map((item) => 0),
                },
              ]}
              height={500}
              chartOptions={{
                colors: ['#0CCFA0', '#EFB604', '#E32D2D'],
                xaxis: {
                  type: 'category',
                  categories: [
                    'Aug 19',
                    'Aug 20',
                    'Aug 21',
                    'Aug 22',
                    'Aug 23',
                    'Aug 24',
                    'Aug 25',
                    'Aug 26',
                    'Aug 27',
                    'Aug 28',
                    'Aug 29',
                    'Aug 30',
                    'Aug 31',
                    'Sep 1',
                    'Sep 2',
                    'Sep 3',
                    'Sep 4',
                    'Sep 5',
                    'Sep 6',
                    'Sep 7',
                    'Sep 8',
                    'Sep 9',
                    'Sep 10',
                    'Sep 11',
                    'Sep 12',
                    'Sep 13',
                    'Sep 14',
                    'Sep 15',
                    'Sep 16',
                    'Sep 17',
                  ],
                },
                tooltip: {
                  y: {
                    formatter: function (value: any) {
                      return parseInt(value).toString();
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div className="flex items-center justify-between mb-2">
          <p className="text-grey-content-secondary text-base leading-5 font-medium">
            Organisations in the network
          </p>
          <Button
            title="Last 30 Days"
            className="pt-[2px] -mt-1 pb-[2px] px-[6px] text-sm1 text-grey-content-tertiary"
            suffixIcon={<CaretSm color="#747678" className="ml-1 rotate-180" />}
          />
        </div>
        <div className="w-full border border-solid border-grey-stroke-primary rounded-lg">
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-grey-content-tertiary text-sm font-medium leading-4 text-left py-2 px-6 border border-solid border-grey-stroke-tertiary">
                  #
                </th>
                <th className="text-grey-content-tertiary text-sm font-medium leading-4 text-left py-2 px-6 border border-solid border-grey-stroke-tertiary">
                  Name
                </th>
                <th className="text-grey-content-tertiary text-sm font-medium leading-4 text-left py-2 px-6 border border-solid border-grey-stroke-tertiary">
                  Active consumer profiles
                </th>
                <th className="text-grey-content-tertiary text-sm font-medium leading-4 text-left py-2 px-6 border border-solid border-grey-stroke-tertiary">
                  API calls
                </th>
                <th className="text-grey-content-tertiary text-sm font-medium leading-4 text-left py-2 px-6 border border-solid border-grey-stroke-tertiary">
                  Webhook events
                </th>
                <th className="text-grey-content-tertiary text-sm font-medium leading-4 text-left py-2 px-6 border border-solid border-grey-stroke-tertiary">
                  Projects
                </th>
                <th className="text-grey-content-tertiary text-sm font-medium leading-4 text-left py-2 px-6 border border-solid border-grey-stroke-tertiary"></th>
              </tr>
            </thead>
            <tbody>
              {organizationsList.length ? (
                organizationsList
                  ?.filter(
                    (organization) =>
                      organization.organization_type === ORGANIZATION_TYPES.PARTNER
                  )
                  .map((organization, index) => (
                    <tr className="bg-white2" key={organization?.id}>
                      <td className="text-grey-content-secondary text-base font-medium leading-5 py-5 px-6 border border-solid border-grey-stroke-tertiary">
                        {index + 1}
                      </td>
                      <td className="text-body-black text-base font-medium leading-5 py-5 px-6 border border-solid border-grey-stroke-tertiary">
                        <div className="flex items-center gap-2.5">
                          <NameBadge
                            FirstName={organization?.name}
                            parentClass="bg-grey-content-quaternary text-white"
                          />
                          {organization?.name}
                        </div>
                      </td>
                      <td className="text-grey-content-secondary text-base font-medium leading-5 py-5 px-6 border border-solid border-grey-stroke-tertiary">
                        7 consumers
                      </td>
                      <td className="text-grey-content-secondary text-base font-medium leading-5 py-5 px-6 border border-solid border-grey-stroke-tertiary">
                        1023 requests
                      </td>
                      <td className="text-grey-content-secondary text-base font-medium leading-5 py-5 px-6 border border-solid border-grey-stroke-tertiary">
                        8420 events
                      </td>
                      <td className="text-grey-content-secondary text-base font-medium leading-5 py-5 px-6 border border-solid border-grey-stroke-tertiary">
                        3 projects
                      </td>
                      <td className="text-body-black text-base font-medium leading-5 py-5 px-6 border border-solid border-grey-stroke-tertiary"></td>
                    </tr>
                  ))
              ) : (
                <>
                  <tr className="bg-white2 text-center">
                    <td colSpan={7} className="p-5">
                      <div className="flex justify-center p-3">
                        <EmptyStateIcon />
                      </div>
                      No data found
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PartnerNetwork;
