import CaretSm from '@/assets/icons/CaretSm';
import { Button } from './button';
import SparklineChart from './charts';

interface SeriesProps {
  date: string;
  value: number;
}

interface NetworkSummaryProps {
  merchantCount: number;
  projectCount: number;
  totalConsumerCount: number;
  totalActiveConsumerCount: number;
  merchantSeries: SeriesProps[];
  customerSeries: SeriesProps[];
  activeConsumerSeries: SeriesProps[];
  projectSeries: SeriesProps[];
  merchantPercentage: number;
  projectPercentage: number;
  consumerPercentage: number;
  activeConsumerPercentage: number;
}

type SummaryHeaderProps = {
  heading: string;
  summaryData: NetworkSummaryProps;
  summaryFilter: number;
  setSummaryFilter?: (value: number) => void;
};

const MerchantSummaryHeader = ({ heading = '', summaryData }: SummaryHeaderProps) => {
  // const scorePercentage = (score: number) => {
  //   return (
  //     <span
  //       className={`text-${score >= 0 ? 'score-green' : 'red-alert'} font-medium text-sm leading-sm`}
  //     >
  //       {Math.abs(score)}%
  //     </span>
  //   );
  // };
  const chartColor = (score: number) => {
    return score >= 0 ? '#02bd8e' : '#e5374c';
  };

  const seriesValue = (data: SeriesProps[]) => {
    return data.map((value) => value.value).reverse();
  };
  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-2">
        <p className="text-grey-content-secondary text-base leading-5 font-medium">
          {heading}
        </p>
        <Button
          title="Last 30 Days"
          className="pt-[2px] -mt-1 pb-[2px] px-[6px] text-sm1 text-grey-content-tertiary"
          suffixIcon={<CaretSm color="#747678" className="ml-1 rotate-180" />}
        />
      </div>
      <div className={`grid grid-cols-3 gap-2`}>
        <div className="relative flex justify-between items-center p-4 rounded-lg border border-solid border-grey-stroke-primary">
          <div className="relative">
            <p className="text-base font-medium leading-5 text-grey-content-secondary mb-1">
              Total consumer profiles
            </p>
            <h3 className="text-xl2 font-medium leading-2xl2 text-black mb-1">
              {summaryData.totalConsumerCount}
            </h3>
            <span
              className={`font-medium text-sm leading-sm ${summaryData.consumerPercentage >= 0 ? 'text-score-green' : 'text-red-alert'}`}
            >
              {Math.abs(summaryData.consumerPercentage)}%
            </span>
          </div>
          <div className="relative">
            <SparklineChart
              color={chartColor(summaryData.consumerPercentage)}
              series={[0, 0]}
            />
          </div>
        </div>
        <div className="relative flex justify-between items-center p-4 rounded-lg border border-solid border-grey-stroke-primary">
          <div className="relative">
            <p className="text-base font-medium leading-5 text-grey-content-secondary mb-1">
              Active consumer profiles
            </p>
            <h3 className="text-xl2 font-medium leading-2xl2 text-black mb-1">
              {summaryData.totalActiveConsumerCount}
            </h3>
            <span
              className={`font-medium text-sm leading-sm ${summaryData.activeConsumerPercentage >= 0 ? 'text-score-green' : 'text-red-alert'}`}
            >
              {Math.abs(summaryData.activeConsumerPercentage)}%
            </span>
          </div>
          <div className="relative">
            <SparklineChart
              color={chartColor(summaryData.activeConsumerPercentage)}
              series={[0, 0]}
            />
          </div>
        </div>
        <div className="relative flex justify-between items-center p-4 rounded-lg border border-solid border-grey-stroke-primary">
          <div className="relative">
            <p className="text-base font-medium leading-5 text-grey-content-secondary mb-1">
              Projects
            </p>
            <h3 className="text-xl2 font-medium leading-2xl2 text-black mb-1">
              {summaryData.projectCount}
            </h3>
            <span
              className={`font-medium text-sm leading-sm ${summaryData.projectCount >= 0 ? 'text-score-green' : 'text-red-alert'}`}
            >
              {Math.abs(summaryData.projectCount)}%
            </span>
          </div>
          <div className="relative">
            <SparklineChart
              color={chartColor(summaryData.projectPercentage)}
              series={[0, 0]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantSummaryHeader;
