import React, { useState } from 'react';

// Assets
import { ReactComponent as CloseIcon } from '@icons/close-lg.svg';
import { ReactComponent as ShopperContactIcon } from '@icons/shopperContact.svg';

type ShopperContactCardProps = {
  fullname: string;
  project_identifier: string;
};

const ShopperContactCard: React.FC<ShopperContactCardProps> = ({
  fullname,
  project_identifier,
}) => {
  const [show, setShow] = useState(false);
  return (
    <div className="relative">
      <ShopperContactIcon onClick={() => setShow(true)} className="cursor-pointer" />
      {show && (
        <div className="shadow-lg z-[100] absolute top-7 -left-[250px] min-w-[347px] transition-all duration-1000 pt-6 rounded-lg bg-grey-810 border border-grey-stroke-primary overflow-hidden">
          <CloseIcon
            className="ml-auto mr-6 w-3 h-3 cursor-pointer"
            onClick={() => setShow(false)}
          />
          <div className="px-6 pb-10 rounded-lg bg-grey-810">
            <h4 className="header4">{fullname}</h4>
            <p className="max-w-[215px] mt-4 text-sm1 leading-[17px] text-grey-content-secondary">
              50 Avenue des Pr'es, Montigny-le-bretonneux 78180, Île-de-France France
            </p>
          </div>
          <div className="flex items-center justify-between px-6 py-4 bg-white2">
            <span className="text-sm1 text-grey-content-tertiary">
              Consumer Identifier
            </span>
            <div className="text-sm text-grey-content-secondary py-1 px-2 rounded bg-white">
              {project_identifier}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopperContactCard;
