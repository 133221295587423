import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Utils
import { getClassNames } from "@utils/helpers";

// Redux
import { IRootState } from "@/redux/store";
import { getProjectById } from "@redux/features/projects";

// Assets
import { ReactComponent as CloseIcon } from "@icons/close-lg.svg";
import { ReactComponent as DashboardIllustration } from "@images/dashboard-returns-and-refunds.svg";
import { Button } from "@components/button";

const GetStartedCheckoutAndRefund = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { activeProject } = useSelector((state: IRootState) => state.projectSlice);
  useEffect(() => {
    if (!activeProject) return
    // dispatch(getShopperTrustConfig(activeProject?.id) as any);
    dispatch(getProjectById(activeProject?.id) as any);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-full h-full flex flex-col">
      <div
        className={getClassNames(
          "relative container-solutions max-w-[800px] h-full md:h-fit flex flex-col items-center justify-center mt-16",
          "px-4 min-h-[776px] md:min-h-[auto]"
        )}
      >
        <div
          className="absolute rounded-full p-3 flex items-center justify-center bg-stroke cursor-pointer right-0 xl:-right-40 top-8"
          onClick={() => navigate("/")}
        >
          <CloseIcon className="w-3 h-3" />
        </div>
        <h5 className="text-center text-base1 mb-4 text-grey-content-tertiary">
          Introduction
        </h5>
        <h1 className="text-[2.875rem] leading-[100%] text-center  mt-3 mb-10 text-body-black">
          Payment <br /> Personalisation
        </h1>
        <div className="max-w-[500px] flex flex-col items-center">
          <p className="text-center text-grey-content-secondary text-base1 max-w-[490px]">
            Apply consumer intelligence to payments routings, for checkout and
            refunds, to personalise payments for enhanced customer experience
            and fraud prevention.
          </p>
          <p
            className={getClassNames(
              "text-center text-grey-content-secondary text-base1 mt-14 py-4 px-6",
              "border border-stroke-primary bg-grey-10"
            )}
          >
            This product is integrates with your existing payment stack.
          </p>
          <Button
            title="Get Started"
            color="dark"
            className="w-full max-w-[383px] mt-14 py-3"
            onClick={() => navigate("/projects/configure", { replace: true })}
          />
        </div>
      </div>
      <DashboardIllustration className="mt-14 mx-auto w-full" />
    </div>
  );
};

export default GetStartedCheckoutAndRefund;
