import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const Barchart = ({
  series,
  height,
  chartOptions,
  columnWidth,
}: {
  series: {
    name: string;
    data: any[];
  }[];
  height?: string | number;
  chartOptions?: any;
  columnWidth?: string | number;
}) => {
  const getOptions = () => ({
    fill: {
      colors: ['#EDEDED', '#EDEDED', '#EDEDED'],
    },
    hover: {
      filter: {
        type: 'none',
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'darken',
          value: 1,
        },
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      fontFamily: 'tt-commons',
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
          enabled: true,
        },
        options: { events: null },
        borderRadius: 5,
        columnWidth: columnWidth,
        // hideZeroBarsWhenGrouped: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any) {
        return Number(val).toLocaleString();
      },
      style: {
        colors: ['#E3E3E3'],
        fontWeight: 600,
      },
      background: {
        enabled: true,
        borderRadius: 8,
        foreColor: '#747678',
        borderColor: '#747678',
        borderWidth: 0.1,
        padding: 4,
      },
      offsetY: 5,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: chartOptions?.xaxis,
    yaxis: {
      opposite: true,
      labels: {
        style: {
          colors: ['transparent'],
          fontSize: '0',
        },
      },
    },
    grid: {
      show: false,
    },
  });
  const [options, setOptions] = useState(getOptions());
  useEffect(() => {
    setOptions(getOptions());
  }, [chartOptions]);
  return <Chart height={height} options={options} series={series} type="bar" />;
};

export default Barchart;
