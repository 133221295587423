import { configureStore } from '@reduxjs/toolkit';

import projectSlice from '@redux/features/projects';
import organizationSlice from '@redux/features/organization';

const store = configureStore({
  reducer: {
    projectSlice,
    organizationSlice,
  },
});

export default store;

export type IRootState = ReturnType<typeof store.getState>;
