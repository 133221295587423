import React from 'react';

// Utils
import { getClassNames } from '@utils/helpers';

// Contexts
import { DemoGuidePopoverContext } from '@/context/demoGuidePopover';

// Components
import DemoGuidePopover from '@components/DemoGuidePopover';
import ShopperContactCard from './shopperContactCard';

// Assets
import { ReactComponent as ArrowIcon } from '@icons/arrow.svg';
import { ReactComponent as CancelIcon } from '@icons/close-lg.svg';
import { ReactComponent as ScoreIcon } from '@icons/score.svg';
import { ReactComponent as SearchIcon } from '@icons/search.svg';

type ReturnsCopilotSearchbarProps = {
  startSearch: boolean;
  selectedShopper: any;
  setStartSearch: (value: boolean) => void;
  handleQueryChange: (value: string) => void;
  clearSearch: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
  query: string;
  collapse: boolean;
  setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReturnsCopilotSearchbar: React.FC<ReturnsCopilotSearchbarProps> = ({
  startSearch,
  selectedShopper,
  setStartSearch,
  handleQueryChange,
  clearSearch,
  inputRef,
  query,
  collapse,
  setCollapse,
}) => {
  const { popUp3, setPopUp3, setPopUp2, setPopUp5, setPopUp6, setPopUp4, popUp5 } =
    React.useContext(DemoGuidePopoverContext);
  return (
    <div
      className={getClassNames(
        selectedShopper ? 'border-b border-b-stroke-primary' : '',
        'flex'
      )}
    >
      <div
        className={getClassNames(
          'flex items-center z-[100]',
          collapse && !selectedShopper
            ? 'justify-center bg-white2 rounded-tl-lg  border-b border-grey-stroke-primary w-[58px] h-12'
            : 'hidden justify-between px-6 py-4'
        )}
      >
        <div className="w-7 h-7 bg-grey-170 rounded-full text-blacked text-base1 flex items-center justify-center">
          <ScoreIcon />
        </div>
        <ArrowIcon
          className={getClassNames(
            'cursor-pointer transition-all duration-300',
            collapse ? '-rotate-90 ml-1' : 'rotate-90'
          )}
          color="#B1B2B6"
          onClick={() => setCollapse((prev) => !prev)}
        />
      </div>
      <div
        className={getClassNames(
          'flex flex-1 justify-between items-center  border-b border-grey-stroke-primary py-3 h-12',
          !selectedShopper ? 'px-8' : ''
        )}
        id="target5"
      >
        {!selectedShopper && (
          <span className={getClassNames('paragraph1 text-body-black')}>Alerts</span>
        )}
        <DemoGuidePopover
          title="4. Returns Orchestrator"
          body={`A returns co-pilot giving operations teams support to fast-track 
                  refund decisions and prevent fraud.`}
          el={() => document.getElementById('target5')}
          topOffset={-170}
          leftOffset={0}
          maxWidth="max-w-[350px]"
          arrowPosition="bottom"
          open={popUp5}
          setOpen={setPopUp5}
          setPrev={setPopUp4}
          setNext={setPopUp6}
        />
        <div
          className={getClassNames(
            'relative  min-w-[197px] border flex gap-x-2 items-center justify-between p-2',

            selectedShopper
              ? 'w-full border-stroke-primary bg-white2'
              : 'w-fit border-grey-stroke-tertiary rounded bg-stroke-primary'
          )}
          id="target3"
        >
          <DemoGuidePopover
            title="2. The Trudenty Trust Network"
            el={() => document.getElementById('target3')}
            topOffset={-260}
            leftOffset={-130}
            maxWidth="max-w-[350px]"
            arrowPosition="bottom"
            open={popUp3}
            setOpen={setPopUp3}
            setPrev={setPopUp2}
            setNext={setPopUp4}
          >
            <p className="text-grey-content-tertiary mt-3">
              Search the Trudenty Trust Network to pull up the fraud profile of consumer’s
              in your network, assisting with manual decision making in the case of late
              returns and/ore suspected fraud. <br /> <br />
              Search <span className="text-white2">Samantha Jonhs</span> to view a
              detailed consumer profile.
            </p>
          </DemoGuidePopover>
          <SearchIcon className="min-w-[18px]" />
          {selectedShopper && (
            <div className="flex items-center gap-x-4 ml-4">
              <span className="whitespace-nowrap">{selectedShopper?.fullname}</span>
              <p
                className={getClassNames(
                  'text-desc-grey text-sm leading-base1 max-w-[380px] h-full',
                  'flex items-center rounded-lg w-fit py-2 px-2 whitespace-nowrap',
                  selectedShopper ? 'bg-white2' : 'bg-stroke'
                )}
              >
                {selectedShopper?.project_identifier}
              </p>
              <ShopperContactCard
                fullname={selectedShopper?.fullname}
                project_identifier={selectedShopper?.project_identifier}
              />
            </div>
          )}

          <input
            ref={inputRef}
            type="text"
            placeholder={selectedShopper ? '' : 'Search consumers by name'}
            onFocus={() => setStartSearch(true)}
            onBlur={() => setStartSearch(false)}
            disabled={!!selectedShopper}
            onChange={(e) => handleQueryChange(e.target.value)}
            className={getClassNames(
              'relative text-sm1 border-0 outline-none focus:border-0 ring-0 w-full min-w-[159px]',
              selectedShopper ? 'bg-white2' : 'bg-grey-stroke-primary'
            )}
          />

          {(query?.length > 0 || selectedShopper) && (
            <CancelIcon className="cursor-pointer min-w-[1rem]" onClick={clearSearch} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReturnsCopilotSearchbar;
