import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const UseCaseSelect = () => {
  const navigate = useNavigate()
  const params = useParams()
  const projectTypes: Record<string, string> = {
    'sti': 'SHOPPER_TRUST_INDEX',
    'crm': 'CHECKOUT_AND_REFUND_MANAGER',
    'payment_personalization': 'PAYMENT_PERSONALIZATION'
  }
  useEffect(() => {
    let { product = 'sti' } = params
    const allowedProducts = ['crm', 'payment_personalization', 'sti']
    if (!allowedProducts.includes(product)) {
      product = 'sti'
    }
    localStorage.setItem('product', projectTypes[product])
    navigate("/", { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [navigate])

  return (
    <div />
  )
}

export default UseCaseSelect