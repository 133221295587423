import React from "react";
import { useForm } from "react-hook-form";

// Helpers
import { emailPattern } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import Input from "@components/input";
import ListBox from "./listBox";

const CreateUserForm = ({ role, setRole, closeModal }) => {
  const {
    register,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  return (
    <form className="px-6 pb-6">
      <div className="flex items-center gap-x-2 mb-5">
        <Input
          label="First name"
          name="firstName"
          category="regular"
          {...{ register }}
          rules={{
            required: true,
          }}
          className="flex-1 text-lg"
        />
        <Input
          label="Last name"
          name="lastName"
          category="regular"
          {...{ register }}
          rules={{
            required: true,
          }}
          className="flex-1 text-lg"
        />
      </div>
      <div className="flex items-center gap-x-2 mb-5">
        <Input
          name="email"
          category="regular"
          label="Email"
          {...{ register }}
          rules={{
            required: true,
            pattern: emailPattern,
          }}
          placeholder="user@domain.com"
          className="flex-1 text-lg"
        />
      </div>
      <div className="" id="roleTargetContainer">
        <ListBox value={role} onChange={setRole} />
      </div>
      <Button
        title="Add User"
        color="dark"
        category="1"
        className="mt-8"
        disabled={!isValid}
        // loading={loading}
        onClick={() => {
          reset();
          closeModal(false);
        }}
      />
    </form>
  );
};

export default CreateUserForm;
