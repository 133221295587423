// Assets
// import TrustedConsumerScoreImage from '@images/Coming Soon/Trusted Consumer Score.png';

const ComingSoonToolTip = ({
  isShow,
  className = '',
  image,
  title,
  content,
  isComing = false,
  icon,
}: {
  isShow: boolean;
  className?: string;
  image?: string;
  title?: string;
  content?: string[];
  isComing?: boolean;
  icon?: React.ReactNode;
}) => {
  if (!isShow) return null;
  return (
    <div
      className={`absolute z-20 w-[470px] flex items-start p-4 border border-grey-stroke-primary rounded-md shadow-sm bg-igrey-20 ${className}`}
      style={{ boxShadow: '0px 4px 34px 0px #4040401F' }}
    >
      {icon && (
        <>
          <div>{icon}</div>
        </>
      )}
      {image && <img src={image} alt="" />}
      <div className="ml-4">
        <div className="flex items-center justify-between">
          {title && <h3 className="text-lg text-body-black font-medium">{title}</h3>}
          {isComing && (
            <span className="text-base px-[8px] py-[1px] text-white bg-grey-content-tertiary rounded">
              Coming soon
            </span>
          )}
        </div>
        {content && content?.length > 0 && (
          <div className="mt-1 space-y-4 mt-2 text-left">
            {content.map((con, index) => (
              <p key={index} className="text-lg text-grey-content-secondary">
                {con}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ComingSoonToolTip;
