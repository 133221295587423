import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "@/redux/store";

// Utils
import { getClassNames } from "@utils/helpers";

// Hooks
import { createProject, setProjectType } from "@redux/features/projects";

// CONSTANTS
import {
  SHOPPER_TRUST_INDEX,
  CHECKOUT_AND_REFUND,
} from "@utils/constants";

// Components
import { Button } from "@components/button";
import Environment from "./components/environment";
import InviteMemberModal from "../components/inviteMemberModal";
import Input from "@components/input";
import NewProjectTemplate from "./components/newProjectTemplate";
import Topbar from "@components/topbar";

// Assets
import Collaborators from "./components/collaborators";


const CreateProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: IRootState) => state.projectSlice);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const [usage, setUsage] = useState("Sandbox");
  const [option, setOption] = useState<string>();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [users, setUsers] = useState<string[]>([]);

  
  const onSubmit = async (data: object) => {
    await dispatch(
      createProject({
        ...data,
        products: option,
      }) as any
    )
      .unwrap()
      .then(() => {
        if (option === SHOPPER_TRUST_INDEX) {
          navigate("/projects/sti/get-started");
          dispatch(setProjectType(SHOPPER_TRUST_INDEX));
        } else {
          navigate("/projects/cr/get-started");
          dispatch(setProjectType(CHECKOUT_AND_REFUND));
        }
      })
      .catch(() => {});
  };

  return (
    <div className="bg-white w-full">
      <div className="container-solutions pb-40 bg-white mx-auto h-full">
        <InviteMemberModal {...{ showInviteModal, setShowInviteModal }} />
        <Topbar
          title="Create a project"
          showBackButton
          showExtras={false}
          className="border-b"
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="overflow-auto h-full max-w-[882px] px-8 mx-auto pt-[4%]"
        >
          <div className="w-full flex flex-col max-h-max h-fit gap-y-10 mb-10 text-grey-content-tertiary">
            <div>
              <input
                placeholder="Project name"
                autoFocus
                {...register("name")}
                className={getClassNames(
                  "w-full bg-white focus:outline-none focus:ring-0 text-[2rem] leading-[100%]",
                  "placeholder:text-grey-content-tertiary text-body-black"
                )}
              />
              <Input
                placeholder="Add a description"
                name="description"
                {...{ register, errors }}
                rules={{
                  required: true,
                  minLength: 6,
                }}
              />
              <p className="paragraph1 mt-2">
                Give this project a name and description to define its
                objectives.
              </p>
            </div>

            <Collaborators {...{ users, setUsers, setShowInviteModal }} />

            <Environment {...{ usage, setUsage }} />
          </div>

          <h6 className="text-grey-content-secondary header6">Add Products</h6>
          <NewProjectTemplate {...{ option, setOption }} />
          <Button
            title="Create project"
            // category=""
            color="dark"
            className={getClassNames(
              "w-full md:w-1/2 mx-auto p-3 rounded-[4rem]",
              !isValid
                ? "bg-grey-650 text-blacked"
                : "text-grey bg-btn-primary border border-primary"
            )}
            disabled={!isValid || !option}
            type="submit"
            loading={loading}
          />
        </form>
      </div>
    </div>
  );
};

export default CreateProject;
