import React from "react";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import { IRootState } from "@/redux/store";

// Utils
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";

type ActionButtonProps = {
  stage?: number;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  onContinue: () => void;
  onUndo?: () => void;
};

const ActionButtons: React.FC<ActionButtonProps> = ({
  // stage,
  // setStage,
  onContinue,
  // onUndo
}) => {
  const { loading } = useSelector((state: IRootState) => state.projectSlice);
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 1,
  });
  return (
    <>
      <div className={getClassNames(inView ? "h-6": "h-20" )} ref={ref} />
      <div
        className={getClassNames(
          "max-w-[876px] flex items-center gap-x-2 w-full py-5 bg-white",
          !inView ? "fixed bottom-0" : ""
        )}
      >
        {/* {(stage === undefined || stage !== 0) && (
          <Button
            title="Undo"
            category="1"
            color="light"
            className="w-fit"
            onClick={onUndo ? onUndo : () => {}}
          />
        )} */}
        <Button
          title="Continue"
          category="1"
          color="dark"
          className="w-fit"
          loading={loading}
          // disabled={stage === 0 && totalScores !== 99}
          onClick={() => onContinue()}
        />
      </div>
    </>
  );
};

export default ActionButtons;
