import React from "react";
import { Switch as Toggle } from "@headlessui/react";
import { getClassNames } from "@utils/helpers";

interface SwitchProps {
  checked: boolean;
  toggleCheck: () => void;
  size?: "sm" | "md";
}


const Switch = ({ checked, toggleCheck, size = "sm" }: SwitchProps) => {
  const sizes = {
    sm: "h-3 w-5",
    md: "h-[1.375rem] w-9"
  }
  const ballSizes = {
    sm: "h-2 w-2",
    md: "h-[19.5px] w-[19.5px]"
  }
  const transitions = {
    sm: "translate-x-[8px]",
    md: "translate-x-[14px]"
  }
  return (
    <Toggle
      checked={checked}
      onChange={toggleCheck}
      className={getClassNames(
        checked ? "bg-black" : "bg-blacked/[0.10] ",
        "border border-istroke relative inline-flex items-center shrink-0 cursor-pointer rounded-full",
        "transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2",
        "focus-visible:ring-white focus-visible:ring-opacity-75", sizes[size]
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={getClassNames(
          ballSizes[size],
          checked
            ? `${transitions[size]}`
            : "translate-x-0",
          "bg-white pointer-events-none inline-block  transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out"
        )}
      />
    </Toggle>
  );
};

export default Switch;
