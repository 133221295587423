import React from 'react'

const Footer = () => {
  return (
    <div className='flex gap-x-6 text-grey-content-tertiary text-sm'>
        <span className="">Privacy policy</span>
        <span className="">Terms of use</span>
        <span className="">Help & Support</span>
    </div>
  )
}

export default Footer