import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ORGANIZATION_TYPES } from '@/utils/constants';
import { getRandomInt, organizationsList, renderNameIcon } from '@/utils/helpers';

import Topbar from '@/components/topbar';
import { getActiveOrganization } from '@/redux/features/organization';
import Table from '@/components/Table/Table';
import SearchInput from './searchInput';

const OrganizationList = () => {
  const [search, setSearch] = useState<string>('');
  const activeOrganization = useSelector(getActiveOrganization);

  const [organizationList, setOrganizationList] = useState<any[]>([]);

  const columnData: any[] = [
    {
      label: '#',
      className: 'background-white w-[44px]',
      option: {
        sort: false,
        hasFilter: false,
        isIndex: true,
      },
    },
    {
      cell: (props: { name: string; image?: string }) => {
        return renderNameIcon({
          name: props?.name,
          imageUrl: props?.image ?? '',
          isCircle: true,
          className: 'bg-grey-content-quaternary text-white font-medium',
          textClassName:
            'flex items-center ml-2 text-body-black font-medium group-hover:text-blacked text-base',
        });
      },
      label: 'Name',
    },
    {
      name: 'no_of_users',
      label: 'Number of users',
      option: {
        sort: false,
        hasFilter: false,
      },
      cell: (props: any) => {
        return props?.no_of_users ? props?.no_of_users + ' users' : '-';
      },
    },
    {
      name: 'no_of_projects',
      label: 'Number of projects',
      option: {
        sort: false,
        hasFilter: false,
      },
      cell: (props: any) => {
        return props?.no_of_projects ? props?.no_of_projects + ' projects' : '-';
      },
    },
    {
      name: 'no_of_consumers',
      label: 'Size of consumer network',
      option: {
        sort: false,
        hasFilter: false,
      },
      cell: (props: any) => {
        return props?.no_of_consumers ? props?.no_of_consumers + ' consumers' : '-';
      },
    },
  ];

  const searchData = (data: string) => {
    if (data === '') {
      setOrganizationList(
        organizationsList.filter(
          (organization) => organization.organization_type === ORGANIZATION_TYPES.MERCHANT
        ) ?? []
      );
    } else {
      setOrganizationList(
        organizationsList.filter(
          (organization) =>
            organization.organization_type === ORGANIZATION_TYPES.MERCHANT &&
            organization.name.toLocaleLowerCase().includes(data.toLocaleLowerCase())
        ) ?? []
      );
    }
  };

  useEffect(() => {
    if (organizationsList) {
      searchData('');
    }
  }, []);

  return (
    <>
      {(organizationList || search) && (
        <div className="h-full w-full flex flex-col overflow-hidden" key={getRandomInt()}>
          <Topbar
            title="Organisations"
            secondButtonConfig={{ color: 'dark', title: 'Add Organisation' }}
          ></Topbar>
          <div className="flex-1 h-screen w-full bg-white3 overflow-y-auto">
            <div className="px-8">
              <div className="flex justify-between bg-white4 border border-solid border-stroke-primary border-b-0 rounded-md py-2 px-6 rounded-b-none">
                <div className=" flex gap-4 items-center text-xl text-body-black pt-1">
                  <span>
                    {activeOrganization?.organization_type ===
                    ORGANIZATION_TYPES.NETWORK_MASTER
                      ? 'Nodes'
                      : 'Merchants'}
                  </span>
                  <span className="rounded-full flex justify-center items-center w-6 h-6 bg-stroke text-sm font-medium">
                    {1}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <SearchInput
                    handleChange={(data) => {
                      searchData(data);
                      setSearch(data);
                    }}
                    className="rounded-full text-grey-content-secondary px-1 py-1 w-36"
                    value={search}
                  />
                </div>
              </div>
            </div>
            <Table
              headerData={columnData}
              bodyData={organizationList}
              bodyClass="cursor-pointer"
              totalPage={1}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default OrganizationList;
