import { createContext, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Utils
import { axiosInstance } from "@utils/baseAxios";

// Context
import { authContext } from "./auth";
export const cookieContext = createContext();

const CookieContextProvider = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(authContext);

  useEffect(() => {
    if (
      location.pathname.startsWith("/login") ||
      location.pathname.startsWith("/signup")
    ) {
      return;
    }

    axiosInstance
      .get("/check_session")
      .then(() => {
        localStorage.setItem("isAuthenticated", true);
        setIsAuthenticated(true);
      })
      .catch(() => {
        const product = localStorage.getItem("product");
        localStorage.clear();
        setIsAuthenticated(false);
        product && localStorage.setItem("product", product);
      });
  }, [location.pathname, navigate, setIsAuthenticated]);

  return (
    <cookieContext.Provider value={{}}>{props.children}</cookieContext.Provider>
  );
};

export default CookieContextProvider;
