import React, { useState } from "react";
import Switch from "@components/switch";

// Helpers
import { getClassNames } from "@utils/helpers";

// Components
import CreateRoleModal from "./components/createRoleModal";
import PageNav from "@pages/users/components/user/pageNav";
import Topbar from "@components/topbar";
import { Button } from "@components/button";

// Assets
import PlusIcon from "@icons/plus";

const RolesAndPermissions = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selected, setSelected] = useState("Admin");
  const [options, setOptions] = useState([
    {
      title: "Permissions",
      settings: [
        {
          id: 1,
          title: "Create Users",
          description:
            "Show notification when a credential model is published, updated or unpublished.",
          checked: true,
        },
        {
          id: 2,
          title: "Create Groups",
          description:
            "Show notification when a credential model is published, updated or unpublished.",
          checked: false,
        },
        {
          id: 3,
          title: "Create Roles",
          description:
            "Show notification when a credential model is published, updated or unpublished.",
          checked: true,
        },
        {
          id: 4,
          title: "Create Policies",
          description:
            "Show notification when a credential model is published, updated or unpublished.",
          checked: true,
        },
        {
          id: 5,
          title: "Create Organizations",
          description:
            "Show notification when a credential model is published, updated or unpublished.",
          checked: true,
        },
        {
          id: 6,
          title: "Read Users",
          description:
            "Show notification when a credential model is published, updated or unpublished.",
          checked: false,
        },
        {
          id: 7,
          title: "Read Groups",
          description:
            "Show notification when a credential model is published, updated or unpublished.",
          checked: true,
        },
        {
          id: 8,
          title: "Read Roles",
          description:
            "Show notification when a credential model is published, updated or unpublished.",
          checked: true,
        },
        {
          id: 9,
          title: "Read Policies",
          description:
            "Show notification when a credential model is published, updated or unpublished.",
          checked: false,
        },
      ],
    },
  ]);
  const toggleValue = (checked, i, j) => {
    const temp = [...options];
    temp[i].settings[j].checked = checked;
    setOptions(temp);
  };
  const navs = [
    {
      title: "Admin",
      to: "admin",
    },
    {
      title: "Editor",
      to: "editor",
    },
    {
      title: "Viewer",
      to: "viewer",
    },
  ];
  return (
    <div className="flex-1 flex flex-col">
      <Topbar title="Roles and Permission" showBackButton showTitleTag />
      <CreateRoleModal
        isOpen={openCreateModal}
        closeModal={setOpenCreateModal}
      />
      <div className="h-full grid grid-cols-[236px,1fr] flex-1">
        <PageNav {...{ selected, setSelected, navs }} />
        <div className="py-8 px-20 bg-white3">
          <div className="flex items-center justify-between">
            <h2 className="header4">{selected}</h2>
            <Button
              title="Create Role"
              category="2"
              color="dark"
              onClick={() => setOpenCreateModal(true)}
              prefixIcon={<PlusIcon color="#fff" />}
            />
          </div>
          <div className="mt-2 rounded-md border border-stroke py-5 flex flex-col gap-y-2">
            {options.map((option, i) => (
              <div className="" key={option.title}>
                <p className="py-2 px-8 text-desc-grey text-base leading-5 border-b border-b-grey-10">
                  {option.title}
                </p>
                <div className="flex flex-col gap-y-6 mt-6">
                  {option.settings.map(
                    ({ id, title, checked, description }, j) => (
                      <div
                        className="px-8 flex items-center justify-between"
                        key={id}
                      >
                        <div className="">
                          <h4 className="text-body-black text-base leading-5">
                            {title}
                          </h4>
                          <p className="text-grey-content-tertiary text-sm leading-base1 mt-2">
                            {description}
                          </p>
                        </div>
                        <div
                          className={getClassNames(
                            "flex py-1 px-2 items-center gap-x-2 rounded",
                            checked ? "bg-grey-stroke-primary" : "bg-grey-850"
                          )}
                        >
                          <Switch
                            checked={checked}
                            toggleCheck={(checked) =>
                              toggleValue(checked, i, j)
                            }
                          />
                          <span className="">{checked ? "ON" : "OFF"}</span>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesAndPermissions;
