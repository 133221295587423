import React from 'react';
import { Menu } from '@headlessui/react';

// Helpers
import { getClassNames } from '@utils/helpers';

// Components
import { InputContainer } from '@components/input';

// Assets
import { ReactComponent as CaretIcon } from '@icons/arrow_down.svg';

const RoleSelect = ({
  label = 'Role',
  value,
  onChange,
}: {
  label?: string;
  value: any;
  onChange: (value: any) => void;
}) => {
  const roles = [
    {
      role: 'viewer',
      description: 'Cannot edit or configure',
    },
    {
      role: 'editor',
      description: 'Can edit but cannot perform admin tasks',
    },
    {
      role: 'admin',
      description: 'Can fully configure and edit application',
    },
  ];

  return (
    <InputContainer className="relative" label={label}>
      <Menu>
        <Menu.Button
          className={getClassNames(
            'flex items-center gap-x-2 justify-between w-full h-full',
            'bg-white4 border-0  text-left'
          )}
        >
          <span className="text-body-black text-base leading-5 font-medium capitalize">
            {value?.role}
          </span>

          <span className="pointer-events-none mt-1">
            <CaretIcon />
          </span>
        </Menu.Button>

        <Menu.Items
          className={getClassNames(
            'absolute z-10 bg-white shadow-lg p-1 pt-0.5 min-w-[20rem] rounded'
          )}
        >
          {roles?.map((option) => (
            <Menu.Item
              key={option.role}
              // onClick={() => onChange(option)}
              // className={({ active }: { active: string }) =>
              //   getClassNames(
              //     active === option.role
              //       ? "text-white bg-grey"
              //       : "text-gray-900",
              //     "cursor-pointer select-none relative py-1 px-1 mt-1"
              //   )
              // }
              // value={option}
            >
              {({ active }) => (
                <div
                  onClick={() => onChange(option)}
                  className={getClassNames(
                    value.role === option.role ? 'bg-grey' : '',
                    'flex flex-col items-center w-full py-2 px-2 hover:bg-grey rounded'
                  )}
                >
                  <span
                    className={getClassNames(
                      'block truncate w-full text-base leading-5 font-medium text-body-black capitalize'
                    )}
                  >
                    {option.role}
                  </span>
                  <span className="w-full font-medium text-sm leading-base1 text-desc-grey">
                    {option.description}
                  </span>
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </InputContainer>
  );
};

export default RoleSelect;
