import React, { useContext } from 'react';

// Context
import { DemoGuidePopoverContext } from '@/context/demoGuidePopover';

// Utils
import { getClassNames } from '@utils/helpers';

// Components
import { Button } from '@components/button';
import DemoGuidePopover from '@/components/DemoGuidePopover';
import Input from '@components/input';
import ToolTip from '@components/ToolTip';

//Assets
import CaretSm from '@assets/icons/CaretSm';

type ConfigUpdaterProps = {
  optionsToReveal: any;
  attr: any;
  register: any;
  i: number;
  openedRow: number | undefined;
  setOpenedRow: React.Dispatch<React.SetStateAction<number | undefined>>;
  editableScores: any;
  stage: number;
  updateScore: (id: string, score: number, parentCategory: string) => void;
  onSave: () => void;
};

const ConfigUpdater = ({
  optionsToReveal,
  attr,
  register,
  i,
  openedRow,
  setOpenedRow,
  stage,
  updateScore,
  onSave,
}: ConfigUpdaterProps) => {
  const {
    popUp11,
    setPopUp10,
    popUp12,
    popUp13,
    setPopUp14,
    setPopUp11,
    setPopUp12,
    setPopUp13,
  } = useContext(DemoGuidePopoverContext);
  return (
    <div
      className={getClassNames('cursor-pointer', openedRow === i ? 'group' : '')}
      id={`${attr.id}`}
    >
      <div
        className={getClassNames(
          'grid grid-cols-[45px,1fr,200px,200px]  divide-x-[1px divide-grey-10'
        )}
        key={attr.name}
        onClick={() => setOpenedRow(openedRow === i ? undefined : i)}
      >
        <div className="text-sm text-grey-content-secondary text-center py-6">
          {i + 1}
        </div>
        <div className="py-6 px-4 border-l border-l-grey-10 group">
          <div className="flex items-center gap-x-2">
            <span className="text-lg text-body-black">{attr.name}</span>
            <CaretSm
              color="#A7A8AC"
              className={getClassNames(openedRow === i ? 'rotate-0' : 'rotate-180')}
            />
          </div>
          <p className="mt-2 text-grey-content-tertiary relative">
            {attr.description
              .replaceAll('shopper', 'consumer')
              .replaceAll('Shopper', 'Consumer')
              .replaceAll('shoppers', 'consumers')}
            {stage > 1 && (
              <ToolTip
                className="max-w-[283px] top-[100%] left-0"
                info={attr.info}
                risk={attr.risk}
              />
            )}
          </p>
        </div>
        <div className="flex flex-col gap-y-1 items-center justify-center py-6">
          <div
            className={getClassNames(
              'mx-auto max-w-[52px] focus:border-desc-grey cursor-pointer',
              'border border-stroke bg-white4 text-body-black',
              'text-base -tracking-[0.4px] font-medium p-3 md:p-4 leading-5 rounded '
            )}
            onClick={() => setOpenedRow(i)}
          >
            {parseInt(attr.score.toString())}
          </div>
          {/* {stage > 0 && (
            <span className={getClassNames("rounded-2xl px-2 text-sm1")}>
              {i > 0 ? "or less" : "or more"}
            </span>
          )} */}
        </div>
        <div className="flex flex-col gap-y-1 items-center justify-center py-6 bg-primary border-l border-l-grey-10">
          <Input
            disabled
            name={'aosdhjn' + i}
            {...{ register }}
            defaultValue={
              parseInt(attr.recommended ?? '0') === 0
                ? ' -'
                : parseInt(attr?.recommended ?? '0')
            }
            value={
              parseInt(attr.recommended ?? '0') === 0
                ? ' -'
                : parseInt(attr?.recommended ?? '0')
            }
            rules={{ required: true }}
            className="mx-auto max-w-[52px] bg-stroke text-grey-content-secondary"
          />
          {/* {stage > 0 && (
            <span className={getClassNames("rounded-2xl px-2  text-sm1")}>
              {i > 0 ? "or less" : "or more"}
            </span>
          )} */}
        </div>
      </div>
      <div className="grid-reveal bg-primary">
        <div className="">
          <div className="grid grid-cols-[45px,1fr,168px] text-grey-content-secondary">
            <span />
            <span>
              Customise each property’s contribution to the index category’s total
            </span>
          </div>

          <div className="flex flex-col mt-3 ml-[45px] gap-y-5 pt-3 rounded border border-stroke-secondary divide-x-[1px] divide-grey-stroke-tertiary">
            {optionsToReveal[attr.name]?.map(
              (
                {
                  name,
                  description,
                  score,
                  recommended,
                }: {
                  name: string;
                  description: string;
                  score: number;
                  recommended: number;
                },
                index: number
              ) => (
                <div className="relative grid grid-cols-[1fr,168px] pl-4">
                  <div className="">
                    <div className="flex items-center gap-x-2">
                      <div className="w-[9px] h-[9px] rounded-sm bg-stroke-secondary" />
                      <span className="text-body-black">{name}</span>
                    </div>
                    <p className="mt-2 text-grey-content-tertiary">
                      {description
                        .replaceAll('shopper', 'consumer')
                        .replaceAll('Shopper', 'Consumer')
                        .replaceAll('shoppers', 'consumers')}
                    </p>
                  </div>
                  <div className="flex items-center gap-x-14 pr-8 py-6">
                    <div className="py-">
                      <Input
                        name={name + i + index}
                        {...{ register }}
                        value={parseInt(score.toString())}
                        rules={{ required: true }}
                        onChange={(v) => {
                          let val = parseInt(v.target.value);
                          if (val < 0 || isNaN(val)) {
                            val = parseInt(v.target.value.replace(/-/g, '')) || 0;
                          }
                          if (val >= 100) {
                            val = 100;
                          }
                          updateScore(name, val, attr.name);
                        }}
                        className={getClassNames(
                          'mx-auto focus:border-desc-grey text-sm py-0 px-0 md:px-1 md:py-2 text-center'
                        )}
                      />
                    </div>
                    <Input
                      disabled
                      name={'aosjn' + i + index}
                      {...{ register }}
                      value={parseInt(recommended.toString())}
                      rules={{ required: true }}
                      className="mx-auto text-sm py-0 px-0 md:px-1 md:py-2 text-center"
                    />
                  </div>
                  {index < optionsToReveal[attr.name]?.length - 1 && (
                    <hr className="absolute inset-x-0 bottom-0 border-stroke-secondary" />
                  )}
                </div>
              )
            )}
          </div>
          <div className="ml-[45px] mt-6 flex items-center gap-x-2">
            <Button title="Cancel" category="2" onClick={() => setOpenedRow(undefined)} />
            <Button title="Save" category="2" color="dark" onClick={onSave} />
          </div>
        </div>
      </div>
      {attr.name === 'Identity' && (
        <DemoGuidePopover
          title="1. Open Banking Data"
          body="Consumer’s open banking data across their linked bank accounts"
          el={() => document.getElementById(`${attr.id}`)}
          topOffset={-50}
          leftOffset={-380}
          arrowPosition="right"
          maxWidth="max-w-[355px]"
          open={popUp12}
          setOpen={setPopUp12}
          setPrev={setPopUp11}
          setNext={setPopUp13}
        />
      )}
      {attr.name === 'Merchant data' && (
        <DemoGuidePopover
          title="2. Merchant data"
          body="Quantitative and qualitative merchant data relating to order history, refunds history and fraud history"
          el={() => document.getElementById(`${attr.id}`)}
          topOffset={-50}
          leftOffset={-380}
          arrowPosition="right"
          maxWidth="max-w-[355px]"
          open={popUp13}
          setOpen={setPopUp13}
          setPrev={setPopUp12}
          setNext={setPopUp14}
        />
      )}
      {attr.name === 'Merchant data' && (
        <DemoGuidePopover
          title="Data from multiple data sources ingested"
          body="The algorithm ingests data from 5 main kinds of data sources to calculate a 360 fraud risk profile for consumers"
          el={() => document.getElementById(`${attr.id}`)}
          topOffset={-50}
          leftOffset={-380}
          arrowPosition="right"
          maxWidth="max-w-[355px]"
          open={popUp11}
          setOpen={setPopUp11}
          setPrev={setPopUp10}
          setNext={setPopUp12}
        />
      )}
    </div>
  );
};

export default ConfigUpdater;
