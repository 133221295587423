import { useNavigate } from "react-router-dom";

// Utils
import { getClassNames, addDays } from "@utils/helpers";

// Components
import { Button } from "@components/button";

// Assets
import { ReactComponent as PlatformLogo } from "@icons/bankco.svg";

const MockBankConsentAuthorization = () => {
  const navigate = useNavigate();

  const bullets = [
    "Name",
    "Account name, number and sort code",
    "Account balances",
    "Details of your incoming transactions",
    "Details of your outgoing transactions",
  ];

  return (
    <div className="flex flex-col h-full bg-white w-full mx-auto">
      <div className={getClassNames("bg-[#22222205]")}>
        <div className="container-auth min-h-[52px] flex items-center justify-center relative">
          <PlatformLogo className="absolute left-0" />
        </div>
      </div>
      <div className="h-full overflow-scroll">
        <div
          className={getClassNames(
            "h-full w-full py-16 mx-auto container-auth",
            "flex-1 flex flex-col h-full rounded items-center"
          )}
        >
          <div
            className={getClassNames(
              "w-full max-w-[832px] mx-auto flex-1 mb-10",
              "flex flex-col items-center"
            )}
          >
            <div className="max-w-[459px] p-8 bg-white border border-stroke-primary rounded-lg">
              <div className="rounded-lg">
                <h2 className="header2 text-body-black">
                  Consent authorization
                </h2>
                <p className="my-8 text-grey-content-tertiary">
                  If approved,{" "}
                  <span className="text-grey-content-secondary">Retail Co</span>{" "}
                  will have access to the information listed until your
                  permission expires on <br /> {addDays(180)}. (180 days)
                </p>
                <div className="rounded-lg bg-white2 border border-grey-stroke-tertiary">
                  <ul className="relative flex flex-col list-none divide-y divide-grey-10">
                    {bullets.map((bullet) => (
                      <li key={bullet} className="bulleted py-4 px-4 lg:px-5   flex gap-x-2 items-center">
                        <div className="w-[9px] h-[9px] rounded-sm bg-stroke-secondary" />
                        <p className=" text-grey-content-secondary">
                          {bullet}
                        </p>

                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="flex gap-x-4 justify-between items-center">
                <Button
                  title="Decline"
                  color="light"
                  size="custom"
                  className="mt-6 flex-1 text-red-alert bg-white2"
                  onClick={() => navigate("/setup-payment/setup-declined")}
                />
                <Button
                  title="Approve"
                  color="dark"
                  size="custom"
                  className="mt-6 flex-1"
                  onClick={() => navigate("/setup-payment/setup-success")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MockBankConsentAuthorization;
