import React, { Fragment } from 'react';
import { Transition, Listbox } from '@headlessui/react';

// Helpers
import { getClassNames } from '@utils/helpers';

// Assets
import { ReactComponent as CaretIcon } from '@icons/arrow_down.svg';
import { InputContainer } from './input';

interface Option {
  value: string | number;
  label: string | number;
}

interface DropDownProps {
  label?: string;
  selected: Option | null;
  onChange: (selected: any) => void;
  options?: Option[];
  className?: string;
  position?: string;
  width?: string;
  size?: string;
  style?: React.CSSProperties;
}

const DropDown = ({
  label,
  selected,
  onChange,
  options = [],
  className = 'mt-2',
  position = 'top-10',
  width = 'min-w-[4rem]',
  size = 'py-1',
  style,
}: DropDownProps) => {
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => {
        return (
          <InputContainer
            label={label}
            labelStyle="mb-2"
            className={getClassNames('relative py-1 md:py-3 md:px-2', className)}
            style={style}
          >
            <Listbox.Button
              className={getClassNames(
                'flex items-center gap-x-2 justify-between w-full',
                'border-0 px-2 text-left cursor-pointer',
                'focus:outline-none focus:ring-0 focus:border-0 w-full'
              )}
            >
              <span className="text-body-black text-sm1 leading-5 font-medium">
                {selected?.label}
              </span>

              {options?.length > 1 && (
                <span className="pointer-events-none">
                  <CaretIcon />
                </span>
              )}
            </Listbox.Button>
            <div className="relative" id="positionEl" />

            {options?.length > 1 && (
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={getClassNames(
                    'absolute w-max bg-white z-[100] -mt-1 p-2 shadow-[0px_6px_10px_2px_rgba(218,218,219,0.5)] rounded',
                    'text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm flex flex-col gap-2',
                    'max-h-[200px]',
                    position,
                    width
                  )}
                >
                  {options?.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        getClassNames(
                          selected?.value === option.value
                            ? 'text-white bg-grey'
                            : 'text-gray-900',
                          'cursor-pointer select-none relative hover:bg-grey rounded',
                          size
                        )
                      }
                      value={option}
                    >
                      <div
                        className={getClassNames(
                          'flex flex-col items-center w-full px-2 '
                        )}
                      >
                        <span
                          className={getClassNames(
                            'block truncate w-full text-sm1 leading-5 font-medium text-body-black'
                          )}
                        >
                          {option.label}
                        </span>
                      </div>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            )}
          </InputContainer>
        );
      }}
    </Listbox>
  );
};

export default DropDown;
