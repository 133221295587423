import React from "react";

// Utils
import { getClassNames } from "@utils/helpers";

// Components
import { InputContainer } from "@components/input";
import RadioButton from "@components/RadioButton";
import ToolTip from "@components/ToolTip";

// Assets
import { ReactComponent as LockIcon } from "@icons/lock_medium.svg";

type EnvironmentProps = {
  usage: string;
  setUsage: (usage: string) => void;
};

const Environment: React.FC<EnvironmentProps> = ({ usage, setUsage }) => {
  return (
    <div className="relative w-full">
      <div className="grid grid-cols-2 items-end gap-x-2 max-w-[695px]">
        <InputContainer
          label="Environment"
          labelStyle="text-desc-grey"
          className=" flex gap-x-2 justify-between items-center cursor-pointer max-w-[334px]"
          onClick={() => setUsage("Sandbox")}
        >
          <div className="flex gap-x-2 items-center">
            <div
              className={getClassNames(
                "w-4 h-4 rounded-full",
                usage === "Sandbox"
                  ? "bg-trud-orange"
                  : "bg-grey-content-quaternary"
              )}
            />
            <span
              className={getClassNames(
                "text-base1 mt-0.5",
                usage === "Sandbox"
                  ? "text-body-black"
                  : "text-grey-content-tertiary"
              )}
            >
              Sandbox
            </span>
          </div>
          <RadioButton active={usage === "Sandbox"} />
        </InputContainer>
        <InputContainer
          label=""
          className="group flex gap-x-2 justify-between items-center max-w-[334px]"
        >
          <div className="flex gap-x-2 items-center">
            <div
              className={getClassNames(
                "w-4 h-4 rounded-full",
                usage === "Live"
                  ? "bg-green-alert"
                  : "bg-grey-content-quaternary"
              )}
            />
            <span
              className={getClassNames(
                "text-base1 mt-0.5",
                usage === "Live"
                  ? "text-body-black"
                  : "text-grey-content-tertiary"
              )}
            >
              Live
            </span>
          </div>
          <LockIcon className="h-6" />
          <ToolTip
            info="Once your project is created in the Sandbox, you’ll be able to publish to the Live environment."
            className="right-0 top-16 max-w-[231px] min-w-[231px] text-sm1"
          />
        </InputContainer>
      </div>
      <p className="paragraph1 mt-2">
        Every project comes with two instances. Utilize the Sandbox environment
        for testing. When you're ready for real users, seamlessly publish to the
        Live environment.
      </p>
    </div>
  );
};

export default Environment;
