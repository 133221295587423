import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Hooks
import { Project, getNetworkShoppers } from '@redux/features/projects';

// Redux
import { IRootState } from '@redux/store';

// Utils
import { getClassNames, getRiskLevel, getRiskLevelColor } from '@utils/helpers';
import { DemoGuidePopoverContext } from '@context/demoGuidePopover';

// Components
import DemoGuidePopover from '@components/DemoGuidePopover';
import { RiskTag } from './shoppersCharts';
import ShopperDetails, { Shopper } from './shopperDetails';
import ShopperContactCard from './shopperContactCard';
import ShopperFilterDropdown from '@components/shopperFilterDropdown';
import ToolTip from '@components/ToolTip';

// Assets
import { ReactComponent as ArrowIcon } from '@icons/arrow.svg';
import { ReactComponent as CancelIcon } from '@icons/close-lg.svg';
import { ReactComponent as ExpiredIcon } from '@icons/expired.svg';
import { ReactComponent as SearchIcon } from '@icons/search.svg';
import { ReactComponent as ScoreIcon } from '@icons/score.svg';
import { ReactComponent as SearchShopperIllustration } from '@images/shopper-empty-search-illustration-large.svg';

type ShoppersListProps = {
  project?: Project;
  collapse: boolean;
  setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  selectedShopper?: any;
  setSelectedShopper: (val?: any) => void;
};

const ShoppersList = ({
  project,
  collapse,
  setCollapse,
  selectedShopper,
  setSelectedShopper,
}: ShoppersListProps) => {
  const dispatch = useDispatch();
  const { shoppers, riskLevelFilter } = useSelector(
    (state: IRootState) => state.projectSlice
  );
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [startSearch, setStartSearch] = useState(false);

  const getTotalScore = (attributes: any[]) =>
    attributes?.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue?.score);
    }, 0);

  const handleQueryChange = (q: string) => {
    setQuery(q.toLowerCase());
  };
  const clearSearch = useCallback(() => {
    if (inputRef.current) {
      (inputRef.current as HTMLInputElement).value = '';
    }
    handleQueryChange('');
    setSelectedShopper(null);
  }, [setSelectedShopper]);

  // useEffect(() => {
  //   if (query?.length === 0) clearSearch();
  // }, [clearSearch, query]);
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const formatDate = (d: string) => {
    const date = new Date(d);
    return `${
      monthNames[date.getMonth()]
    } ${date.getDay()}, ${date.getFullYear()} at ${date.getHours()}:${date.getMinutes()}`;
  };

  const handleSearch = () => {
    return shoppers?.users?.filter(
      ({ fullname, project_identifier, risk_level }) =>
        (fullname?.toLowerCase().includes(query) ||
          project_identifier?.toLowerCase().includes(query)) &&
        (!riskLevelFilter?.value || risk_level === riskLevelFilter.value)
    );
  };

  const handleSearchSelect = (shopper: Shopper) => {
    setSelectedShopper(shopper);
    handleQueryChange('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (!shoppers) dispatch(getNetworkShoppers(null) as any);
  }, [dispatch, shoppers]);

  useEffect(() => {
    if (project?.id) dispatch(getNetworkShoppers(project.id) as any);
  }, [dispatch, project]);

  const { popUp3, popUp4, setPopUp3, setPopUp2, setPopUp4 } = React.useContext(
    DemoGuidePopoverContext
  );

  useEffect(() => {
    if (popUp4) {
      setSelectedShopper(shoppers?.users[0]);
    } else {
      setSelectedShopper();
    }
  }, [popUp4, setSelectedShopper, shoppers?.users]);

  return (
    <div
      className={getClassNames(
        'w-full bg-white2 h-full max-h-[90vh] flex-1 overflow-auto rounded-t-lg',
        selectedShopper ? '' : 'pb-20'
      )}
    >
      <div className="flex">
        <div
          className={getClassNames(
            'flex items-center z-[100]',
            collapse && !selectedShopper
              ? 'justify-center bg-white2 rounded-tl-lg border-r border-b border-grey-stroke-primary w-[58px] h-12'
              : 'hidden justify-between px-6 py-4'
          )}
        >
          <ScoreIcon />
          <ArrowIcon
            className={getClassNames(
              'cursor-pointer transition-all duration-300',
              collapse ? '-rotate-90 ml-1' : 'rotate-90'
            )}
            color="#B1B2B6"
            onClick={() => setCollapse((prev) => !prev)}
          />
        </div>
        <div
          className={getClassNames(
            'relative flex-1 border-b border-grey-stroke-primary flex items-center px-8 justify-between h-12',
            startSearch ? 'bg-white' : 'bg-white2',
            selectedShopper && 'rounded-tl-lg'
          )}
        >
          <SearchIcon
            className={getClassNames(
              'min-w-[18px]'
              // collapse && !selectedShopper ? "ml-10" : ""
            )}
          />
          {selectedShopper && (
            <div className="flex items-center gap-x-4 ml-4">
              <span className="whitespace-nowrap">{selectedShopper?.fullname}</span>
              <p
                className={getClassNames(
                  'text-desc-grey text-sm leading-base1 max-w-[380px] h-full',
                  'flex items-center bg-stroke rounded w-fit py-1 px-2 whitespace-nowrap'
                )}
              >
                {selectedShopper?.project_identifier}
              </p>
              <ShopperContactCard
                fullname={selectedShopper?.fullname}
                project_identifier={selectedShopper?.project_identifier}
              />
            </div>
          )}
          <input
            ref={inputRef}
            id="target3"
            type="text"
            placeholder={
              selectedShopper ? '' : 'Search for a consumer by name or trust network ID'
            }
            onFocus={() => setStartSearch(true)}
            onBlur={() => setStartSearch(false)}
            disabled={!!selectedShopper}
            onChange={(e) => handleQueryChange(e.target.value)}
            className={`relative text-base border-0 outline-none focus:border-0 ring-0 py-4 w-full h-full max-[353px] text-center ${
              startSearch ? 'bg-white' : 'bg-white2'
            }`}
          />

          <DemoGuidePopover
            title="2. The Trudenty Trust Network"
            el={() => document.getElementById('target3')}
            topOffset={-260}
            leftOffset={200}
            maxWidth="max-w-[350px]"
            arrowPosition="bottom"
            open={popUp3}
            setOpen={setPopUp3}
            setPrev={setPopUp2}
            setNext={setPopUp4}
          >
            <p className="text-grey-content-tertiary mt-3">
              Search the Trudenty Trust Network to pull up the fraud profile of consumer’s
              in your network, assisting with manual decision making in the case of late
              returns and/ore suspected fraud. <br /> <br />
              Search <span className="text-white2">Samantha Jonhs</span> to view a
              detailed consumer profile.
            </p>
          </DemoGuidePopover>

          {(query?.length > 0 || selectedShopper) && (
            <CancelIcon className="cursor-pointer" onClick={clearSearch} />
          )}
        </div>
      </div>
      {/* Content */}
      {selectedShopper ? (
        <ShopperDetails {...{ selectedShopper }} />
      ) : (
        <div className="">
          {!query && !riskLevelFilter?.value && (
            <div className="bg-white relative min-h-[448px] flex flex-col items-center overflow-y-hidden">
              <h4 className="header4 mt-24">Search the Trust Network</h4>
              <p className="max-w-[376px] paragraph2 text-grey-content-tertiary text-center mt-4">
                View the Consumer Trust Index for consumers who are in your network and
                have consented to sharing data with your organization.
              </p>
              <SearchShopperIllustration className="absolute -bottom-24" />
            </div>
          )}
          <div
            className={getClassNames(
              'bg-white2 border-b border-grey-stroke-primary flex items-center justify-between px-8 py-4'
            )}
          >
            <h6 className="paragraph1 text-body-black">
              Consumers in your Trust Network
            </h6>
            <SearchIcon
              className="cursor-pointer"
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.focus();
                }
              }}
            />
          </div>
          <div
            className={getClassNames(
              'bg-white border-b border-grey-stroke-primary flex items-center justify-between px-8 py-4'
            )}
          >
            <h6 className=" text-grey-content-tertiary">
              {handleSearch()?.length ?? 0} Consumers
            </h6>
            <ShopperFilterDropdown />
          </div>

          {/* table */}
          <div className=" bg-white rounded-md">
            {handleSearch()?.map((shopper: Shopper, i) => {
              const {
                fullname,
                project_identifier,
                attributes,
                created_at,
                inactive,
                risk_level,
              } = shopper;
              return (
                <div
                  className={getClassNames(
                    'group grid grid-cols-[64px,4fr,2fr,3fr,1fr] hover:bg-white2 items-center'
                  )}
                  key={project_identifier}
                >
                  <div className="counter text-grey-content-secondary text-sm self-center w-full mx-auto flex items-center justify-center border-b border-grey-10 h-full">
                    {i + 1}
                  </div>
                  <div
                    className={getClassNames(
                      'title hover:text-body-black text-grey-content-secondary text-base pl-6 flex items-center gap-x-3 self-center',
                      'h-full border-b border-l py-6 border-grey-10 cursor-pointer relative '
                    )}
                    onClick={() => handleSearchSelect(shopper)}
                  >
                    <span className="header">{fullname}</span>
                    {inactive && (
                      <>
                        <ExpiredIcon />
                        <ToolTip
                          className="max-w-[240px] top-14"
                          info="Access expired and score may be outdated. Request permission renewal from the customer."
                        />
                      </>
                    )}
                  </div>
                  <div className="h-full border-b border-l py-6 border-grey-10 pl-6">
                    <p className="text-desc-grey text-sm leading-base1 max-w-[380px] h-full flex items-center bg-stroke rounded w-fit py-1 px-2">
                      {project_identifier}
                    </p>
                  </div>
                  <div className="h-full border-b border-l py-6 border-grey-10 pl-6 group relative">
                    <div className="text-desc-grey text-sm leading-base1 max-w-[380px] h-full flex items-center ">
                      {query ? (
                        <RiskTag risk={getRiskLevel(risk_level)} />
                      ) : (
                        `Added ${formatDate(created_at)}`
                      )}
                    </div>
                    {query && (
                      <ToolTip
                        info="may be offered instant refunds and alternate payment options"
                        className="w-full max-w-[240px]"
                      />
                    )}
                  </div>
                  <div className="h-full flex items-center justify-center border-b border-l py-3 border-grey-10">
                    <div
                      className={getClassNames(
                        'text-xl flex items-center justify-center bg-stroke',
                        'w-[42px] h-[42px] rounded-full',
                        getRiskLevelColor(risk_level, inactive)
                      )}
                    >
                      {getTotalScore(attributes)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ShoppersList;
