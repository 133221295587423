import React, { useEffect, useState } from "react";
import { getClassNames } from "@utils/helpers";
import { getRiskColor } from "@utils/helpers";
import { createPortal } from "react-dom";

interface ToolTipProps {
  className?: string;
  info?: string;
  risk?: string;
}

const ToolTip: React.FC<ToolTipProps> = ({ className, info = "Tool tip", risk }) => {
  return (
    <div
      className={getClassNames(
        "z-[999] bg-body-black p-2 absolute hidden group-hover:block",
        "rounded text-white",
        className
      )}
    >
      {risk && (
        <div
          className={getClassNames(
            "w-3 h-3 rounded-sm inline-block mr-2 bg-green-alert",
            getRiskColor(risk)
          )}
        />
      )}
      {info}
    </div>
  );
};

export default ToolTip;

interface ToolTipPopProps extends ToolTipProps {
  target: string;
}

export const ToolTipPop: React.FC<ToolTipPopProps> = ({ className, info = "Tool tip", risk, target }) => {
  const [show, setShow] = useState(false);
  const el = document.getElementById(target);
  useEffect(() => {
    if (el) {
      el.onmouseenter = () => {
        if (show) setShow(false);
        setTimeout(() => {
          setShow(true);
        }, 100);
      };
      el.onmouseleave = () => {
        setTimeout(() => {
          setShow(false);
        }, 0);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el]);
  return createPortal(
    <div
      className={getClassNames(
        "z-[999] bg-body-black p-2 absolute",
        "rounded text-white",
        show ? "block" : "hidden",
        className
      )}
      style={{
        top: el?.offsetTop ?? 0 + (el?.offsetHeight ?? 0) - 30,
        left: el?.offsetLeft,
      }}
      onMouseEnter={() => {
        if (el) {
          el.onmouseleave = () => { };
        }
      }}
      onMouseLeave={() => {
        if (el) {
          el.onmouseleave = () => {
            setShow(false);
          };
        }
      }}
    >
      {risk && (
        <div
          className={getClassNames(
            "w-3 h-3 rounded-sm inline-block mr-2 bg-green-alert",
            getRiskColor(risk)
          )}
        />
      )}
      {info}
    </div>,
    document.body
  );
};
