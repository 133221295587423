import { Tab } from '@headlessui/react';
import { useState } from 'react';
import { getClassNames } from '@/utils/helpers';
import Topbar from '@/components/topbar';
import AllUser from './AllUser';
import AllTeams from './AllTeams';
import RoleAndPermission from './RoleAndPermission';
import SearchInput from '@/pages/dashboard/components/searchInput';
import { ROLES } from '@/utils/constants';
import ReactListbox from '@/components/FormElement/ReactListbox';

export interface TeamProps {
  name: string;
  role?: {
    name: string;
    organization_type: string;
  };
  image?: string;
  id?: number;
  role_id?: string;
}

const PartnerUsers = ({
  organizationId,
  organizationName,
  setOrganizationDetail,
}: {
  organizationId?: string;
  organizationName?: string;
  setOrganizationDetail?: (value: any) => void;
}) => {
  const [activeSection, setActiveSection] = useState<number>(0);
  const [currentRole, setCurrentRole] = useState('all');
  const [searchText, setSearchText] = useState('');

  const tabOptions = ['All Users', 'Teams', 'Roles and Permissions'];
  const [selectedTab, setSelectedTab] = useState<string>('All Users');
  const isDetail = false;

  return (
    <>
      <meta title="Users - Trudenty Trust Network Console" />
      <div className="h-full w-full flex flex-col overflow-hidden">
        <Topbar
          title={organizationName ?? 'Users'}
          titleClassName="text-base1 font-medium"
          buttonConfig={{ color: 'light', title: 'Add Team' }}
          secondButtonConfig={{ color: 'dark', title: 'Add User' }}
          showBackButton={organizationId ? true : false}
          backButtonAction={() => {
            if (organizationId) {
              setOrganizationDetail?.(null);
            }
          }}
        />
        <div className="flex-1 h-screen w-full bg-white3 overflow-y-auto">
          <Tab.Group onChange={setActiveSection} selectedIndex={activeSection}>
            <Tab.List className="relative">
              <div className="px-8">
                <div className="flex justify-between bg-white4 border border-solid border-stroke-primary border-b-0 rounded-md py-2 px-6 rounded-b-none">
                  <div className=" flex gap-2">
                    {tabOptions?.map((tabTitle) => (
                      <Tab key={tabTitle} onClick={() => setSelectedTab(tabTitle)}>
                        {({ selected }) => (
                          <div
                            className={getClassNames(
                              'px-4 py-2 text-lg border border-grey-stroke-primary rounded',
                              selected
                                ? 'text-trud-black bg-grey-stroke-primary'
                                : 'text-grey-content-secondary'
                            )}
                          >
                            {tabTitle}
                          </div>
                        )}
                      </Tab>
                    ))}
                  </div>
                  <div className="flex items-center gap-2">
                    {selectedTab !== 'Roles and Permissions' && (
                      <>
                        <ReactListbox
                          Options={[
                            { label: 'All', value: 'all' },
                            ...Object.values(ROLES).map((roleData) => {
                              return { label: roleData, value: roleData };
                            }),
                          ]}
                          onChange={(newValue) => {
                            setCurrentRole(newValue);
                          }}
                          selectedOption={currentRole}
                          btnClass="min-w-28"
                        />
                        <SearchInput
                          handleChange={(data: string) => {
                            setSearchText(data);
                          }}
                          className="rounded-full text-grey-content-secondary px-1 py-1 w-36"
                          value={searchText}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Tab.List>
            {!isDetail ? (
              <Tab.Panels>
                <Tab.Panel>
                  <AllUser currentRole={currentRole} search={searchText} />
                </Tab.Panel>
                <Tab.Panel>
                  <AllTeams currentRole={currentRole} search={searchText} />
                </Tab.Panel>
                <Tab.Panel>
                  <RoleAndPermission />
                </Tab.Panel>
              </Tab.Panels>
            ) : (
              // renderDetail()
              <></>
            )}
          </Tab.Group>
        </div>
      </div>
    </>
  );
};

export default PartnerUsers;
