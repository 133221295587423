import React, { useState } from "react";
import { useForm } from "react-hook-form";

// Utils
import { emailPattern } from "@utils/helpers";

// Components
import Input, { TextArea } from "@components/input";
import RoleSelect from "@components/RoleSelect";
import GroupSelect from "@components/GroupSelect";
import { Button } from "@components/button";

const UserDetails = ({ setStep }) => {
  const [group, setGroup] = useState("Internal Team");
  const [role, setRole] = useState({
    role: "viewer",
    description: "Cannot edit or configure",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async () => {
    setStep(1);
  };
  return (
    <div className="bg-white rounded-md border border-grey-stroke-primary mb-10 overflow-y-auto">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full p-10 flex flex-col gap-y-4"
      >
        <div className="flex gap-x-2 w-full">
          <Input
            name="firstName"
            label="First name"
            defaultValue="Marco"
            {...{ register, errors }}
            rules={{
              required: true,
            }}
          />
          <Input
            name="lastName"
            label="Last name"
            defaultValue="Kome"
            {...{ register, errors }}
            rules={{
              required: true,
              minLength: 6,
            }}
          />
        </div>
        <Input
          name="email"
          type="email"
          label="Email"
          defaultValue="marco@trudenty.com"
          {...{ register, errors }}
          rules={{
            required: true,
            minLength: 6,
            pattern: emailPattern,
          }}
          labelStyle="text-desc-grey"
          placeholder={"name@company.com"}
        />
        <div className="grid grid-cols-[118px,1fr] gap-x-2 w-full">
          <Input
            name="countryCode"
            label="Phone number"
            defaultValue="+234"
            {...{ register, errors }}
            rules={{
              required: true,
            }}
          />
          <Input
            name="phoneNumber"
            label="-"
            defaultValue="08123457903"
            labelStyle="text-white"
            {...{ register, errors }}
            rules={{
              required: true,
              minLength: 6,
            }}
          />
        </div>
        <GroupSelect value={group} onChange={setGroup} />
        <RoleSelect value={role} onChange={setRole} />
        <Input
          name="userId"
          label="User ID"
          defaultValue="352e1e77f432c2f47284b308b73a68b810cc29"
          {...{ register, errors }}
          rules={{
            required: true,
            minLength: 10,
          }}
        />
        <TextArea
          name="fingerprint"
          label="Mobile Fingerprint"
          defaultValue="Trudenty/1.0 (iPhone; CPU iPhone OS 16_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.5 Mobile/15E148 Safari/604.1"
          {...{ register, errors }}
          rules={{
            required: true,
            minLength: 10,
          }}
        />
        <Input
          name="createdAt"
          label="Profile created on"
          placeholder="8 Aug 2023 - 15:26"
          disabled
          {...{ register, errors }}
        />
        <div className="flex gap-x-4 mt-2">
          <Button
            title="Save"
            color="dark"
            category="1"
            //   onClick={() => setOpenCreateUsersModal(true)}
          />
          <Button
            title="Cancel"
            color="light"
            category="1"
            onClick={() => {}}
          />
        </div>
      </form>
    </div>
  );
};

export default UserDetails;
