import React from 'react';
import { useNavigate } from 'react-router-dom';

// Utils
import { getClassNames } from '@utils/helpers';

// Components
import CustomPopover from '@components/customPopover';
import EmptyUsersState from './emptyState';
import Switch from '@components/switch';

// Assets
import EllipsisIcon from '@icons/Ellipsis.js';

const UsersTable = ({ members, setIsBulk, setOpenCreateUsersModal, org }) => {
  const navigate = useNavigate();
  // members = [];
  const getRoleColors = (role) => {
    const colors = {
      admin: 'text-blurple',
    };
    if (colors[role]) return colors[role];
    return 'text-body-black';
  };
  return (
    <div>
      {members?.length ? (
        <div className="px-8 pb-6">
          <div className="border border-stroke bg-white rounded-md">
            <div className="grid grid-cols-[64px,2fr,2fr,1fr,64px,1fr] bg-grey-600 py-2 leading-base1 text-sm text-grey-content-secondary">
              <span className="self-center mx-auto">#</span>
              <div className="pl-6">Name</div>
              <div className="pl-6">Email</div>
              <div className="pl-6">Last seen</div>
              <div className="pl-6">Role</div>
            </div>
            {members?.map(
              ({ firstName, lastName, id, email, lastSeen, role, active }, i) => (
                <div
                  className="draggable-item group grid grid-cols-[64px,2fr,2fr,1fr,64px,1fr] hover:bg-white2 items-center"
                  key={id}
                >
                  <div className="counter text-grey-content-secondary text-sm self-center w-full mx-auto flex items-center justify-center border-b border-grey-10 h-full">
                    {i + 1}
                  </div>
                  <div
                    className={getClassNames(
                      'title text-body-black text-base pl-6 flex items-center gap-x-3 self-center',
                      'h-full border-b border-l py-6 border-grey-10 cursor-pointer'
                    )}
                    onClick={() => navigate('/users/id')}
                  >
                    <span className="header">{`${firstName} ${lastName}`}</span>
                  </div>
                  <div className="h-full border-b border-l py-6 border-grey-10 pl-6">
                    <p className="text-desc-grey text-sm leading-base1 max-w-[380px] h-full flex items-center ">
                      {email}
                    </p>
                  </div>
                  <div className="h-full border-b border-l py-6 border-grey-10 pl-6">
                    <p className="text-desc-grey text-sm leading-base1 max-w-[380px] h-full flex items-center ">
                      {lastSeen}
                    </p>
                  </div>
                  <div className="h-full flex items-center border-b border-l py-6 border-grey-10 pl-6">
                    <p
                      className={getClassNames(
                        'text-sm leading-base1 flex items-center capitalize',
                        'border border-stroke px-2 py-1 rounded',
                        getRoleColors(role)
                      )}
                    >
                      {role}
                    </p>
                  </div>
                  <div className="h-full flex items-center justify-center">
                    <CustomPopover
                      className="right-0 top-2"
                      placeholder={<EllipsisIcon color="#747678" />}
                      options={[
                        {
                          name: 'Edit Profile',
                          id: 1,
                          value: true,
                          onClick: () => {},
                        },
                        {
                          id: 2,
                          value: true,
                          onClick: () => {},
                          render: (
                            <div
                              className={getClassNames(
                                'flex items-center gap-x-2 w-full px-2'
                              )}
                            >
                              <span
                                className={getClassNames(
                                  'block truncate w-full text-lg leading-5 font-medium',
                                  active ? 'text-green-alert' : 'text-body-black'
                                )}
                              >
                                Active
                              </span>
                              <Switch
                                checked={active}
                                toggleCheck={() => (members[id][active] = !active)}
                              />
                            </div>
                          ),
                        },
                        {
                          name: 'Remove Member',
                          id: 3,
                          value: true,
                          onClick: () => {},
                        },
                      ]}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      ) : (
        <EmptyUsersState {...{ setOpenCreateUsersModal, setIsBulk, org }} />
      )}
    </div>
  );
};

export default UsersTable;
