import ComingSoon from '@/components/ComingSoon/comingSoon';

const MerchantInfraStructure = () => {
  return (
    <>
      <ComingSoon
        title="Infrastructure activity"
        content="This dashboard will provide reports on all blockchain activity on the Trust Network infrastructure in the future."
      />
    </>
  );
};

export default MerchantInfraStructure;
