import { createSlice } from '@reduxjs/toolkit';
import { IRootState } from '../store';

type organizationProps = {
  id: string;
  name: string;
  organization_type: string;
  image: string | null;
};

type InitialStateProps = {
  activeOrganization: organizationProps | null;
};

const slice = createSlice({
  name: 'organization',
  initialState: {
    activeOrganization: null,
    userOrganization: null,
  } as InitialStateProps,
  reducers: {
    setActiveOrganization: (state, { payload }) => {
      state.activeOrganization = payload;
    },
  },
});

export const { setActiveOrganization } = slice.actions;

export const getActiveOrganization = (state: IRootState) =>
  state.organizationSlice.activeOrganization;

export default slice.reducer;
