import React from 'react';
import { Menu } from '@headlessui/react';

// Helpers
import { getClassNames } from '@utils/helpers';

// Components
import { InputContainer } from '@components/input';

// Assets
import { ReactComponent as CaretIcon } from '@icons/arrow_down.svg';

export const GroupTag = ({ title }) => (
  <span
    className={getClassNames(
      'text-white text-sm leading-5 font-medium capitalize',
      'bg-grey-content-tertiary rounded px-2 py-1'
    )}
  >
    {title}
  </span>
);

const GroupSelect = ({ value, onChange }) => {
  const groups = ['Internal Team', 'Administrators', 'Human Resources'];
  const handleClick = (value) => {
    onChange(value);
  };

  return (
    <InputContainer className="relative" label="Group">
      <Menu>
        <Menu.Button
          className={getClassNames(
            'flex items-center gap-x-2 justify-between w-full h-full',
            'bg-white4 border-0  text-left'
          )}
        >
          <GroupTag title={value} />
          <span className="pointer-events-none mt-1">
            <CaretIcon />
          </span>
        </Menu.Button>

        <Menu.Items
          className={getClassNames(
            'absolute z-10 bg-white shadow-lg p-1 pt-0.5 min-w-[10rem] rounded'
          )}
        >
          {groups?.map((option) => (
            <Menu.Item
              key={option}
              onClick={() => handleClick(option)}
              className={({ active }) =>
                getClassNames(
                  active ? 'text-white bg-grey' : 'text-gray-900',
                  'cursor-pointer select-none py-1 px-1 mt-0.5'
                )
              }
              value={option}
            >
              <div
                className={getClassNames(
                  value === option && 'bg-grey',
                  'flex flex-col items-center w-full py-2 px-2 hover:bg-grey rounded'
                )}
              >
                <span
                  className={getClassNames(
                    'block truncate w-full text-base leading-5 font-medium text-body-black capitalize'
                  )}
                >
                  {option}
                </span>
              </div>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </InputContainer>
  );
};

export default GroupSelect;
