import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Hooks
import { Project, getNetworkShoppers } from '@redux/features/projects';

// Utils
import { getClassNames } from '@utils/helpers';

// Context
import { DemoGuidePopoverContext } from '@context/demoGuidePopover';

// Components
import ReturnsCopilotSearchBar from './returnsCopilotSearchbar';
import ShopperDetails, { Shopper } from './shopperDetails';
import StatusUpdatePopover from './statusUpdatePopover';
import { IRootState } from '@/redux/store';
// import { IRootState } from "@/redux/store";

type ShopperListProps = {
  project?: Project;
  collapse: boolean;
  setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  selectedShopper?: any;
  setSelectedShopper: (val?: any) => void;
};

const ShoppersListReturnsCopilot: React.FC<ShopperListProps> = ({
  project,
  collapse,
  setCollapse,
  selectedShopper,
  setSelectedShopper,
}) => {
  const dispatch = useDispatch();
  // const { shoppers } = useSelector((state: IRootState) => state.projectSlice);
  const [returnStates, setReturnStates] = useState([0, 1, 2, 3, 4]);
  const [clearedUsers, setClearedUser] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [startSearch, setStartSearch] = useState(false);
  const { riskLevelFilter } = useSelector((state: IRootState) => state.projectSlice);

  const shoppers = useMemo(
    () => ({
      users: [
        {
          id: 0,
          fullname: 'Samantha Jonhs',
          project_id: 'd3f83a2c-090f-4851-93f5-7d92d7bc06f4',
          created_at: '2023-10-10T12:00:00Z',
          scores: 90,
          returnInitiated: '202',
          status: 'Pending',
          actionRequired: 'Check return',
          project_identifier: 'TRU-5678-EFGH',
          profileId: '10058711',
          account_num: '2319564973',
          attributes: [
            {
              id: 1,
              name: 'Identity',
              score: '28.00',
              description:
                'Identity verification based on bank-verified identity and digital identity.',
              recommended: '0.00',
            },
            {
              id: 2,
              name: 'Banking history',
              score: '12.00',
              description:
                'Consumer bank activity levels including shopping returns and refunds history.',
              recommended: '0.00',
            },
            {
              id: 3,
              name: 'Merchant data',
              score: '25.00',
              description:
                'History of consumer’s orders, returns and refunds in your company.',
              recommended: '0.00',
            },
            {
              id: 4,
              name: 'Network intelligence',
              score: '0.00',
              description:
                'History of consumer’s orders, returns and refunds in across the network including other ecommerce players.',
              recommended: '0.00',
            },
            {
              id: 5,
              name: 'Real world data',
              score: '0.00',
              description:
                'Other trusted data sources e.g card data networks and data credit bureau.',
              recommended: '0.00',
            },
          ],
          risk_level: 'MEDIUM_RISK_SHOPPER',
        },
        {
          fullname: 'Jacob Martinez',
          scores: 90,
          returnInitiated: '202',
          status: 'Pending',
          actionRequired: 'Process refund',
          created_at: '2023-10-10T12:00:00Z',
          project_identifier: 'TRU-5678-EFGU',
          profileId: '10058711',
          account_num: '2319564973',
          attributes: [
            {
              id: 1,
              name: 'Identity',
              score: '28.00',
              description:
                'Identity verification based on bank-verified identity and digital identity.',
              recommended: '0.00',
            },
            {
              id: 2,
              name: 'Banking history',
              score: '12.00',
              description:
                'Consumer bank activity levels including shopping returns and refunds history.',
              recommended: '0.00',
            },
            {
              id: 3,
              name: 'Merchant data',
              score: '15.00',
              description:
                'History of consumer’s orders, returns and refunds in your company.',
              recommended: '0.00',
            },
            {
              id: 4,
              name: 'Network intelligence',
              score: '0.00',
              description:
                'History of consumer’s orders, returns and refunds in across the network including other ecommerce players.',
              recommended: '0.00',
            },
            {
              id: 5,
              name: 'Real world data',
              score: '0.00',
              description:
                'Other trusted data sources e.g card data networks and data credit bureau.',
              recommended: '0.00',
            },
          ],
          risk_level: 'MEDIUM_RISK_SHOPPER',
        },
        {
          fullname: 'Olivia Williams',
          scores: 90,
          returnInitiated: '202',
          status: 'Pending',
          actionRequired: 'Check return',
          created_at: '2023-10-10T12:00:00Z',
          project_identifier: 'TRU-5678-EFGP',
          profileId: '10058711',
          account_num: '2319564973',
          attributes: [
            {
              id: 1,
              name: 'Identity',
              score: '16.00',
              description:
                'Identity verification based on bank-verified identity and digital identity.',
              recommended: '0.00',
            },
            {
              id: 2,
              name: 'Banking history',
              score: '12.00',
              description:
                'Consumer bank activity levels including shopping returns and refunds history.',
              recommended: '0.00',
            },
            {
              id: 3,
              name: 'Merchant data',
              score: '15.00',
              description:
                'History of consumer’s orders, returns and refunds in your company.',
              recommended: '0.00',
            },
            {
              id: 4,
              name: 'Network intelligence',
              score: '0.00',
              description:
                'History of consumer’s orders, returns and refunds in across the network including other ecommerce players.',
              recommended: '0.00',
            },
            {
              id: 5,
              name: 'Real world data',
              score: '0.00',
              description:
                'Other trusted data sources e.g card data networks and data credit bureau.',
              recommended: '0.00',
            },
          ],
          risk_level: 'HIGH_RISK_SHOPPER',
        },
        {
          fullname: 'Liam Johnson',
          scores: 90,
          returnInitiated: '202',
          status: 'Pending',
          actionRequired: 'Reject refund',
          created_at: '2023-10-10T12:00:00Z',
          project_identifier: 'TRU-5678-EFYU',
          profileId: '10058711',
          account_num: '2319564973',
          attributes: [
            {
              id: 1,
              name: 'Identity',
              score: '25.00',
              description:
                'Identity verification based on bank-verified identity and digital identity.',
              recommended: '0.00',
            },
            {
              id: 2,
              name: 'Banking history',
              score: '12.00',
              description:
                'Consumer bank activity levels including shopping returns and refunds history.',
              recommended: '0.00',
            },
            {
              id: 3,
              name: 'Merchant data',
              score: '15.00',
              description:
                'History of consumer’s orders, returns and refunds in your company.',
              recommended: '0.00',
            },
            {
              id: 4,
              name: 'Network intelligence',
              score: '0.00',
              description:
                'History of consumer’s orders, returns and refunds in across the network including other ecommerce players.',
              recommended: '0.00',
            },
            {
              id: 5,
              name: 'Real world data',
              score: '0.00',
              description:
                'Other trusted data sources e.g card data networks and data credit bureau.',
              recommended: '0.00',
            },
          ],
          risk_level: 'HIGH_RISK_SHOPPER',
        },
        {
          fullname: 'Sophia Anderson',
          scores: 90,
          returnInitiated: '202',
          status: 'Pending',
          actionRequired: 'Return not received - contact consumer',
          created_at: '2023-10-10T12:00:00Z',
          project_identifier: 'TRU-5678-ERGU',
          profileId: '10058711',
          account_num: '2319564973',
          attributes: [
            {
              id: 1,
              name: 'Identity',
              score: '28.00',
              description:
                'Identity verification based on bank-verified identity and digital identity.',
              recommended: '0.00',
            },
            {
              id: 2,
              name: 'Banking history',
              score: '12.00',
              description:
                'Consumer bank activity levels including shopping returns and refunds history.',
              recommended: '0.00',
            },
            {
              id: 3,
              name: 'Merchant data',
              score: '15.00',
              description:
                'History of consumer’s orders, returns and refunds in your company.',
              recommended: '0.00',
            },
            {
              id: 4,
              name: 'Network intelligence',
              score: '3.00',
              description:
                'History of consumer’s orders, returns and refunds in across the network including other ecommerce players.',
              recommended: '0.00',
            },
            {
              id: 5,
              name: 'Real world data',
              score: '0.00',
              description:
                'Other trusted data sources e.g card data networks and data credit bureau.',
              recommended: '0.00',
            },
          ],
          risk_level: 'HIGH_RISK_SHOPPER',
        },
      ],
    }),
    []
  );

  const { popUp4 } = React.useContext(DemoGuidePopoverContext);

  const getTotalScore = (attributes: any) =>
    attributes?.reduce((accumulator: number, currentValue: any) => {
      return accumulator + parseInt(currentValue?.score);
    }, 0);

  const handleQueryChange = (q: string) => {
    setQuery(q.toLowerCase());
  };
  const clearSearch = useCallback(() => {
    if (inputRef.current) inputRef.current.value = '';
    handleQueryChange('');
    setSelectedShopper();
  }, [setSelectedShopper]);

  const handleReturn = (i: number, opt: string) => {
    const temp = [...returnStates];
    temp[i] = parseInt(opt);
    setReturnStates([...temp]);
  };

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const formatDate = (d: string) => {
    const date = new Date(d);
    return `${
      monthNames[date.getMonth()]
    } ${date.getDay()}, ${date.getFullYear()} at ${date.getHours()}:${date.getMinutes()}`;
  };

  const handleSearch = () => {
    return shoppers?.users?.filter(
      ({ fullname, project_identifier, risk_level }) =>
        (fullname?.toLowerCase().includes(query) ||
          project_identifier?.toLowerCase().includes(query)) &&
        !clearedUsers.includes(project_identifier) &&
        (!riskLevelFilter?.value || risk_level === riskLevelFilter.value)
    );
  };

  const handleSearchSelect = (shopper: Shopper) => {
    setSelectedShopper(shopper);
    handleQueryChange('');
    if (inputRef.current) inputRef.current.value = '';
  };

  useEffect(() => {
    if (!shoppers) dispatch(getNetworkShoppers(null) as any);
  }, [dispatch, shoppers]);

  useEffect(() => {
    if (project?.id) {
      dispatch(getNetworkShoppers(project?.id) as any);
    }
  }, [dispatch, project]);

  useEffect(() => {
    if (popUp4) {
      setSelectedShopper(shoppers?.users[0]);
    }
  }, [popUp4, setSelectedShopper, shoppers?.users]);

  return (
    <div
      className={getClassNames(
        'w-full bg-white2 h-full max-h-[90vh] flex-1 overflow-auto',
        selectedShopper && collapse ? 'rounded-t-md' : '',
        !selectedShopper && collapse ? 'rounded-t-lg' : ''
      )}
    >
      <ReturnsCopilotSearchBar
        {...{
          startSearch,
          selectedShopper,
          setStartSearch,
          handleQueryChange,
          clearSearch,
          inputRef,
          query,
          collapse,
          setCollapse,
        }}
      />
      {/* Content */}
      {selectedShopper ? (
        <ShopperDetails {...{ selectedShopper }} />
      ) : (
        <>
          <div
            className={getClassNames(
              'group grid grid-cols-[64px,4fr,2fr,3fr,3fr,3fr,64px] hover:bg-white2 items-center border-b border-grey-stroke-tertiary'
            )}
          >
            <span className="h-full w-full flex items-center justify-center text-center bg-white py-2 text-grey-content-tertiary text-sm">
              #
            </span>
            <span className="h-full w-full flex items-center pl-6 bg-white py-2 text-grey-content-tertiary text-sm">
              Consumer
            </span>
            <span className="h-full w-full flex items-center justify-center bg-white py-2 text-grey-content-tertiary text-sm text-center ">
              Consumer Trust Index
            </span>
            <span className="h-full w-full flex items-center pl-6 bg-white py-2 text-grey-content-tertiary text-sm">
              Return initiated
            </span>
            <span className="h-full w-full flex items-center pl-6 bg-white py-2 text-grey-content-tertiary text-sm">
              Status
            </span>
            <span className="h-full w-full flex items-center pl-6 bg-white py-2 text-grey-content-tertiary text-sm">
              Action required
            </span>
            <span className="h-full w-full flex items-center pl-6 bg-white py-2 text-grey-content-tertiary text-sm"></span>
          </div>

          <div className="">
            {/* table */}
            <div className=" bg-white rounded-md">
              {handleSearch()?.map((shopper, i) => {
                return (
                  <StatusUpdatePopover
                    {...{
                      returnStates,
                      i,
                      handleReturn,
                      shopper,
                      handleSearchSelect,
                      getTotalScore,
                      query,
                      formatDate,
                      setClearedUser,
                    }}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShoppersListReturnsCopilot;
