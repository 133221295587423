import React from "react";

// Components
import { Button } from "@components/button";

// Assets
import { ReactComponent as EmptyStateIcon } from "@icons/empty-users-illustration.svg";

const EmptyUsersState = ({ setOpenCreateUsersModal, setIsBulk, org="team" }) => {
  return (
    <div className="flex flex-col max-w-[347px] mx-auto flex-1 h-full pt-[15%] items-center">
      <EmptyStateIcon />
      <h3 className="mt-6 mb-2">No Users</h3>
      <p className="text-desc-grey text-center leading-5">
        There are no users in this {org}. You can add users individually
        or import in bulk to populate the user database.
      </p>
      <div className="flex gap-x-4">
        <Button
          title="Add Single User"
          color="dark"
          category="1"
          className="mt-8"
          onClick={() => setOpenCreateUsersModal(true)}
        />
        <Button
          title="Bulk Import"
          color="light"
          category="1"
          className="mt-8"
          onClick={() => {
            setIsBulk(true);
            setOpenCreateUsersModal(true)
          }}
        />
      </div>
    </div>
  );
};

export default EmptyUsersState;
