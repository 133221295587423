import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// Utils
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import Input from "@components/input";

// Assets
import { ReactComponent as PlatformLogo } from "@icons/bankco.svg";

const MockBankLogin = () => {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async () => {
    navigate("/setup-payment/bank-consent")
  };

  return (
    <div className="flex flex-col h-full bg-white w-full mx-auto">
      <div className={getClassNames("bg-[#22222205]")}>
        <div className="container-auth min-h-[52px] flex items-center justify-center relative ">
          <PlatformLogo className="absolute left-0" />
        </div>
      </div>
      <div className="h-full">
        <div
          className={getClassNames(
            "h-full w-full py-16 mx-auto container-auth",
            "flex-1 flex flex-col h-full rounded items-center"
          )}
        >
          <div
            className={getClassNames(
              "w-full max-w-[832px] mx-auto flex-1 mb-10",
              "flex flex-col items-center"
            )}
          >
            <div className="max-w-[378px]">
              <h3 className="text-body-black text-xl2 mb-10 leading-[120%]">
                Log in to Bank Co
              </h3>
              <form onSubmit={handleSubmit(onSubmit)} className="w-[378px]">
                <div className="flex flex-col gap-y-4 w-full">
                  <Input
                    name={"itid"}
                    label={"Internet Banking User ID"}
                    category="auth"
                    {...{ register, errors }}
                    rules={{
                      required: true,
                    }}
                    labelStyle="text-desc-grey"
                    placeholder="Username"
                  />
                  <Input
                    name={"password"}
                    type={"password"}
                    label={"Password"}
                    category="auth"
                    {...{ register, errors }}
                    rules={{
                      required: true,
                    }}
                    labelStyle="text-desc-grey"
                    placeholder="Password"
                  />
                </div>

                <Button
                  title="Continue"
                  color="dark"
                  size="custom"
                  className="mt-6"
                  disabled={!isValid}
                  type="submit"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MockBankLogin;
