import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

// Utils
import { getClassNames } from "@utils/helpers";

// Assets
import { Button } from "@components/button";

type FocusedStateCheckoutAndRefundProps = {
  children?: React.ReactNode;
  showFocusedStated: boolean;
  onClick: () => void;
};

const FocusedStateCheckoutAndRefund: React.FC<FocusedStateCheckoutAndRefundProps> = ({ showFocusedStated, onClick, children }) => {

  return (
    <Transition appear show={showFocusedStated} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="relative flex min-h-full container-solutions">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `absolute top-[40%] h-fit left-5 w-full max-w-[203px] transform rounded-lg bg-body-black 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all p-5`
                )}
              >
                
                {children}
                <div className="flex mt-10">
                  <Button
                    title="Got it"
                    color="light"
                    category="2"
                    className="text-sm1"
                    onClick={onClick}
                  />
                
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default FocusedStateCheckoutAndRefund;
