import { roleAndPermissionData, tabOptions } from '@/utils/constants';
import { getClassNames, getRandomInt } from '@/utils/helpers';
import { Tab } from '@headlessui/react';
import { useState } from 'react';
import ChangePermission from './ChangePermission';

const RoleAndPermissionsDescription = ({ title }: { title: string }) => {
  const [role, setRole] = useState<number>(0);

  const renderPermissionList = (category: string) => {
    // setCategory(categoryData);
    return (
      (
        <div>
          {roleAndPermissionData?.[title]?.[category] &&
            roleAndPermissionData?.[title]?.[category]?.map(
              (permissionData: {
                title: string;
                description: string;
                hasPermission: boolean;
              }) => {
                return (
                  <ChangePermission
                    key={permissionData.title}
                    title={permissionData.title}
                    description={permissionData.description}
                    hasPermission={permissionData.hasPermission}
                  />
                );
              }
            )}
        </div>
      ) ?? <></>
    );
  };
  return (
    <>
      <h4 className="text-black text-xl2 leading-2xl2 font-medium mb-5">{title}</h4>
      <div className="border border-solid border-stroke w-full shadow-bordershadow">
        <p className="border-b border-solid text-lg text-desc-grey border-grey-stroke-tertiary py-4 px-8">
          Permissions
        </p>
        <div className="px-8 pb-3">
          <Tab.Group
            selectedIndex={role}
            onChange={(currentRole) => {
              setRole(currentRole);
            }}
          >
            <Tab.List className="px-0 pt-6 pb-4 flex items-center gap-2">
              {tabOptions?.map((tabTitle) => (
                <Tab key={getRandomInt()}>
                  {({ selected }) => (
                    <div
                      className={getClassNames(
                        'px-4 py-2 border border-grey-stroke-primary rounded focus:outline-none font-medium',
                        selected
                          ? 'text-trud-black bg-grey-stroke-primary'
                          : 'text-grey-content-secondary'
                      )}
                    >
                      {tabTitle}
                    </div>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel key={getRandomInt()}>
                {renderPermissionList('Organisation')}
              </Tab.Panel>
              <Tab.Panel key={getRandomInt()}>{renderPermissionList('users')}</Tab.Panel>
              <Tab.Panel key={getRandomInt()}>
                {renderPermissionList('projects')}
              </Tab.Panel>
              <Tab.Panel key={getRandomInt()}>
                {renderPermissionList('dashboard')}
              </Tab.Panel>
              <Tab.Panel key={getRandomInt()}>{renderPermissionList('roles')}</Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </>
  );
};

export default RoleAndPermissionsDescription;
