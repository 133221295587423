import React, { Fragment, useState } from 'react';
import { RadioGroup, Transition } from '@headlessui/react';

// Utils
import { getClassNames, getRiskLevel, getRiskLevelColor } from '@utils/helpers';

// Components
import { Button } from '@components/button';

// Assets
import Caret from '@assets/icons/Caret';

// Components
import { RiskTag } from './shoppersCharts';
import ToolTip from '@components/ToolTip';

// Assets
import { ReactComponent as ExpiredIcon } from '@icons/expired.svg';
import Ellipsis from '@assets/icons/Ellipsis';

type StatusUpdatePopoverProps = {
  returnStates: number[];
  i: number;
  handleReturn: (i: number, opt: any) => void;
  shopper: any;
  handleSearchSelect: (shopper: any) => void;
  getTotalScore: (attributes: any) => number;
  query: string;
  formatDate: (date: string) => string;
  setClearedUser: React.Dispatch<React.SetStateAction<string[]>>;
};

const StatusUpdatePopover: React.FC<StatusUpdatePopoverProps> = ({
  returnStates,
  i,
  handleReturn,
  shopper,
  handleSearchSelect,
  getTotalScore,
  query,
  formatDate,
  setClearedUser,
}) => {
  const {
    fullname,
    project_identifier,
    attributes,
    created_at,
    inactive,
    risk_level,
    // actionRequired,
  } = shopper;
  const [checked, setChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openReason, setOpenReason] = useState(false);
  const [openReason2, setOpenReason2] = useState(false);
  return (
    <div key={project_identifier} className="relative">
      <div
        className={getClassNames(
          'cursor-pointer group grid grid-cols-[64px,4fr,2fr,3fr,3fr,3fr,64px] w-full hover:bg-white2 items-center'
        )}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <div className="counter text-grey-content-secondary text-sm self-center w-full mx-auto flex items-center justify-center border-b border-grey-10 h-full">
          {i + 1}
        </div>
        <div
          className={getClassNames(
            'title hover:text-body-black hover:text-underline text-grey-content-secondary text-base pl-6 flex items-center gap-x-3 self-center',
            'h-full border-b border-l py-6 border-grey-10 cursor-pointer relative '
          )}
          onClick={() => handleSearchSelect(shopper)}
        >
          <span className="header">{fullname}</span>
          {inactive && (
            <>
              <ExpiredIcon />
              <ToolTip
                className="max-w-[240px] top-14"
                info="Access expired and score may be outdated. Request permission renewal from the customer."
              />
            </>
          )}
        </div>
        <div className="h-full flex items-center justify-center border-b border-l py-3 border-grey-10">
          <div
            className={getClassNames(
              'text-xl flex items-center justify-center bg-stroke',
              'w-[42px] h-[42px] rounded-full',
              getRiskLevelColor(risk_level, inactive)
            )}
          >
            {getTotalScore(attributes)}
          </div>
        </div>
        <div className="h-full border-b border-l py-6 border-grey-10 pl-6 group relative">
          <div className="text-desc-grey text-sm leading-base1 max-w-[380px] h-full flex items-center ">
            {query ? (
              <RiskTag risk={getRiskLevel(risk_level)} />
            ) : (
              `${formatDate(created_at)}`
            )}
          </div>
          {query && (
            <ToolTip
              info="may be offered instant refunds and alternate payment options"
              className="w-full max-w-[240px]"
            />
          )}
        </div>
        <div className="h-full border-b border-l py-6 border-grey-10 pl-6">
          <p className="text-desc-grey text-sm leading-base1 max-w-[380px] h-full flex items-center">
            {
              {
                0: 'Return initiated',
                1: 'Return checked',
                2: 'Instant refund processed',
                3: 'Refund rejected',
                4: 'Consumer contacted',
              }[returnStates[i]]
            }
          </p>
        </div>
        <div className="h-full border-b border-l py-6 border-grey-10 pl-6">
          <p className="relative text-desc-grey text-sm leading-base1 max-w-[380px] h-full flex items-center justify-between">
            <span>
              {
                {
                  0: 'Check return',
                  1: 'Process refund',
                  2: '-',
                  3: '-',
                  4: 'Return not received - contact consumer',
                }[returnStates[i]]
              }
              {/* {actionRequired} */}
            </span>

            {/* {returnStates[i] === 3 ? (
              <Button
                title="Clear"
                category="2"
                color="light"
                className="absolute right-0 border-grey-stroke-tertiary group-hover:block hidden mr-2"
                onClick={() => setClearedUser((prev) => [...prev, project_identifier])}
              />
            ) : null} */}
          </p>
        </div>
        <div className="relative h-full flex items-center">
          {returnStates[i] === 3 ? (
            <Button
              title="Clear"
              category="2"
              color="light"
              className="absolute right-0 border-grey-stroke-tertiary group-hover:block hidden mr-2"
              onClick={() => setClearedUser((prev) => [...prev, project_identifier])}
            />
          ) : (
            <Ellipsis color="#747678" className="group-hover:block hidden" />
          )}
        </div>
      </div>
      {isOpen && (
        <div
          className="fixed inset-0"
          onClick={() => {
            setOpenReason(false);
            setOpenReason2(false);
            setIsOpen(false);
          }}
        />
      )}
      <Transition
        show={isOpen && (returnStates[i] === 0 || returnStates[i] === 1)}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div
          className={getClassNames(
            'absolute z-[100] flex flex-col text-left items-start justify-start top-14 right-56',
            'bg-white rounded-md shadow-lg pt-2 px-4 pb-5 min-w-[229px] border border-stroke'
          )}
        >
          <>
            <span className="text-grey-content-tertiary">Status Update</span>
            <RadioGroup value={checked} onChange={setChecked} className="mt-4 w-full">
              <div className="w-full">
                <div
                  className="cursor-pointer relative flex items-center justify-between  text-grey-470 hover:bg-tab-grey hover:text-body-black  rounded p-2 w-full"
                  onClick={() => {
                    setOpenReason(true);
                    setOpenReason2(false);
                  }}
                  onMouseEnter={() => {
                    setOpenReason(true);
                    setOpenReason2(false);
                  }}
                >
                  <span>Received with issues</span>
                  <Caret color="#B1B2B6" className="-rotate-90" />
                </div>
                <Transition
                  show={openReason}
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <div
                    className={getClassNames(
                      'absolute z-10 flex flex-col text-left items-start justify-start -top-[1px] left-[214px]',
                      'bg-white rounded-md shadow-lg pt-2 px-4 pb-5 min-w-[229px] border border-stroke'
                    )}
                  >
                    <span className="text-grey-content-tertiary">Reason</span>
                    <div className="mt-4 w-full">
                      <RadioGroup.Option value="notOriginal">
                        {({ checked }) => (
                          <div
                            className={getClassNames(
                              checked ? 'bg-tab-grey text-body-black' : 'text-grey-470',
                              'cursor-pointer  hover:bg-tab-grey hover:text-body-black rounded p-2 w-full'
                            )}
                          >
                            Not original item
                          </div>
                        )}
                      </RadioGroup.Option>
                      <RadioGroup.Option value="itemUsed">
                        {({ checked }) => (
                          <div
                            className={getClassNames(
                              checked ? 'bg-tab-grey text-body-black' : 'text-grey-470',
                              'cursor-pointer  hover:bg-tab-grey hover:text-body-black mt-0.5 rounded p-2 w-full'
                            )}
                          >
                            Item used
                          </div>
                        )}
                      </RadioGroup.Option>
                    </div>

                    <Button
                      // disabled={
                      //   checked !== "notOriginal" && checked !== "itemUsed"
                      // }
                      title="Done"
                      color="dark"
                      category="2"
                      className="lg:py-2 lg:px-4 mt-4"
                      onClick={() => {
                        setOpenReason2(false);
                        setOpenReason(false);
                        setIsOpen(false);
                        handleReturn(i, 5);
                      }}
                    />
                  </div>
                </Transition>
              </div>
              <RadioGroup.Option value="2">
                {({ checked }) => (
                  <div
                    className={getClassNames(
                      'cursor-pointer hover:bg-tab-grey hover:text-body-black  rounded p-2 w-full my-0.5',
                      checked ? 'bg-tab-grey text-body-black' : 'text-grey-470'
                    )}
                    onClick={() => {
                      setOpenReason(false);
                      setOpenReason2(false);
                    }}
                  >
                    Received in order
                  </div>
                )}
              </RadioGroup.Option>
              <div
                className="cursor-pointer relative flex items-center justify-between  text-grey-470 hover:bg-tab-grey hover:text-body-black  rounded p-2 w-full"
                onClick={() => {
                  setOpenReason(false);
                  setOpenReason2(true);
                }}
                onMouseEnter={() => {
                  setOpenReason(false);
                  setOpenReason2(true);
                }}
              >
                <span>Not Received</span>
                <Caret color="#B1B2B6" className="-rotate-90" />
              </div>
              <Transition
                show={openReason2}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <div
                  className={getClassNames(
                    'absolute z-10 flex flex-col text-left items-start justify-start top-[18px] left-[214px]',
                    'bg-white rounded-md shadow-lg pt-2 px-4 pb-5 min-w-[229px] border border-stroke'
                  )}
                >
                  <span className="text-grey-content-tertiary">Reason</span>
                  <div className="mt-4 w-full">
                    <RadioGroup.Option value="logistics">
                      {({ checked }) => (
                        <div
                          className={getClassNames(
                            checked ? 'bg-tab-grey text-body-black' : 'text-grey-470',
                            'cursor-pointer  hover:bg-tab-grey hover:text-body-black rounded p-2 w-full'
                          )}
                        >
                          Lost by Logistics
                        </div>
                      )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value="tamperedLabel">
                      {({ checked }) => (
                        <div
                          className={getClassNames(
                            checked ? 'bg-tab-grey text-body-black' : 'text-grey-470',
                            'cursor-pointer  hover:bg-tab-grey hover:text-body-black mt-0.5 rounded p-2 w-full'
                          )}
                        >
                          Return label tampered with
                        </div>
                      )}
                    </RadioGroup.Option>
                  </div>

                  <Button
                    // disabled={
                    //   checked !== "logistics" && checked !== "tamperedLabel"
                    // }
                    title="Done"
                    color="dark"
                    category="2"
                    className="lg:py-2 lg:px-4 mt-4"
                    onClick={() => {
                      setOpenReason2(false);
                      setOpenReason(false);
                      setIsOpen(false);
                      //   setChecked2(true);
                      handleReturn(i, 3);
                    }}
                  />
                </div>
              </Transition>
            </RadioGroup>

            <Button
              // disabled={checked !== "2"}
              title="Done"
              color="dark"
              category="2"
              className="lg:py-2 lg:px-4 mt-4"
              onClick={() => {
                setIsOpen(false);
                handleReturn(i, checked);
              }}
            />
          </>
        </div>
      </Transition>
    </div>
  );
};

export default StatusUpdatePopover;
