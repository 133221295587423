import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import CustomPopover from "@components/customPopover";

// Assets
import EllipsisIcon from "@icons/Ellipsis.js";
import { ReactComponent as RefreshIcon } from "@icons/refresh.svg";

const UserCredentials = ({ setStep }) => {
  const credentials = [
    {
      action: "OTP",
      date: "Jul 7, 23 at 23:02",
      success: true,
      id: 1,
    },
    {
      action: "Notification",
      date: "Jul 7, 23 at 23:02",
      success: false,
      id: 2,
    },
  ];
  return credentials ? (
    <div className="">
      <div className="border border-stroke bg-white rounded-md">
        <div className="grid grid-cols-[47px,5fr,3fr,3fr,2fr,100px] bg-grey-600 py-2 leading-base1 text-sm text-grey-content-secondary">
          <span className="self-center mx-auto">#</span>
          <div className="pl-6">Credential</div>
          <div className="pl-6">Status</div>
          <div className="pl-6">Date</div>
          <div className="pl-6">Action</div>
        </div>
        {credentials.map(({ id, action, date, success }, i) => (
          <div
            className="draggable-item group grid grid-cols-[47px,5fr,3fr,3fr,2fr,100px] hover:bg-white2 items-center"
            key={id}
          >
            <div className="counter text-grey-content-secondary text-sm self-center w-full mx-auto flex items-center justify-center border-b border-grey-10 h-full">
              {i + 1}
            </div>
            <div
              className={getClassNames(
                "title text-body-black text-base pl-6 self-center",
                "h-full border-b border-l py-6 border-grey-10 cursor-pointer"
              )}
            >
              <span className="header">{action}</span>
            </div>
            <div className="h-full border-b border-l py-6 border-grey-10 pl-6 flex items-center gap-x-2">
              <div
                className={getClassNames(
                  "w-2 h-2 rounded-full",
                  success ? "bg-green-alert" : "bg-red-alert"
                )}
              />
              <p className="text-desc-grey text-sm leading-base1 max-w-[380px] h-full flex items-center ">
                {success ? "Activated" : "Deactivated"}
              </p>
            </div>
            <div className="h-full border-b border-l py-6 border-grey-10 pl-6">
              <p className="text-desc-grey text-sm leading-base1 max-w-[380px] h-full flex items-center ">
                {date}
              </p>
            </div>
            <div className="h-full border-b border-l py-5 border-grey-10 pl-6">
              <Button
                title="Refresh"
                color={"light"}
                category="2"
                prefixIcon={<RefreshIcon />}
              />
            </div>
            <div className="h-full border-b border-l py-6 border-grey-10 flex items-center justify-center">
              <CustomPopover
                className="right-0 top-2"
                placeholder={<EllipsisIcon color="#747678" />}
                options={[
                  {
                    name: "Edit",
                    id: 1,
                    value: true,
                    onClick: () => {},
                  },

                  {
                    name: "Clear",
                    id: 3,
                    value: true,
                    onClick: () => {},
                  },
                ]}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default UserCredentials;
