import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Dialog, Transition } from "@headlessui/react";

// Helpers
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import Input from "@components/input";

// Assets
import { ReactComponent as CloseIcon } from "@icons/cancel.svg";
import { ReactComponent as EditIcon } from "@icons/edit.svg";
import RoleSelect from "@components/RoleSelect";

const EditGroupModal = ({ isOpen, closeModal }) => {
  const [role, setRole] = useState({
    role: "viewer",
    description: "Cannot edit or configure",
  });
  const {
    register,
    // getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async () => {
    // const data = getValues();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed top-20 left-0 right-0 overflow">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full max-w-[530px] transform rounded-md bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all`
                )}
              >
                <Dialog.Title
                  as="h3"
                  className="flex justify-between items-center text-base1 leading-xl1 text-body-black bg-grey-50 py-[0.625rem] px-[1.625rem] rounded-t-md"
                >
                  <span>Edit Group</span>

                  <CloseIcon
                    onClick={() => closeModal(false)}
                    className="cursor-pointer"
                  />
                </Dialog.Title>

                <form className="p-[1.875rem]">
                  <div className="flex items-center gap-x-3 mb-4">
                    <div className="relative flex items-center justify-center group min-w-[76px] min-h-[76px] rounded">
                      <span className="text-2xl">H</span>
                      <div className="absolute inset-0 rounded group-hover:bg-overlay-black/50 bg-overlay-black/70 flex items-center justify-center">
                        <EditIcon className="hidden group-hover:block cursor-pointer" />
                      </div>
                    </div>
                    <Input
                      name="name"
                      category="regular"
                      label="Team name"
                      labelStyle="text-desc-grey"
                      {...{ register, errors }}
                      rules={{ required: true }}
                      placeholder="Give this group a name."
                      className="flex-1"
                    />
                  </div>
                  <RoleSelect
                    value={role}
                    onChange={setRole}
                    label="Group default role"
                  />

                  <Button
                    title="Save"
                    color="dark"
                    category="1"
                    className="mt-4"
                    disabled={!isValid}
                    // loading={loading}
                    onClick={onSubmit}
                  />
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditGroupModal;
