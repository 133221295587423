import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Utils
import { getClassNames } from '@utils/helpers';

// Components
import { Button } from '@components/button';
import { InputContainer } from '@components/input';
import RadioButton from '@components/RadioButton';
import SearchInput from '@components/searchInput';

// Assets
import { ReactComponent as BankCoFlag } from '@icons/bank-co.svg';
import { ReactComponent as AxaBankFlag } from '@icons/bank-axa.svg';
import { ReactComponent as BnpParibasBankFlag } from '@icons/bank-bnp-paribas.svg';
import { ReactComponent as CaretIcon } from '@icons/arrow_down.svg';
import { ReactComponent as CreditAgricoleBankFlag } from '@icons/bank-credit-agricole.svg';
import { ReactComponent as CreditMutuelFlag } from '@icons/bank-credit-mutuel.svg';
import { ReactComponent as CreditDuNordFlag } from '@icons/bank-credit-du-nord.svg';
import { ReactComponent as FlagFrance } from '@icons/flag-fr.svg';
import { ReactComponent as GroupBPCEFlag } from '@icons/bank-bpce.svg';
import { ReactComponent as HSBCFlag } from '@icons/bank-hsbc-france.svg';
import { ReactComponent as LaBanquePostaleFlag } from '@icons/bank-la-bank-postale.svg';
import { ReactComponent as RevolutBankFlag } from '@icons/bank-revolut.svg';

const Authenticate = ({ setStep }) => {
  const navigate = useNavigate();
  const [currentBank, setCurrentBank] = useState();
  const [query, setQuery] = useState('');
  const banks = [
    {
      name: 'Bank Co',
      value: 'bco',
      flag: <BankCoFlag className="w-full" />,
    },
    {
      name: 'Revolut',
      value: 'revolut',
      flag: <RevolutBankFlag className="w-full" />,
    },
    {
      name: 'BNP Paribas',
      value: 'bnp',
      flag: <BnpParibasBankFlag className="w-full" />,
    },
    {
      name: 'Crédit Agricole Group',
      value: 'cag',
      flag: <CreditAgricoleBankFlag className="w-full" />,
    },
    {
      name: 'AXA Banque',
      value: 'axa',
      flag: <AxaBankFlag className="w-full" />,
    },
    {
      name: 'Group BPCE',
      value: 'bpce',
      flag: <GroupBPCEFlag className="w-full" />,
    },
    {
      name: 'Crédit Mutuel Group',
      value: 'cmg',
      flag: <CreditMutuelFlag className="w-full" />,
    },
    {
      name: 'La Banque Postale',
      value: 'lbp',
      flag: <LaBanquePostaleFlag className="w-full" />,
    },
    {
      name: 'HSBC France',
      value: 'hsbc',
      flag: <HSBCFlag className="w-full" />,
    },
    {
      name: 'Crédit du Nord',
      value: 'cdn',
      flag: <CreditDuNordFlag className="w-full" />,
    },
  ];
  const handleSearch = () => {
    return banks.filter(
      ({ name, value }) =>
        name.toLowerCase().includes(query) || value.toLowerCase().includes(query)
    );
  };
  const onSubmit = async () => {
    navigate('bank-login');
  };

  const topRef = useRef(0);
  useEffect(() => {
    topRef?.current?.scrollIntoView();
  }, []);

  return (
    <div className="pb-28" ref={topRef}>
      <InputContainer
        className="flex items-center justify-between"
        label="Select a Country"
      >
        <div className="flex items-center gap-x-2">
          <FlagFrance />
          <span>France</span>
        </div>
        <CaretIcon />
      </InputContainer>
      <p className="text-desc-grey text-base leading-5 font-medium mt-4 lg:mt-6 mb-1">
        Select a Bank
      </p>
      <SearchInput
        placeholder="Search for a bank"
        handleChange={(query) => setQuery(query.toLowerCase())}
        className="py-3 border-b-0 rounded-t-lg"
      />
      <div className="flex flex-col divide-y-[1px] divide-grey-10 border border-stroke-primary rounded-b-lg p-0 bg-transparent">
        {handleSearch().map((bank) => (
          <div
            key={bank.value}
            className="group flex justify-between items-center hover:bg-white2 py-2 px-4"
            onClick={() => setCurrentBank(bank.value)}
          >
            <div className="flex items-center gap-x-4">
              <div className="max-w-[40px] lg:max-w-[52px] overflow-hidden">
                {bank.flag}
              </div>
              <span
                className={getClassNames(
                  'group-hover:text-body-black',
                  currentBank === bank.value && 'text-body-black'
                )}
              >
                {bank.name}
              </span>
            </div>
            <RadioButton active={bank.value === currentBank} />
          </div>
        ))}
      </div>
      <div className="fixed w-full bottom-10 flex items-center gap-x-2 mt-6 bg-white py-5">
        <Button
          title="Cancel"
          category="1"
          color="light"
          className="w-fit"
          onClick={() => navigate('/setup-declined')}
        />
        {/* <a href="https://xs2a.awltest.de/aspsp-mock/forms/psuConsentAuthenticationForm.jsp?psuAuthorizationUri=https://xs2a.awltest.de/aspsp-mock/services/authorize-redirect/simulation/consentAuthorization-gui&tppRedirectUri=https%3A%2F%2Fdemo.consumer.trudenty.com%2Fsetup-success&consentId=2083abb254bf41ceab7fb03c317ab535&xRequestId=17afa2cd-621e-49cd-80c0-5dce272293b9&aspsp_name="> */}
        <Button
          title="Continue"
          category="1"
          color="dark"
          className="w-fit"
          disabled={!currentBank}
          onClick={() => onSubmit()}
        />
        {/* </a> */}
      </div>
    </div>
  );
};

export default Authenticate;
