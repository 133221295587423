import Dots from '@/assets/icons/Dots';
import Table from '@/components/Table/Table';
import { ROLES } from '@/utils/constants';
import { getRandomInt, organizationUsers, renderNameIcon } from '@/utils/helpers';
import { useEffect, useState } from 'react';

const AllTeams = ({ currentRole, search }: { currentRole?: string; search?: string }) => {
  const teamsList = [
    {
      id: 'ff1ce333-7c37-4564-a24c-ee2eadb8527b',
      name: 'General',
      image: null,
      role_id: '44534385-b8ec-4b76-8e8e-886b1e6d5989',
      organization_id: null,
      role: {
        name: 'Admin',
        organization_type: 'NETWORK_MASTER',
      },
      users: organizationUsers,
    },
  ];

  const [teams, setTeams] = useState(teamsList);

  useEffect(() => {
    if (search === '') {
      setTeams(teamsList);
    } else {
      setTeams(
        teamsList.filter((team) =>
          team.name.toLocaleLowerCase().includes(search?.toLocaleLowerCase() ?? '')
        )
      );
    }
  }, [search]);

  useEffect(() => {
    if (currentRole === 'all') {
      setTeams(teamsList);
    } else {
      setTeams(teamsList.filter((team) => team.role.name === currentRole));
    }
  }, [currentRole]);

  const getRoleColor = (role: string) => {
    switch (role) {
      case ROLES.ADMIN:
        return 'bg-blurple';
      case ROLES.EDITOR:
        return 'bg-trud-orange';
      case ROLES.VIEWER:
        return 'bg-risk-green';
      default:
        return 'bg-body-black';
    }
  };

  const teamHeaderData: any[] = [
    {
      label: '#',
      className: 'w-[44px]',
      option: {
        sort: false,
        hasFilter: false,
        isIndex: true,
      },
    },
    {
      cell: (props: any) => {
        return renderNameIcon({
          name: props.name,
          imageUrl: props?.image,
          className: 'bg-grey-content-quaternary text-white font-medium',
          textClassName:
            'flex items-center ml-2 text-body-black group-hover:text-blacked font-medium text-base',
        });
      },
      label: 'Name',
    },
    {
      label: 'Team',
      cell: (props: any) => {
        const renderRows = () => {
          const resp = [];
          for (let i = 0; i < 3; i++) {
            if (props?.users?.[i]?.name) {
              resp.push(
                renderNameIcon({
                  name: props?.users?.[i].name,
                  isCircle: true,
                  imageUrl: props?.users?.[i]?.image,
                  className: 'bg-grey-content-quaternary text-white font-medium',
                  textClassName:
                    'flex items-center ml-2 text-body-black group-hover:text-blacked font-medium text-base',
                })
              );
            }
          }
          return resp;
        };
        if (props.users?.length > 0) {
          return (
            <div className="flex items-center gap-4" key={getRandomInt()}>
              {renderRows()}
              {props.users?.length > 3 ? (
                <span className="text-content-tertiary">
                  + {props.users?.length - 3} more
                </span>
              ) : (
                ''
              )}
            </div>
          );
        } else {
          return <div></div>;
        }
      },
    },
    {
      label: 'Role',
      cell: (props: any) => actionRender(props),
    },
  ];
  const actionRender = (props: Record<string, any>) => {
    return (
      <div className="flex justify-between">
        <div className="inline-flex items-center gap-1 border border-solid border-stroke-primary px-2 py-1 rounded-full w-auto">
          <div
            className={`w-2 h-2 rounded-sm ${getRoleColor(props?.role?.name ?? '')}`}
          ></div>
          <span className=" text-base leading-base1 capitalize text-body-black">
            {props?.role?.name ?? ''}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <Table
        headerData={teamHeaderData}
        headerClass="background-white"
        bodyData={teams}
        totalPage={1}
        noDataHeader={!search && teams?.length === 0 ? 'Add users to this team' : ''}
        noDataContent={
          !search && currentRole === 'all' && teams?.length === 0
            ? 'Switch a user to this team or invite a new user to be added to this team.'
            : ''
        }
      />
    </>
  );
};

export default AllTeams;
