import { Fragment, useState } from "react";

// import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
// import { toast } from "react-toastify";

// Helpers
import { getClassNames } from "@utils/helpers";

// Components
import BackArrow from "@components/BackArrow";
import Progress from "@components/Progress";
import ServerConfigForm from "./ServerConfigForm";
import AttributeMappingForm from "./AttributeMappingForm";
import ImportSummary from "./ImportSummary";

const LDAPModal = ({ isOpen, closeModal }) => {
  const options = [
    "Server configuration",
    "Attribute mapping",
    "Import summary",
  ];

  const [step, setStep] = useState(0);
  const onContinue = () => {
    if (step < 2) {
      setStep((prev) => prev + 1);
    } else {
      closeModal();
      setStep(0);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full min-w-[852px] max-w-[852px] transform rounded-md bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all p-10`
                )}
              >
                <Dialog.Title
                  as="h3"
                  className="flex justify-between items-center text-base1 leading-xl1 text-body-black rounded-t-md mb-6"
                >
                  <div className="flex items-center gap-x-2">
                    <BackArrow onClick={() => closeModal()} />
                    <span className="header4 text-body-black">
                      LDAP Configuration
                    </span>
                  </div>
                </Dialog.Title>
                <Progress {...{ step, setStep, options }} />

                {step === 0 && <ServerConfigForm {...{ onContinue }} />}
                {step === 1 && <AttributeMappingForm {...{ onContinue }} />}
                {step === 2 && <ImportSummary {...{ onContinue }} />}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LDAPModal;
